import { Container } from 'react-bootstrap';
import styles from './Featured_catgeory.module.scss';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Kitchen from '../../../assets/images/kitchen.png';
import Bedroom from '../../../assets/images/bedroom.png';
import Pooja from '../../../assets/images/pooja.png';
import Toilet from '../../../assets/images/toilet.png';
import Storage from '../../../assets/images/storage.png';
import Arrow from '../../../assets/images/arrowright.png';
import { Link, useLocation, useNavigate } from 'react-router-dom'


export const FeaturedCatgeory = () => {
  const navigate = useNavigate();
  const handleProductListPage = (value) =>{
    navigate('/list-products', { state: { type: 'listing', InterriorRoom: value } });
    } 
  
  return (
    <div className={styles.categori_slides}>
        <div className={styles.cat_bgcol}></div>
        <Container>
          <div className={`${styles.categ_titles} d-flex gap-3 align-items-center `}>
            <h3>Featured Categories</h3>
            <p className="m-0">specifically curated for you</p>
          </div>
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={25}
            slidesPerView={4.5}
            navigation
            pagination={{ clickable: true }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // when window width is >= 640px
              320: {
                slidesPerView: 1,
              },
              // when window width is >= 768px
              568: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
            className="categories_slider"
          >
            <SwiperSlide   onClick={() => handleProductListPage('Kitchen')}>
              <div className={styles.single_categ_slide}>
                <img src={Kitchen} alt='' width='100%' />
                <label>Kitchen <span><img src={Arrow} alt='' width='20px' height='auto' /></span></label>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.single_categ_slide} onClick={() => handleProductListPage('Bedroom')}>
                <img src={Bedroom} alt='' width='100%' />
                <label>Bedroom <span><img src={Arrow} alt='' width='20px' height='auto' /></span></label>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.single_categ_slide} onClick={() => handleProductListPage('Pooja')}>
                <img src={Pooja} alt='' width='100%' />
                <label>Pooja <span><img src={Arrow} alt='' width='20px' height='auto' /></span></label>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.single_categ_slide} onClick={() => handleProductListPage('Toilet')}>
                <img src={Toilet} alt='' width='100%' />
                <label>Toilet <span><img src={Arrow} alt='' width='20px' height='auto' /></span></label>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.single_categ_slide} onClick={() => handleProductListPage('Storage')}>
                <img src={Storage} alt='' width='100%' />
                <label>Storage <span><img src={Arrow} alt='' width='20px' height='auto' /></span></label>
              </div>
            </SwiperSlide>
          </Swiper>
        </Container>
      </div>
  )
}
