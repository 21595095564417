import styles from './review.module.scss'
import Star from '../../../assets/images/svg/5_start.svg'
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Person from '../../../assets/images/Ahmed-Jamal.jpg.png'

export const Reviews = () => {
    return (
        <div className={styles.reviews}>
            <h1 className='pt-2'>Reviews from our customers</h1>
            <div className='d-flex flex-column'>
                <label>AVERAGE RATING</label>
                <label className='pt-3'>
                    4.5
                    <img src={Star} alt='' className='ms-3' />
                </label>
            </div>
            <div>
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={15}
                    slidesPerView={5}
                    navigation
                    pagination={{ clickable: true }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        // when window width is >= 640px
                        320: {
                            slidesPerView: 1,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 4,
                        },
                        1200: {
                            slidesPerView: 5,
                        },
                    }}
                    className="categories_slider px-1 blog_sliders"
                >
                    <SwiperSlide>
                        <div className={styles.review_card}>
                            <div className='d-flex flex-column' >
                                <label className='pt-3'>
                                    4.5
                                    <img src={Star} alt='' className='ms-3' />
                                </label>
                                <span>
                                    "Amazing service for a very good price for companies. We use Planningg for all the designs we have in the pharmaceutical business, even our plans and elevation. We recommend them 10/10."
                                </span>
                            </div>
                            <div className={`${styles.main_user_box} d-flex  gap-3 mt-4 `}>
                                <img src={Person} alt='' />
                                <div className= 'd-flex flex-column'>
                                    <p>Abhilash Sharma</p>
                                    <span>Feb 2022</span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.review_card}>
                            <div className='d-flex flex-column'>
                                <label className='pt-3'>
                                    4.5
                                    <img src={Star} alt='' className='ms-3' />
                                </label>
                                <span>
                                    "Amazing service for a very good price for companies. We use Planningg for all the designs we have in the pharmaceutical business, even our plans and elevation. We recommend them 10/10."
                                </span>
                            </div>
                            <div className={`${styles.main_user_box} d-flex  gap-3 mt-4 `}>
                                <img src={Person} alt='' />
                                <div className='d-flex flex-column'>
                                    <p>Abhilash Sharma</p>
                                    <span>Feb 2022</span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.review_card}>
                            <div className='d-flex flex-column'>
                                <label className='pt-3'>
                                    4.5
                                    <img src={Star} alt='' className='ms-3' />
                                </label>
                                <span>
                                    "Amazing service for a very good price for companies. We use Planningg for all the designs we have in the pharmaceutical business, even our plans and elevation. We recommend them 10/10."
                                </span>
                            </div>
                            <div className={`${styles.main_user_box} d-flex  gap-3 mt-4 `}>
                                <img src={Person} alt='' />
                                <div className='d-flex flex-column'>
                                    <p>Abhilash Sharma</p>
                                    <span>Feb 2022</span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.review_card}>
                            <div className='d-flex flex-column'>
                                <label className='pt-3'>
                                    4.5
                                    <img src={Star} alt='' className='ms-3' />
                                </label>
                                <span>
                                    "Amazing service for a very good price for companies. We use Planningg for all the designs we have in the pharmaceutical business, even our plans and elevation. We recommend them 10/10."
                                </span>
                            </div>
                            <div className={`${styles.main_user_box} d-flex  gap-3 mt-4 `}>
                                <img src={Person} alt='' />
                                <div className='d-flex flex-column'>
                                    <p>Abhilash Sharma</p>
                                    <span>Feb 2022</span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.review_card}>
                            <div className='d-flex flex-column'>
                                <label className='pt-3'>
                                    4.5
                                    <img src={Star} alt='' className='ms-3' />
                                </label>
                                <span>
                                    "Amazing service for a very good price for companies. We use Planningg for all the designs we have in the pharmaceutical business, even our plans and elevation. We recommend them 10/10."
                                </span>
                                <div className={`${styles.main_user_box} d-flex  gap-3 mt-4 `}>
                                    <img src={Person} alt='' />
                                    <div className='d-flex flex-column'>
                                        <p>Abhilash Sharma</p>
                                        <span>Feb 2022</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

        </div>
    )
}
