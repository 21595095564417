import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { ShowErrorMessage } from "../Notification";
import styles from './payment.module.scss';
import { Button } from "react-bootstrap";
export const Register = ({ handleCreateAccount, setFormData }) => {
    const [validated, setValidated] = useState(false);
    const defaultFormData = {
        first_name: "",
        last_name: "",
        country_code:"+91",
        email: "",
        mobile_no: "",
        password: "",
    };

    const [userInputs, setUserInputs] = useState(defaultFormData);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }
        if (userInputs.password === "") {
            ShowErrorMessage("Password is required !");
            return;
        }
        setFormData(userInputs);
        handleCreateAccount();
    };

    return (
        <div className={`${styles.register_section} col-lg-9 col-md-12`}>
            <Form  onSubmit={handleSubmit} validated={validated}>
                <div className={`${styles.tab1} row`}>
                    <div className='col-md-6 pb-2 d-flex flex-column'>
                        <label>First name*</label>
                        <Form.Control
                            placeholder="First name"
                            required
                            name="first_name"
                            key="first_name"
                            type="text"
                            value={userInputs.first_name}
                            onChange={(e) => {
                                setUserInputs({
                                    ...userInputs,
                                    first_name: e.target.value,
                                });
                            }}
                        />                    </div>
                    <div className='col-md-6 d-flex flex-column'>
                        <label> Last name</label>
                        <Form.Control
                            placeholder="Last name"
                            name="last_name"
                            type="text"
                            key="last_name"
                            value={userInputs.last_name}
                            onChange={(e) => {
                                setUserInputs({
                                    ...userInputs,
                                    last_name: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className={`${styles.tab1} row`}>
                    <div className='col-md-6 pb-2 d-flex flex-column'>
                        <label>Email id*</label>
                        <Form.Control
                            placeholder="Email"
                            required
                            name="email"
                            type="email"
                            key="email"
                            value={userInputs.email}
                            onChange={(e) => {
                                setUserInputs({
                                    ...userInputs,
                                    email: e.target.value,
                                });
                            }}
                        />                   </div>
                    <div className='col-md-6 d-flex flex-column'>
                        <label>Mobile No*</label>
                        <Form.Control
                            placeholder="Mobile No."
                            aria-label="mobile_no"
                            aria-describedby="basic-addon2"
                            name="mobile_no"
                            required
                            type="text"
                            key="mobile_no"
                            value={userInputs.mobile_no}
                            onChange={(e) => {
                                setUserInputs({
                                    ...userInputs,
                                    mobile_no: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className='pt-2 pb-2'>
                    <label>Password*</label>
                    <Form.Control
                        placeholder="Password"
                        name="password"
                        type="password"
                        required
                        key="password"
                        value={userInputs.password}
                        onChange={(e) => {
                            setUserInputs({
                                ...userInputs,
                                password: e.target.value,
                            });
                        }}
                    />                </div>
                <div className='pt-2 pb-2'>
                    <span>Your password</span>
                    <div className='row'>
                        <div className='col-md-5 d-flex flex-column '>
                            <span className='d-flex align-items-center gap-2'>
                                <p className={styles.radio_tick}></p>
                                Must have one lowercase letter
                            </span>
                            <span className='d-flex align-items-center gap-2'>
                                <p className={styles.radio_tick}></p>
                                Must have one lowercase letter
                            </span>
                        </div>
                        <div className='col-md-5 d-flex flex-column'>
                            <span className='d-flex align-items-center gap-2'>
                                <p className={styles.radio_tick}></p>
                                Must have one lowercase letter
                            </span>
                            <span className='d-flex align-items-center gap-2'>
                                <p className={styles.radio_tick}></p>
                                Must have one lowercase letter
                            </span>
                        </div>
                    </div>
                    <span>by clicking “Create Account”, you agrees planningg.homes terms and condition</span>
                </div>
                <Button type="submit" variant="primary" size="md">
                Ceate Account
                </Button>
            </Form>
        </div>
    );
};
