import SampleManagment from '../services/SampleManagment';

export const onButtonClick = async () => {
  const response = await SampleManagment.dawnloadFile();

  if (response.status === 200) {
    const { pdfUrl, fileName } = response;
    console.log(pdfUrl);
    fetch(pdfUrl)
      .then(response => response.blob())
      .then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.setAttribute('download',fileName);
        document.body.appendChild(alink);
        alink.click();
        document.body.removeChild(alink);
      }).catch((err) => console.error("Error",err))
  } else {
    
  }
};


