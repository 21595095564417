import React, { useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styles from './profile.module.scss';
import { useForm, Controller } from 'react-hook-form';
import { postRequest } from '../../API_Requests/Api_Request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the default CSS (if not already imported)
function ReferFriendModal({ showModal, onClose }) {
  const { control, handleSubmit } = useForm();
  const { REACT_APP_API_URL } = process.env;
  // eslint-disable-next-line
  const Url = useMemo(() => `${REACT_APP_API_URL}client/refer_friend`);


  const onSubmit = async (data) => {
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('id');
    data.userId = userId;
    try {
      const response = await postRequest(Url, data, accessToken);
      if (response.data.status === 200) {
        toast.success('send successfully!');
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
    onClose();
  };

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className={`${styles.modalTitleHeading} text-danger`}>
          <h3>Refer A Friend</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="friendEmail" className={`${styles.inputBoxDesign} mb-3`}>
            <Controller
              name="friendEmail"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="email"
                  placeholder="Enter Friend's Email"
                  autoFocus
                  className={styles.inputBoxDesign}
                  {...field}
                />
              )}
            />
          </Form.Group>
          <Form.Group controlId="friendMobile" className={`${styles.inputBoxDesign} mb-3`}>
            <Controller
              name="friendMobile"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="tel"
                  placeholder="Enter Friend's Mobile Number"
                  className={styles.inputBoxDesign}
                  {...field}
                />
              )}
            />
          </Form.Group>
          <Modal.Footer className='border-0'>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ReferFriendModal;
