import React from 'react'
import styles from './sidebar.module.scss'
import { Link } from 'react-router-dom';
import ArrowRight from '../../../assets/images/svg/arrow_right.svg'

export const RightSidebar = () => {
    return (
        <div className={`${styles.right_div} col-lg-3 col-md-4 `}>
            <div className={styles.div_1}>
                <div className={styles.inner_div1}>
                    <label>Get your First<br /> House Floor<br />
                        Plan for <span>Free</span></label>
                </div>
                <span className={styles.label}>
                    Find Design and Plan <br /> easily
                </span>
                <div className='d-flex align-item-center justify-content-center'>
                    {/* <button type='button'  className={styles.get_consslt_btn}>Get Consultaion</button> */}
                    <Link className={styles.get_consslt_btn} to='/book-demo'>Get Consultaion</Link>
                </div>
                <div className={styles.why_planningg}>
                    <h6>Here's why<br /> Planningg:</h6>
                    <ul>
                        <li>
                            <div className={styles.tick_cirle}></div>
                            <span>Get Access to 4k+ Designs </span>
                        </li>
                        <li>
                            <div className={styles.tick_cirle}></div>
                            <span>Get Faster with Premium Service</span>

                        </li>
                        <li>
                            <div className={styles.tick_cirle}></div>
                            <span>Find only Genuine  Plans </span>
                        </li>
                        <li>
                            <div className={styles.tick_cirle}></div>
                            <span>Get Expert advice on Market Trends & insights</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.div_2}>
                <div className={styles.label}>
                    <div className={styles.design_sahi_service}>
                        Design Sahi, Service <br />
                        Sabhi
                    </div>
                </div>
                <div className={styles.label}>
                    <p className={styles.text_wrapper}>We help you to design your House with 5+ more service</p>
                </div>
                <div className={styles.view_all_services}>
                    <div className={styles.text_wrapper}>View all Packages<img src={ArrowRight} alt='' className={styles.img} />
                    </div>
                </div>
            </div>
            <div className={styles.div_3}>
                <div className={styles.label}>
                    <p className={styles.get_assured_discount}>
                        <span className={styles.text_wrapper}>Get assured discount </span>
                        <span className={styles.span}>upto 30% on every design services</span>
                        <span className={styles.text_wrapper}> on Planningg.Homes</span>
                    </p>
                </div>
                <div className={styles.interestes_label}>
                    <div className={styles.text_wrapper}>I’m Interested<img src={ArrowRight} alt='' className={styles.img} /></div>
                </div>
                <div className={styles.call_label}>
                    <div className={styles.text_wrapper}>Call us now</div>
                </div>
            </div>
        </div>
    )
}
