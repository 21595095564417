import React from "react";
import Logo from '../../assets/images/transparent-logo.png';
import './login.styles.scss';
import { Link } from "react-router-dom";


const authSideBar = () => {

  return (
    <div className="col-md-5 left_login_page">
      <Link to='/'><img src={Logo} alt="" height="" width="123px" /></Link>
      <div className="login-center-content">
        <label>We're glad to see you again!</label>
        <h2>Looking for the best architectural services? Sign Up/Login to get started</h2>
      </div>
    </div>

  )
}

export default authSideBar;