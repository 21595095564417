import React from 'react';
// import PhoneLogo from '../../assets/images/otp-icon.png';
import Form from 'react-bootstrap/Form';
import OTPInput from 'react-otp-input';
import Button from 'react-bootstrap/Button';
import AuthSideBar from './authSideBar';

export const GetOtp = ({
  otpSent,
  setMobileNumber,
  setOtpValue,
  otpValue,
  submitOtp,
  resendOtp,
  mobileNumber,
  sendOTP,
  countryFlag,
  countryCode,
  setCountryCode,
  setCountryFlag,
}) => {
  return (
    <div className='login_main_page w-100 d-flex'>
      <div className='row w-100'>
        <AuthSideBar />
        <div className="col-md-7 col-12 d-flex flex-column justify-content-center right_login_page">
          {otpSent ? ( // Check if OTP was sent successfully
            <>
              <div className="card-header px-0 px-md-5">
                <div className="header-text w-100">Two-Factor Verification</div>
                {/* <img className='img-fluid' src={PhoneLogo} alt='' /> */}
                <div className="header-subtext pe-md-5">
                  Please enter the OTP (one time password) to verify your account. A Code has been sent to {mobileNumber}
                </div>

                <form className="otp-conatiner">
                  <div className="otp-subtext">Type your 6 digit security code</div>
                  <div className="otp-inputs">
                    <OTPInput
                      value={otpValue}
                      onChange={setOtpValue}
                      numInputs={6}
                      separator={<span>-</span>}
                      inputStyle="otp-input"
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                  <button
                type="button"
                disabled={!otpValue}
                className={`submit-btn ${!otpValue ? 'transparent-btn-submit' : 'submit-btn'}`}
                onClick={submitOtp}
              >
                Submit
              </button>
                    <div className="otp-resend">Didn’t get the code? <p className='resend-btn m-0' onClick={resendOtp}>Resend Otp</p></div>
                  </div>
                </form>
              </div>

            </>
          ) : (
            <>
              <Form.Label htmlFor="inputPassword5">Login or Register</Form.Label>
              <div className="d-flex gap-3 align-items-center">
                <span className={`flag-icon flag-icon-${countryFlag}`}></span>
                <p className="m-0 countryCode">{countryCode}</p>
                <Form.Control
                  type="number"
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Enter your mobile no"
                  className="input-control"
                  inputMode="numeric"
                  minLength={1}
                  maxLength={10}
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </div>
              <p className="py-3">By continuing, I agree to the Terms of Use & Privacy Policy</p>
                 <Button
                onClick={sendOTP}
                disabled={!mobileNumber}
                className={`getOtpBtn ${!mobileNumber ? 'transparent-btn' : ''}`}
              >
                Get OTP
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
