import styles from './bookDemo.module.scss';
import { TryUsBox } from '../Home/TryUsBox';
import { PartnerBox } from '../../common/partner-box';
import Google from '../../../assets/images/svg/rating/google_rating.svg'
import FaceBook from '../../../assets/images/svg/rating/facebook_rating.svg'
import Trust from '../../../assets/images/svg/rating/trust_pilot_rating.svg'
import G from '../../../assets/images/svg/rating/g_rating.svg'
import INC from '../../../assets/images/svg/rating/ince_rating.svg'
import SliderBox from './slider';
import { MainBannner } from '../../common/banner';


export const BookDemo = () => {
  return (
    <div className={styles.main}>
      {/* main banner */}
      <MainBannner
        title1={'Book a free call from'}
        title2={'Planningg Experts'}
        pageLink={'Book a Demo'}
        ServiceForm={true}
      />
      <div className={styles.rating_box}>
        <span>Trusted by 100 brands worldwide</span>
        <div className={styles.rating_img_box}>
          <img src={Google} alt='' />
          <img src={FaceBook} alt='' />
          <img src={Trust} alt='' />
          <img src={G} alt='' />
          <img src={INC} alt='' />
        </div>
      </div>
      <SliderBox />
      <PartnerBox />
      <TryUsBox title1="Ready to try " title2='Floor Plan?' page='bookDemo' />
    </div>
  )
};
