import React, { useEffect, useState } from 'react'
import styles from './home.module.scss';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Slide1 from '../../../assets/images/slide-img.png';
import Logo from '../../../assets/images/Logo-Planning.png';
import OwlImg1 from '../../../assets/images/OwlImg1.png';
import OwlImg2 from '../../../assets/images/OwlImg2.png';
import OwlImg3 from '../../../assets/images/OwlImg3.png';
import OwlImg4 from '../../../assets/images/OwlImg4.png';
import OwlImg5 from '../../../assets/images/OwlImg5.png';
import Arrow from '../../../assets/images/arrowright.png';
import TabArrow from '../../../assets/images/svg/arrow.svg';
import Demo from '../../../assets/images/demo-img.png';
import DummyImg from '../../../assets/images/DummyImg.png';
import MobiKwik from '../../../assets/images/mobikwik.png';
import Freecharge from '../../../assets/images/freecharrger.png';
import Simpl from '../../../assets/images/simpl.png';
import Lazypay from '../../../assets/images/lazypay.png';
import BlogImg from '../../../assets/images/blogimg.png';
import EveryPlan from '../../../assets/images/svg/everyplantext.svg';
import Unlimited from '../../../assets/images/svg/unlimited.svg';
import Pointclick from '../../../assets/images/svg/pointclick.svg';
import Folder from '../../../assets/images/svg/folder.svg';
import Clock from '../../../assets/images/svg/clock.svg';
import Days from '../../../assets/images/svg/120+.svg';
import Vetted from '../../../assets/images/svg/vetted.svg';
import TeamColl from '../../../assets/images/svg/teamcollab.svg';
import CustomerSup from '../../../assets/images/svg/customersupp.svg';
import Moneyback from '../../../assets/images/svg/moneyback.svg';
import RedTick from '../../../assets/images/svg/redtick.svg';
import Greytick from '../../../assets/images/svg/greytick.svg';
import Quesmark from '../../../assets/images/svg/quesmark.svg';
import CrossX from '../../../assets/images/svg/xmark.svg';
import WhyImg from '../../../assets/images/whyimg.png';
import Accordion from 'react-bootstrap/Accordion';
import { Link, useNavigate } from "react-router-dom";
import { TryUsBox } from "./TryUsBox";
import { TrendingDesign } from "./TrendingDesign";
import { PartnerBox } from "../../common/partner-box";
import { FeaturedCatgeory } from "../../common/Featured_catergory";
import homeService from "../../../services/homeService";
import { IMAGE_BASE_URL } from '../../../config/constants/AppConfig';

export const Home = () => {
  const navigate = useNavigate();
  const [ProjectCategory, setProjectCategory] = useState([]);
  const topPlotSizes1 = [
    { breath: 25, length: 45 },
    { breath: 30, length: 40 },
    { breath: 30, length: 45 },
    { breath: 30, length: 65 },
    { breath: 35, length: 60 },
  ]
  const topPlotSizes2 = [
    { breath: 40, length: 60 },
    { breath: 40, length: 80 },
    { breath: 50, length: 60 },
    { breath: 25, length: 60 },
    { breath: 15, length: 40 },
  ]

  const handleProductListPage = (value) => {
    navigate('/list-products', { state: { type: 'listing', listingId: value.id } });
  }

  // filter listing by plot sizes
  const handleProductListPageByPlot = (size) => {
    let plotSize = {
      plotLenght: size?.length,
      plotBreath: size?.breath
    }
    navigate('/list-products', { state: { type: 'listing', plotSize } });
  }

  const handleProjectCategory = async () => {
    let response = await homeService.getArchitecularService();
    if (response.status === 200) {
      setProjectCategory(response.data)
    }
  }

  useEffect(() => {
    handleProjectCategory();
  }, [])

  useEffect(() => {
    console.log(ProjectCategory, 'herrerere');
  }, [])

  return (
    <>
      <div className={styles.home_banner}>
        <Container>
          <div className="row">

            <div className="col-lg-6 px-4 d-flex align-items-center pb-lg-0 mb-lg-0 pb-5 mb-3 ">
              <div>
                <h1>We design<br />great Homes</h1>
                <p className="py-4 pe-4 m-0">No matter what your Architectural needs, we can connect you with a creative expert to make your Space look and feel professional. Because good design makes great feel.</p>
                <div className={`${styles.banner_input_btn} d-flex gap-4 mb-4`}>
                  <Form.Control
                    type="text"
                    id="inputPassword5"
                    placeholder="What do you need planned?"
                    aria-describedby="passwordHelpBlock"
                  />
                  <Link className={styles.getOtpBtn} to='/'>
                    Get a design
                  </Link>
                </div>
                <div className={`${styles.tiles} gap-3 d-flex align-items-center flex-sm-nowrap flex-wrap`}>
                  <label>Popular:</label>
                  <Link className={styles.button_pills} to="#">Floor Plans</Link>
                  <Link className={styles.button_pills} to="#">Elevation</Link>
                  <Link className={styles.button_pills} to="#">House Design</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-5">
              <Swiper
                modules={[Pagination]}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{ clickable: true }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide >
                  <img src={Slide1} alt='' width='100%' height='100%' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Slide1} alt='' width='100%' height='100%' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Slide1} alt='' width='100%' height='100%' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Slide1} alt='' width='100%' height='100%' />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.popu_archi_servi}>
        <Container>
          <div className="row">
            <div className="col-12">
              <h2 className="mb-4">Popular Architectural services</h2>
              <div className="col-12 px-3 position-relative">
                <Swiper
                  modules={[Navigation]}
                  spaceBetween={12}
                  slidesPerView={5}
                  navigation
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    // when window width is >= 640px
                    320: {
                      slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    568: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1200: {
                      slidesPerView: 5,
                    },
                  }}
                >
                  {/* console.log(ProjectCategory,'herrererere'); */}
                  {ProjectCategory?.map((project) => (
                    <SwiperSlide onClick={() => handleProductListPage(project)} key={project.id}>
                      <img src={`${IMAGE_BASE_URL}/${project.image}`} alt='' width='100%' />
                      <div className={styles.owlText} >
                        <label>{project.label}</label>
                        <div className="d-flex gap-3">
                          <h6>{project.name}</h6><img src={Arrow} alt='' width='30px' height='100%' />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}

                </Swiper></div>
            </div>
          </div>
        </Container>
      </div>
      {/* our partners */}
      <PartnerBox />
      <Container>
        <div className={styles.hr_border_line}></div>
      </Container>
      <div className={styles.we_design_home}>
        <Container>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className={styles.left_homedes_text}>
                <h2>We Design new home <br />for you</h2>
                <p>At Planningg.homes, we are committed to providing exceptional architectural services to help you design the home of your dreams. We have the expertise and creativity whether you are starting from scratch or looking to renovate an existing property.</p>
              </div>
            </div>
            <div className="col-lg-6  col-12 ps-lg-4 pe-lg-4">
              <h4>Why Planningg.home designs are better</h4>
              <Container>
          <div className={styles.faq_inn_box}>
            <Accordion className={styles.accodian_main} defaultActiveKey="0">
              <Accordion.Item className={styles.accordian_item} eventKey="0">
                <Accordion.Header className={styles.accordian_ques}>Tailored your <span className='ms-2'> Needs</span> </Accordion.Header>
                <Accordion.Body className={styles.accordian_ans}>
                Our designs are customized to meet your specific requirements and preferences, ensuring that the end result is a home that truly reflects your style.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className={styles.accordian_item} eventKey="1">
                <Accordion.Header  className={styles.accordian_ques}> <span className='me-2'>Innovative </span> and Creative</Accordion.Header>
                <Accordion.Body  className={styles.accordian_ans}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className={styles.accordian_item} eventKey="2">
                <Accordion.Header  className={styles.accordian_ques}>Attention to Quality with Details</Accordion.Header>
                <Accordion.Body  className={styles.accordian_ans}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>     
             
            </Accordion>
          </div>
        </Container>
            </div>
          </div>
        </Container>
      </div>
      <FeaturedCatgeory />
      <div className={styles.ploat_size}>
        <Container>
          <h3>Popular Plot Sizes</h3>
          <div className="row justify-content-center">
            <div className="col-1 d-none d-lg-flex"></div>
            {topPlotSizes1.map((size, i) => {
              return (
                <>
                  <div className="col-lg-2 col-md-3 col-sm-4 col-6 pb-5 mb-2 text-center" key={i} onClick={() => handleProductListPageByPlot(size)}>
                    <div className={styles.plot_single_box}>
                      <label>Plot Size</label>
                      <p>{size.breath}' X {size.length}'</p>
                      <img src={Demo} alt='' width='72px' />
                    </div>
                  </div>
                </>
              )
            })}
            <div className="col-1 d-none d-lg-flex"></div>
            <div className="col-1 d-none d-lg-flex"></div>
            {topPlotSizes2.map((size, i) => {
              return (
                <>
                  <div className="col-lg-2 col-md-3 col-sm-4 col-6 pb-5 mb-2 text-center" key={i} onClick={() => handleProductListPageByPlot(size)}>
                    <div className={styles.plot_single_box}>
                      <label>Plot Size</label>
                      <p>{size.breath}' X {size.length}'</p>
                      <img src={Demo} alt='' width='72px' />
                    </div>
                  </div>
                </>
              )
            })}
            <div className="col-1 d-none d-lg-flex"></div>
          </div>
        </Container>
      </div>
      {/* Trending Design Box */}
      <TrendingDesign title="Trending Designs" />

      <div className={styles.quotes_two_sec}>
        <Container>
          <div className="row">
            <div className="col-lg-6 col-12 pe-md-4">
              <h3>Get Customised Quotes</h3>
              <div className="row flex-column-reverse flex-xl-row">
                <div className={`${styles.quotes_ind_sec} col-xl-6 col-12`}>
                  <p>Unable to find a good design for your house ? Looking for bulk spare parts procurement? Share your Request For Quotation (RFQ) and we will provide you with a custom quote at the best prices.</p>
                  <Link to='/your-design'>Give Requirements</Link>
                </div>
                <div className={`${styles.quotes_indimg_sec} col-xl-6 col-12`}>
                  <img className='img-fluid' src={DummyImg} alt="Your SVG" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 ps-md-4">
              <h3>Become a Planningg.Home Partner</h3>
              <div className="row flex-column-reverse flex-xl-row">
                <div className={`${styles.quotes_ind_sec} col-xl-6 col-12`}>
                  <p>Join Planningg.Homes and boost your annual income or your auto spare parts business. Click here to be a part of the prospering and evergrowing Planningg family.</p>
                  <Link to='/parnter-now'>Partner Now</Link>
                </div>
                <div className={`${styles.quotes_indimg_sec} col-xl-6 col-12`}>
                  <img src={DummyImg} alt="Your SVG" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.plan_vastu}>
        <Container>
          <div className={styles.plan_vastu_colorbox}>
            <label>Planning Vaastu</label>
            <h3>Get Vaastu Consultation<br /> <span>in minutes</span></h3>
            <p>Our Vaastu top talent. Just add your touch.</p>
            <Link to='/try-vasstu'>Try Vaastu</Link>
          </div>
        </Container>
      </div>
      <div className={styles.offers_main_slides}>
        <div className={styles.offer_bg_column}></div>
        <Container>
          <h3>Offers</h3>
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={25}
            slidesPerView={4}
            navigation
            pagination={{ clickable: true }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // when window width is >= 640px
              320: {
                slidesPerView: 1.5,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2.5,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
            className="categories_slider px-1 offer_sliders"
          >
            <SwiperSlide>
              <div className={`${styles.offer_ind_box} d-flex align-items-center`}>
                <div className={styles.offer_box_text}>
                  <h5>MobiKwik</h5>
                  <p>5% Cashback Upto Rs 300 with MobiKwik</p>
                </div>
                <img src={MobiKwik} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`${styles.offer_ind_box} d-flex align-items-center`}>
                <div className={styles.offer_box_text}>
                  <h5>Freecharge</h5>
                  <p>5% Cashback upto Rs.350 on Freecharge Paylater</p>
                </div>
                <img src={Freecharge} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`${styles.offer_ind_box} d-flex align-items-center`}>
                <div className={styles.offer_box_text}>
                  <h5>Simpl</h5>
                  <p>5% Cashback upto ₹750</p>
                </div>
                <img src={Simpl} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`${styles.offer_ind_box} d-flex align-items-center`}>
                <div className={styles.offer_box_text}>
                  <h5>Lazypay</h5>
                  <p>Get up to Rs 75 Cashback via Lazypay</p>
                </div>
                <img src={Lazypay} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`${styles.offer_ind_box} d-flex align-items-center`}>
                <div className={styles.offer_box_text}>
                  <h5>MobiKwik</h5>
                  <p>5% Cashback Upto Rs 300 with MobiKwik</p>
                </div>
                <img src={Freecharge} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </Container>
      </div>
      <div className={styles.blog_posts_slider}>
        <Container>
          <h3>From Our Blogs</h3>
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={25}
            slidesPerView={3}
            navigation
            pagination={{ clickable: true }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // when window width is >= 640px
              320: {
                slidesPerView: 1,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
            }}
            className="categories_slider px-1 blog_sliders"
          >
            <SwiperSlide>
              <div className={`${styles.blog_ind_box}`}>
                <img src={BlogImg} alt="" />
                <h5>Start an online business and work from home</h5>
                <p>A complete guide to starting a small business online</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`${styles.blog_ind_box}`}>
                <img src={BlogImg} alt="" />
                <h5>Digital marketing made easy</h5>
                <p>A practical guide to understand what is digital marketing</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`${styles.blog_ind_box}`}>
                <img src={BlogImg} alt="" />
                <h5>Create a logo for your business</h5>
                <p>A step-by-step guide to create a memorable business logo</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`${styles.blog_ind_box}`}>
                <img src={BlogImg} alt="" />
                <h5>Start an online business and work from home</h5>
                <p>A complete guide to starting a small business online</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </Container>
      </div>
      <div className={styles.planningg_home_benifits}>
        <Container>
          <h3>Planningg.Home Benifits</h3>
        </Container>
        <div className={styles.inner_colored_plannbox}>
          <Container>
            <h4>Why Planningg is better than Tradional</h4>
            <div className={styles.every_svg_planimg}><img src={EveryPlan} alt="" /> </div>
            <div className="row pt-5">
              <div className="col-lg-4 col-sm-6 col-12 d-flex align-items-start gap-3 p-3 pe-4">
                <img src={Unlimited} alt="" />
                <div className={styles.plans_text_sect}>
                  <h5>Unlimited design projects</h5>
                  <p>Submit and queue up as many design projects as you want on our platform.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12 d-flex align-items-start gap-3 p-3 pe-4 border-end border-start border-2">
                <img src={Pointclick} alt="" />
                <div className={styles.plans_text_sect}>
                  <h5>Point & Click revisions</h5>
                  <p>Mark your revisions directly on the design using our App. Simple and hassle-free.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12 d-flex align-items-start gap-3 p-3 pe-4">
                <img src={Folder} alt="" />
                <div className={styles.plans_text_sect}>
                  <h5>Brand folders</h5>
                  <p>Outsourcing to Penji? Organize and manage your clients better with folders.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12 d-flex align-items-start gap-3 p-3 pe-4">
                <img src={Clock} alt="" />
                <div className={styles.plans_text_sect}>
                  <h5>1-2 Day turnaround</h5>
                  <p>Speed without compromise. 1-2 days turnaround for simple and 3-4 days for complex projects.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12 d-flex align-items-start gap-3 p-3 pe-4 border-end border-start border-2">
                <img src={Days} alt="" />
                <div className={styles.plans_text_sect}>
                  <h5>120+ Design services</h5>
                  <p>One-stop shop for all your needs. 120+ services, there's a designer for any and every task.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12 d-flex align-items-start gap-3 p-3 pe-4">
                <img src={Vetted} alt="" />
                <div className={styles.plans_text_sect}>
                  <h5>Vetted designers</h5>
                  <p>Penji only hires the top 2% of all designers, ensuring you only work with top talent.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12 d-flex align-items-start gap-3 p-3 pe-4">
                <img src={TeamColl} alt="" />
                <div className={styles.plans_text_sect}>
                  <h5>Team collaboration</h5>
                  <p>Invite your team to work with our designers on client projects, marketing campaigns, or product designs.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12 d-flex align-items-start gap-3 p-3 pe-4 border-end border-start border-2">
                <img src={CustomerSup} alt="" />
                <div className={styles.plans_text_sect}>
                  <h5>24/7 Customer support</h5>
                  <p>Need help? Our friendly support team is always on standby, ready to assist.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12 d-flex align-items-start gap-3 p-3 pe-4">
                <img src={Moneyback} alt="" />
                <div className={styles.plans_text_sect}>
                  <h5>Money-back guarantee</h5>
                  <p>Get a full refund within 30 days if Penji isn't the perfect graphic design service for you.</p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className={styles.why_designing_sec}>
        <Container>
          <h3>See Why Designing is better with us..!</h3>
          <div className="row">
            <div className="col-lg-6 col-12">
              <table className={`w-100`}>
                <tr>
                  <th></th>
                  <th><img src={Logo} alt="" height='32px' /></th>
                  <th><span>Traditional process</span></th>
                </tr>
                <tr>
                  <th>Cost</th>
                  <th>5%</th>
                  <th>5-6%</th>
                </tr>
                <tr>
                  <td>Employs the latest software to design homes</td>
                  <td> <img src={RedTick} alt="" /> </td>
                  <td> <img src={Greytick} alt="" /> </td>
                </tr>
                <tr>
                  <td>Expect a faster turnaround time.</td>
                  <td> <img src={RedTick} alt="" /> </td>
                  <td> <img src={Quesmark} alt="" /> </td>
                </tr>
                <tr>
                  <td>We offer transparent pricing upfront.</td>
                  <td> <img src={RedTick} alt="" /> </td>
                  <td> <img src={Quesmark} alt="" /> </td>
                </tr>
                <tr>
                  <td>Collaborate with our team at any time, any where</td>
                  <td> <img src={RedTick} alt="" /> </td>
                  <td> <img src={CrossX} alt="" /> </td>
                </tr>
                <tr>
                  <td>Strong emphasis on sustainable design.</td>
                  <td> <img src={RedTick} alt="" /> </td>
                  <td> <img src={Quesmark} alt="" /> </td>
                </tr>
                <tr>
                  <td>Team of skilled architects combines creativity.</td>
                  <td> <img src={RedTick} alt="" /> </td>
                  <td> <img src={Quesmark} alt="" /> </td>
                </tr>
              </table>
            </div>
            <div className={`${styles.tbl_desi_img} col-lg-6 col-12 d-flex justify-content-end align-items-center pt-2`}>
              <img src={WhyImg} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.faq_main_box}>
        <Container>
          <div className={styles.faq_inn_box}>
            <h3>Frequently asked question ?</h3>
            <Accordion className="faq_single_set" defaultActiveKey="0">
              <Accordion.Item className="faq_ques" eventKey="0">
                <Accordion.Header>Where are Planningg designers located?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="1">
                <Accordion.Header> How quick is Planningg turnaround time?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="2">
                <Accordion.Header>How many hours a month will my designer work? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="3">
                <Accordion.Header>How many designs can I get in a month? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="4">
                <Accordion.Header>How do revisions work? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="5">
                <Accordion.Header>What happens if I do not like my design? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="6">
                <Accordion.Header>What design software do your designers use? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="7">
                <Accordion.Header>How does Planningg 30-day money back guarantee work? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="8">
                <Accordion.Header>What does unlimited Architectural design mean? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="9">
                <Accordion.Header>What is the max amount of updates I can expect in one day?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Container>
      </div>
      {/* Try Us Box */}
      <TryUsBox title1="Ready to try Us?" />
    </>
  )
}