import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../../../assets/images/Logo-Planning.png';
import { Link, useNavigate } from 'react-router-dom';
import ProfileImage from '../../../assets/images/Profile.png';
import './header.styles.scss'
import homeService from '../../../services/homeService';
import { InputGroupProps } from 'react-bootstrap';
import { ConsultationForm } from '../../common/consultation';
// import { Col, Row } from 'react-bootstrap';
export const Header = () => {
  const [ProjectCategory, setProjectCategory] = useState([]);
  const handleProjectCategory = async () => {
    let response = await homeService.getArchitecularService();
    if (response.status === 200) {
      setProjectCategory(response.data)
    }
  }

  useEffect(() => {
    handleProjectCategory();
  }, [])
  const navigate = useNavigate();
  const handleProductListPage = (value) => {
    navigate('/list-products', { state: { type: 'listing', listingId: value.id } });
  }

  const data = localStorage.getItem('id')
  return (
    <Navbar expand="lg" className="">
      <Container>
        <Navbar.Brand >
          <Link to='/'> <img src={Logo} alt='' height='' width='123px' /></Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <NavDropdown title="Solution" id="basic-nav-dropdown">
      {ProjectCategory?.map((project) => (
        <NavDropdown.Item
          key={project.id}
          onClick={() => handleProductListPage(project)}
        >
          {project.name}
        </NavDropdown.Item>
      ))}
    </NavDropdown>

            <Nav.Link> <Link to='/how-it-works'>How it works</Link></Nav.Link>
            <Nav.Link> <Link to='/inspiration-list'>Inspiration</Link></Nav.Link>
            <NavDropdown title="Resources" >
              <NavDropdown.Item>Resources 4</NavDropdown.Item>
              <NavDropdown.Item>
                Resources 5
              </NavDropdown.Item>
     
            </NavDropdown>
            <Nav.Link> <Link to='/pricing'>Packages</Link></Nav.Link>
            <Nav.Link> <Link to='/your-design'>Custom Design</Link></Nav.Link>

          </Nav>
        </Navbar.Collapse>
        <div className='d-flex gap-4 nav_last_numbox'>
          <p className='d-none d-sm-block mb-0'>1 800 513 1678</p>
          {data ?
            <Link className='login-btn' to='/profile' ><img src={ProfileImage} alt='' height='' width='25px' />Profile </Link>
            :
            <Link className='login-btn' to='/login' >Login </Link>
          }
        </div>
      </Container>
      <ConsultationForm />
    </Navbar>
  )
}