import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './how_it_works.module.scss'
import { ReactComponent as LeftArrow } from '../../../assets/images/svg/arrow_right_white.svg';
import planningworkbanner from "../../../assets/images/planning_work_banner.png"
import illustrationbanner from "../../../assets/images/illustration-banner.png"
import thoughtfulrevision from "../../../assets/images/thoughtful-revision-img.png"
import aibanner from "../../../assets/images/ai-banner.png"
import teximg1 from "../../../assets/images/1-img.png"
import textimg2 from "../../../assets/images/2-img.png"
import { Link } from 'react-router-dom';
import { Reviews } from '../../common/review_box';
import { HaveMoreQuestionBox } from '../../common/haveMoreQuestion';
import { ExportOtherServices } from '../../common/export-other-services';
import { TryUsBox } from '../Home/TryUsBox';
import Carratarrow from "../../../assets/images/caratarrow.png"


export const HowItWorks = () => {
  return (
    <div >
      <div className={`${styles.main_banner} ${styles.spacing}`}>
        <Container>
          <Row>
            <Col lg={6} md={12}>
              <div className='listing'>
                <div direction="horizontal" gap={1}>
                  <Link className="text-light text-decoration-none fw-normal" to="">Home </Link>
                  <img className={`${styles.arrow_img}`} src={Carratarrow} alt=""/>
                  <Link className="text-light text-decoration-none fw-bold" to="">How It Works?</Link>
                </div>
              </div>
              <h1 className={`${styles.heading_text}`}> <span className={`${styles.filled_text}`}>How planningg Works?</span></h1>
              <p className={`${styles.para_text}`}>We make great design work happen with<br /> our global community of professional<br /> designers. If you'd like to speak to a human,<br /><u>talk to one of our design experts</u></p>

            </Col>
            <Col lg={6} md={12}>
              <img className={`${styles.planning_banner_img} ps-5 pt-5 img-fluid `} src={planningworkbanner} alt="work-banner" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className={`${styles.main_circle_box}`}>
        <Container>
          <Row>
            <Col md={3}>
              <div className='d-flex align-items-center w-100'>
                <span className={`${styles.purple_circle}`}>1</span>
                <p className='mb-0'>Initial Consultation</p>
              </div>
            </Col>
            <Col md={3}>
              <div className='d-flex align-items-center w-100'>
                <span className={`${styles.blue_circle}`}>2</span>
                <p className='mb-0'>Design Development</p>
              </div>
            </Col>
            <Col md={3}>
              <div className='d-flex align-items-center w-100'>
                <span className={`${styles.yellow_circle}`}>3</span>
                <p className='mb-0'>Receive within 1-2 days</p>
              </div>
            </Col>
            <Col md={3}>
              <div className='d-flex align-items-center w-100'>
                <span className={`${styles.pink_circle}`}>4</span>
                <p className='mb-0'>Finalization and Delivery</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={`${styles.create_design_box}`}>
        <Container>
          <Row>
            <Col lg={6} md={12}>
              <div className={`${styles.design_box}`}>
                <h3>Initial Consultations</h3>
                <div className='mt-4 mb-5'>
                  <p>Start by filling out a simple form detailing your design needs. You can create multiple projects to form a queue.</p>
                </div>
                <div className={`${styles.grey_box}`}>
                  <p className={`${styles.ques_text} mb-0`}>How does the initial consultation process work?</p>
                </div>
                <div className={`${styles.grey_box} `}>
                  <p className={`${styles.ques_text} mb-0`}>Can I provide my own design ideas and inspiration?</p>
                </div>
                <div className={`${styles.grey_box} mb-0`}>
                  <p className='mb-0'>How long is your turnaround time?</p>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className={''}>
                <div className={`${styles.text_banner1}`}>
                  <img className='pe-2 img-fluid' src={illustrationbanner} alt="img" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={`${styles.ai_design_box} `}>
        <Container>
          <Row className={`${styles.banner_row}`}>
            <Col lg={6} md={12}>
              <div className={''}>
                <div className={`${styles.text_banner2}`}>
                  <img className={`${styles.ai_banner} img-fluid`} src={aibanner} alt="img"/>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12} className='d-flex justify-content-end'>
              <div className={`${styles.design_box}`}>
                <h3>Design Development</h3>
                <div className='mb-5 mt-4'>
                  <p>Once we have a clear understanding of your requirements, our team of architects and<br></br> designers will begin the design development<br></br> phase.</p>
                </div>
                <div className={`${styles.grey_box}`}>
                  <p className={`${styles.ques_text} mb-0`}>How is the design concept developed?</p>
                </div>
                <div className={`${styles.grey_box}`}>
                  <p className={`${styles.ques_text} mb-0`}>Can I make changes during the design process?</p>
                </div>
                {/* <div className={`${styles.grey_box}`}>
                  <p className='mb-0'>What type of designs can I request?</p>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={`${styles.create_design_box}`}>
        <Container>
          <Row>
            <Col lg={6} md={12}>
              <div className={`${styles.design_box}`}>
                <h3>Finalization and Delivery</h3>
                <div className='mt-4 mb-5'>
                  <p>Once the design has been refined and finalized, we will provide you with the final design package.</p>
                </div>
                <div className={`${styles.grey_box}`}>
                  <p className={`${styles.ques_text} mb-0`}>How is the final design delivered?</p>
                </div>
                <div className={`${styles.grey_box}`}>
                  <p className={`${styles.ques_text} mb-0`}>What if I don’t like my final design?</p>
                </div>

              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className={''}>
                <div className={`${styles.text_banner3}`}>
                  <img className={`${styles.thoughtfulrevision_img} img-fluid`} src={thoughtfulrevision} alt="img" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={`${styles.border_line_box}`}>
        <hr></hr>
      </div>
      <div className={`${styles.spacing} ${styles.why_us}`}>
        <Container>
          <h2 className='mb-5'>So Why Us ?</h2>
          <Row className={`${styles.why_us_banner}`}>
            <Col md={6}>
              <div className={`${styles.box_banner} w-100 d-flex`}>
                <div className='w-25'>

                </div>
                <div className='w-75'>
                  <p className={`${styles.main_text} text-dark fw-bold`}>Expertise and Experience</p>
                  <p className='text-dark mt-2'>Our team consists of highly skilled architects and designers with extensive experience in the industry</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className={`${styles.box_banner} w-100 d-flex`}>
                <div className='w-25'>

                </div>
                <div className='w-75'>
                  <p className={`${styles.main_text} text-dark fw-bold`}>Tailored Design Solutions</p>
                  <p className='text-dark mt-2'>We believe in creating designs that are<br></br> tailored to your specific needs<br></br> and preferences</p>
                </div>
              </div>
            </Col>
          
          </Row>
          <Row>
            <Col md={6}>
              <div className={`${styles.box_banner} w-100 mt-4 d-flex`}>
                <div className='w-25'>

                </div>
                <div className='w-75'>
                  <p className={`${styles.main_text} text-dark fw-bold`}>Attention to Detail</p>
                  <p className='text-dark mt-2'>Our design approach emphasizes meticulous attention to detail in<br></br> every aspect of the project</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className={`${styles.box_banner} w-100 mt-4 d-flex`}>
                <div className='w-25'>

                </div>
                <div className='w-75'>
                  <p className={`${styles.main_text} text-dark fw-bold`}>Client Satisfaction</p>
                  <p className='text-dark mt-2'>Your project are our top priorities, and we go the extra mile to ensure that you are<br></br> thrilled with the final design.</p>
                </div>
              </div>
            </Col>
            <Col className='text-center mt-5 mb-5' md={12}>
              <button className={`${styles.get_sample_btn}`}>Get Sample</button>
            </Col>
          </Row>
        </Container>
      </div>
      <Reviews />
      <HaveMoreQuestionBox />
      <ExportOtherServices />
      <TryUsBox title1="Ready to try " title2='Floor Plan?' page='howitWork' />

    </div>
  )
}
