import React from 'react'
import { Container } from 'react-bootstrap'
import styles from './export_other_service.module.scss'
import ArrowRight from '../../../assets/images/svg/arrow_right.svg'

export const ExportOtherServices = () => {
    const cardAarr = [
        { id: 1, title: "Floor Plan", despcription: "With our team, we create accurate and detailed floor plans that showcase the layout and dimensions of your property." },
        { id: 1, title: "3D Elevation", despcription: "Stunning 3D elevation services that allow you to see your building design from every angle and perspective." },
        { id: 1, title: "Vaastu ", despcription: "Convenient online Vaastu consultation help you to harmonize your living or working spaces with the principles of Vaastu Shastra." },
    ]
    return (
        <div className={styles.expo_othe_serv}>
            <Container>
                <h4>Explore other Services</h4>
                <div className={styles.card_main}>
                    {cardAarr.map((item, i) => {
                        return (
                            <div className={styles.serv_card} key={i}>
                                <div className='row'>
                                    <div className={`col-md-3`}>
                                        <div className={styles.card_circle}></div>
                                    </div>
                                    <div className={`${styles.card_text} col-md-9`}>
                                        <h6>{item.title}</h6>
                                        <span>
                                            {item.despcription}
                                        </span>
                                        <div className={styles.arrow}>
                                            <img src={ArrowRight} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Container>
        </div>
    )
}
