import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import styles from './conslt_btn.module.scss';
import { ConsultationForm } from '../consultation';
export const GetFreeConslt = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className={styles.consltbtn_box}>
      <Container>
        <div className='row'>
          <div className='col-lg-7 col-md-7'>
            <span>
              Ready to get started with House Floor Plan Services?<br />
              It's fast and very easy!
            </span>
          </div>
          <div className='col-lg-5 col-md-5'>
            <button type='button' className={styles.get_conslt} onClick={handleOpenModal}>
              Get Free Consultation
            </button>
            <ConsultationForm showConsultationModal={showModal} handleClose={handleCloseModal} /> {/* Render the ConsultationForm component */}
          </div>
        </div>
      </Container>
    </div>
  );
};
