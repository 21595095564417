import fetch from "./FetchInterceptor";

const ConsultationService = {};

ConsultationService.submitConsultationForm = function (data) {
    console.log(data,'afsfdasfdfds');
    return fetch({
      url: "/add-reuest-consultation",
      method: "post",
      data: data,
    });
  };

  export default ConsultationService;