import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import styles from './consultation.module.scss';
import ConsultationService from '../../../services/ConsultationService';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
export const ConsultationForm = ({ showConsultationModal, handleClose }) => {
    const navigate = useNavigate();
    const { handleSubmit, control, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        const response = await ConsultationService.submitConsultationForm(data);
        if (response.status == 200) {
            navigate('/thank-you');
                } else {

        }
    };

    return (
     
            <Modal
                show={showConsultationModal}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName={styles.my_modal}
            >
                <Modal.Header className={styles.modal_header} closeButton>
                    <Modal.Title aria-labelledby="example-modal-sizes-title-lg" className={styles.background_image_title}></Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.modal_body} >
                   <div className={styles.modal_box_outer}>
                      <div className={styles.modal_box_left}>
                            <h1>Unlock best deal by Booking Best Deal today</h1>
                      </div>
                      <div className={styles.modal_box_right}>
                          <h3>Book a free Consultancy Call</h3>
                          <p className='mt-4 mb-4 text-center'>Get a complementary Estimation and Consultation from<br></br> Our Expert</p>
                            <Form className={styles.demo_form}  onSubmit={handleSubmit(onSubmit)}>
                                <Row className='mt-2 mb-4'>
                                    <Col>
                                        <Form.Group className={styles.form_group}>
                                            <Controller
                                                name="name"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <Form.Control
                                                            placeholder="Full Name"
                                                            {...field}
                                                            required
                                                        />
                                                        {errors.email && (
                                                            <p className={styles.error_message}>Name is required</p>
                                                        )}
                                                    </>
                                                )}
                                            />
                                            <p>{errors.name?.message}</p>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mb-4'>
                                    <Col>
                                        <Form.Group>
                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <Form.Control
                                                            placeholder="Email ID"
                                                            {...field}
                                                            required // Add required attribute
                                                        />
                                                        {errors.email && (
                                                            <p className={styles.error_message}>Email is required</p>
                                                        )}
                                                    </>

                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-4 mt-2">
                                    <Col>
                                        <Form.Group>
                                            <Controller
                                                name="mobileNo"
                                                control={control}
                                                render={({ field }) => (
                                                    <InputGroup>
                                                        <InputGroup.Text id="basic-addon2">+91</InputGroup.Text>
                                                        <>
                                                            <Form.Control
                                                                placeholder="Phone  Number."
                                                                aria-label="mobile_no"
                                                                aria-describedby="basic-addon2"
                                                                {...field}
                                                                required
                                                            />
                                                            {errors.mobileNo && (
                                                                <p className={styles.error_message}>Mbile No is required</p>
                                                            )}
                                                        </>
                                                    </InputGroup>
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mt-2 mb-4'>
                                    <Col>
                                        <Form.Group>
                                            <Controller
                                                name="comments"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={4}
                                                            placeholder="Comments"
                                                            {...field}
                                                            required
                                                        />
                                                        {errors.comments && (
                                                            <p className={styles.error_message}>Mbile No is required</p>
                                                        )}
                                                    </>

                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                   <Col>
                                       <div className='d-flex ps-5 pe-5 align-items-baseline'>
                                           <input type='checkbox'></input>
                                           <p className='ms-3 mb-3'>I hereby authorize Squareyards.com & its partners to contact me via Whatsapp, sms, phone, email</p>
                                       </div>
                                   </Col>
                                </Row>
                                
                                <Row className="mt-2">
                                    <Button type="submit" variant="primary" className={styles.custom_button} size="md">
                                        Book a Demo
                                    </Button>
                                </Row>
                            </Form>
                      </div>
                   </div>
                    
                </Modal.Body>
               
            </Modal>    
    );
};
