import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './price.module.scss';
import TickFilledGreen from '../../../assets/images/svg/filled_green_tick.svg';
import SafeGurd from '../../../assets/images/svg/safe_gurd.svg';
import { Link } from 'react-router-dom';

export const PriceBox = ({ activePackages }) => {
  console.log('Active Packages:', activePackages);

  return (
    <div className={styles.packages}>
      <Container>
        <Row className='pt-3 d-flex align-items-center justify-content-center'>
          {activePackages?.length > 0 ? (
            activePackages.map((Package, index) => (
              <Col key={index} md={4} className={`mb-4 ${styles.packages_plan}`}>
                <div className='pb-4 border-bottom'>
                  <h4>{Package.package_types}</h4>
                  <h1>₹{Package.package_pricing}</h1>
                  <Link to={`/payment?package=${JSON.stringify(Package)}`}>
                    <button type='button'>Choose {Package.package_types}</button>
                  </Link>
                  <button type='button' className={styles.btn_bordred}>
                    Chat to sales
                  </button>
                </div>
                <div className='p-3 d-flex flex-column'>
                  <label>What you get with {Package.package_types}:</label>
                  {/* Render other package details based on your API response */}
                  {Package?.packages_details_points && JSON.parse(Package.packages_details_points).map((point, index) => (
                    <span className='d-flex align-items-center gap-2 pt-3 pb-2'>
                      <img src={TickFilledGreen} alt='' />
                      {Object.values(point)[0]}
                    </span>
                  ))}
                  {/* Add similar lines for other details */}
                  <span className='d-flex align-items-center gap-2 pt-2 pb-2'>
                    <img src={SafeGurd} alt='' />
                    {Package.package_status === 'Active' ? 'Active' : 'Inactive'}
                  </span>
                </div>
              </Col>
            ))
          ) : (
            <Col md={12} className='text-center'>
              <p className='text-white'>No active packages available.</p>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

