import fetch from "./FetchInterceptor";

const AuthService = {};

AuthService.register = function (data) {
  return fetch ({
    url:"register",
    method:"post",
    data:data,
  });
};
AuthService.login = function (data) {
  return fetch({
    url: "login",
    method: "post",
    data: data,
  });
};

AuthService.ForgotPassword = function (data) {
  return fetch({
    url: "forgot-password",
    method: "post",
    data: data,
  });
};

AuthService.ResetPassword = function (data) {
  return fetch({
    url: "reset-password",
    method: "post",
    data: data,
  });
};
export default AuthService;
