import styles from './custom.module.scss'
import { TryUsBox } from '../Home/TryUsBox'
import { ExportOtherServices } from '../../common/export-other-services'
import { HaveMoreQuestionBox } from '../../common/haveMoreQuestion'
import { Reviews } from '../../common/review_box'
import { FeaturedCatgeory } from '../../common/Featured_catergory'
import { PriceBox } from '../../common/price-box'
import ArrowDown from '../../../assets/images/svg/arrow_down_red.svg'
import { MainBannner } from '../../common/banner'
import img1 from '../../../assets/images/div1.png'
import img2 from '../../../assets/images/div2.png'
import InsideDsign from '../../../assets/images/Inside-design.png'
import { Container } from 'react-bootstrap'




export const CustomHouseDesign = () => {
  return (
    <div>
      <MainBannner title1={'Custom Premium '} title2={'Floor Plans'} imageBox={true} pageLink={'House Floor Plan'} />
      <Container>
        <div className='d-flex align-items-center justify-content-center mt-4 mb-4'>
          <img src={img1} alt='' className='me-2' />
          <img src={img2} alt='' className='ms-2' />
        </div>
      </Container>
      <Container>
        <div className={styles.getyourdesgin}>
          <label>How It Works</label> 
          <span>Get your designs done in 1-2 days, not weeks</span>
          <img src={InsideDsign} alt='' />
        </div>
      </Container>
      <div className={styles.packages}>
        <div className='d-flex align-items-center justify-content-center p-4 flex-column '>
          <h2 >We’ve got a plan that is perfect for you</h2>
          <h5 className='pt-3'>Find the best home designing packages.*</h5>
          <h5 className='pt-3'>Currently showing for
            <label className='ms-1'>
              floor plans
              <img src={ArrowDown} alt='' className='ms-2' />
            </label>
          </h5>
        </div>
        <PriceBox />
      </div>
      <FeaturedCatgeory />
      <Reviews />
      <HaveMoreQuestionBox />
      <ExportOtherServices />
      <TryUsBox title1="Ready to try " title2='Floor Plan?' page='custom-desgin' />

    </div>
  )
}
