import React, { useEffect, useState } from 'react';
import styles from './description.module.scss';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Icon from '../../../assets/images/svg/i_icon.svg';
import Clock from '../../../assets/images/svg/clock_vector.svg';
import RedTick from '../../../assets/images/svg/tick_red.svg';
import ArrowRight from '../../../assets/images/svg/arrow_right_white.svg';
import { Link, useLocation } from 'react-router-dom';

export const Pricing = ({ listDescription }) => {
    const [key, setKey] = useState('Basic');
    const packages = listDescription?.package_data;
    useEffect(() => {
        if (packages && packages.length > 0) {
            setKey(packages[0].package_types);
        }
    }, [listDescription]);

    return (
        <Tabs
            defaultActiveKey="Basic"
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className={`${styles.tabsDesign} mb-3`}
        >
            {packages?.map((packageData, index) => (
                <Tab
                    key={index}
                    className={`${styles.sectionTab}`}
                    eventKey={packageData.package_types}
                    title={packageData.package_types.charAt(0).toUpperCase() + packageData.package_types.slice(1)} // Capitalize the first letter
                >
                    <div className="">
                        <div className={styles.plan_desp}>
                            <div className={styles.heading}>
                                <span>{packageData.package_name}</span>
                                <div className="d-flex gap-2">
                                    <label>₹{packageData.package_pricing}</label>
                                    <img src={Icon} alt="" />
                                </div>
                            </div>
                            <p className="pt-4 pb-3">
                                {packageData.details_offering_packages}
                            </p>
                            <div className={styles.plan_pointer}>
                                <img src={Clock} alt="" />
                                <span>
                                    {packageData.package_revision.deliveryTime} Days Delivery
                                </span>
                            </div>
                            <div className={styles.pointer_list}>
                                {Object.keys(JSON?.parse(packageData.with_package))?.map((item, itemIndex) => (
                                    <div key={itemIndex} className={styles.pointer}>
                                        <img src={RedTick} alt="" />
                                        <span>{item}</span>
                                    </div>
                                ))}
                            </div>
                            <Link to={`/payment?package=${JSON.stringify(packageData)}`}>
                                <button type="button" className={styles.continue_btn}>
                                    <span>Continue</span>
                                    <img src={ArrowRight} alt="" />
                                </button>
                            </Link>
                            <span className="d-flex align-items-center justify-content-center">
                                Get Sample
                            </span>
                        </div>
                    </div>
                </Tab>
            ))}
        </Tabs>
    );
};
