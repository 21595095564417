import React from 'react'
import styles from './profile.module.scss';
import Build2 from '../../../assets/images/building2.png';
// import ProVerified from '../../../assets/images/svg/proverified.svg';
// import Heart from '../../../assets/images/svg/heart.svg';
import Button from 'react-bootstrap/Button';
import CrossX from '../../../assets/images/svg/xmark.svg';

export const FavouriteDesign = () => {
  return (
    <div className="col-xl-9 col-lg-8 m-b30">
    <div className={`${styles.shop_bx} ${styles.shop_profile}`}>
      <div className={`${styles.shop_bx_title} ${styles.clearfix}`}>
        <h5 className="text-uppercase">Favorite Design</h5>
      </div>
      <div className='d-flex'>
      <div className={styles.single_categ_slide}>
          <div className={styles.imageContainer}>
            <img src={Build2} alt='' width='100%' />
            <img className={styles.crossX} src={CrossX} alt='X' /> {/* CrossX image */}
          </div>
                <h4 className={styles.MainHeadingBox}>Complete Design of 1800 Sqft House</h4>
                <p>Facing: SOUTH</p>
                <p>Size: 22ft X 44ft</p>
                <div className={`${styles.design_price_box} d-flex justify-content-between`}>
                  {/* <img src={Heart} alt="Your SVG" /> */}
                  <div className="">
                    <label>Starting at</label>
                    <p>₹868,704 <span>31</span></p>
                  </div>
                  <div className="">
                  <Button
                type="button"
              >
                View Details
              </Button>
                  </div>
                </div>
              </div>
              <div className={styles.single_categ_slide}>
          <div className={styles.imageContainer}>
            <img src={Build2} alt='' width='100%' />
            <img className={styles.crossX} src={CrossX} alt='X' /> {/* CrossX image */}
          </div>
                <h4 className={styles.MainHeadingBox}>Complete Design of 1800 Sqft House</h4>
                <p>Facing: SOUTH</p>
                <p>Size: 22ft X 44ft</p>
                <div className={`${styles.design_price_box} d-flex justify-content-between`}>
                  {/* <img src={Heart} alt="Your SVG" /> */}
                  <div className="">
                    <label>Starting at</label>
                    <p>₹868,704 <span>31</span></p>
                  </div>
                  <div className="">
                  <Button
                type="button"
              >
                View Details
              </Button>
                  </div>
                </div>
              </div>
              <div className={styles.single_categ_slide}>
          <div className={styles.imageContainer}>
            <img src={Build2} alt='' width='100%' />
            <img className={styles.crossX} src={CrossX} alt='X' /> {/* CrossX image */}
          </div>
                <h4 className={styles.MainHeadingBox}>Complete Design of 1800 Sqft House</h4>
                <p>Facing: SOUTH</p>
                <p>Size: 22ft X 44ft</p>
                <div className={`${styles.design_price_box} d-flex justify-content-between`}>
                  {/* <img src={Heart} alt="Your SVG" /> */}
                  <div className="">
                    <label>Starting at</label>
                    <p>₹868,704 <span>31</span></p>
                  </div>
                  <div className="">
                  <Button
                type="button"
              >
                View Details
              </Button>
                  </div>
                </div>
              </div>
              </div>
              
    </div>
  </div>
  )
}
