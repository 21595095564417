import Tnyt from '../../../assets/images/tnyt.png';
import Forbes from '../../../assets/images/forbes.png';
import ReviwIo from '../../../assets/images/reviw_io.png';
import Fortune from '../../../assets/images/fortune.png';
import Wsj from '../../../assets/images/wsj.png';
import Bbb from '../../../assets/images/bbb.png';
import { Container } from 'react-bootstrap';
import styles from './partner.module.scss'

export const PartnerBox = () => {
    return (
        <div className={styles.partn_logos}>
            <Container>
                <div className="row justify-content-center">
                    <div className={`${styles.partn_logo_box} col-xl-2 col-lg-3 col-sm-6 col-12 text-center`}>
                        <img src={Tnyt} alt='' />
                        <p>“Moving as simple as the click of a button”</p>
                    </div>
                    <div className={`${styles.partn_logo_box} col-xl-2 col-lg-3 col-sm-6 col-12 text-center`}>
                        <img src={Forbes} alt='' />
                        <p>“Changing the way Americans buy and sell”</p>
                    </div>
                    <div className={`${styles.partn_logo_box} col-xl-2 col-lg-3 col-sm-6 col-12 text-center`}>
                        <img src={ReviwIo} alt='' />
                        <p>4.44/5</p>
                    </div>
                    <div className={`${styles.partn_logo_box} col-xl-2 col-lg-3 col-sm-6 col-12 text-center`}>
                        <img src={Fortune} alt='' />
                        <p>“Fair offer price within minutes”</p>
                    </div>
                    <div className={`${styles.partn_logo_box} col-xl-2 col-lg-3 col-sm-6 col-12 text-center`}>
                        <img src={Wsj} alt='' />
                        <p>“Take the hassle out of selling”</p>
                    </div>
                    <div className={`${styles.partn_logo_box} col-xl-2 col-lg-3 col-sm-6 col-12 text-center`}>
                        <img src={Bbb} alt='' />
                        <p></p>
                    </div>
                </div>
            </Container>
        </div>
    )
}
