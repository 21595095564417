import { Container } from 'react-bootstrap'
import styles from './payment.module.scss'
import ArrowLeft from '../../../assets/images/svg/arrow_left.svg'
import ArrowRight from '../../../assets/images/svg/arrow_right.svg'
import GreenTick from '../../../assets/images/svg/tick_green.svg'
import { useEffect, useState } from 'react'
import { Register } from './register'
import { PaymentConfirm } from './PaymentConfirm'
import { Service } from './service'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import AuthService from '../../../services/AuthService'
import { ShowErrorMessage, ShowSuccessMessage } from '../Notification'
const servicePage = 'service'
const Registertion = 'register'
const PayConfirm = 'paymmentConfirm'

export const Payment = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const packageData = searchParams.get('package');
    const parsedPackage = packageData ? JSON.parse(packageData) : null;
    const [formData, setFormData] = useState(null);
    console.log(parsedPackage, 'hererrerereererre');
    const [activeStep, setActiveStep] = useState('')
    const isLoggedIn = localStorage.getItem('id')

    // const handleStep = () => {

    // }
    useEffect(() => {
        if (isLoggedIn) {
            setActiveStep(PayConfirm)
        } else {
            setActiveStep(Registertion)
        }
    }, []);

    useEffect(() => {
        handlecreateAccount();
    }, [formData]);

    const handlecreateAccount = async () => {
        if(formData){
            const response = await AuthService.register(formData);
            if (response.success === false) {
                ShowErrorMessage(response.message);
                return false;
              }
              else{
                ShowSuccessMessage(response.message)
                localStorage.setItem('accessToken', response.data.token);
                localStorage.setItem('id', response.data.id);
                window.location.reload(false)
            }
        }
        console.log('form data:', formData);
        // Rest of your logic
    };
    return (
        <div className={styles.payment_main}>
            <Container>
                <div className='d-flex align-items-center gap-2 pb-3'>
                    <img src={ArrowLeft} alt='' />
                    {!isLoggedIn && activeStep === Registertion ?
                        <h4>Create your account </h4>
                        :
                        <h4>Payment </h4>
                    }

                </div>
            </Container>
            <div className={styles.inner_main}>
                <Container>
                    <div className='row'>
                        {!isLoggedIn && activeStep === Registertion ?
                            <Register handleCreateAccount={handlecreateAccount} setFormData={setFormData} />
                               :
                            <PaymentConfirm />

                        }
                        <div className='col-lg-3 col-md-12'>
                            {/* Package box design for packages  */}
                            {parsedPackage?.packages_details_points ? (
                                <div className={styles.right_main}>
                                    <div className={styles.plan}>
                                        <div className='d-flex align-items-center justify-content-between w-100 border-bottom'>
                                            <h5>{parsedPackage?.package_types}</h5>
                                            <div className={styles.small_text}>Change Plan</div>
                                        </div>
                                        <div className='pt-3 d-flex align-items-center justify-content-between w-100'>
                                            <label>{parsedPackage?.package_name}</label>
                                            <span>₹{parsedPackage?.package_pricing}</span>
                                        </div>
                                        <div className='pt-3 pb-3'>
                                            {parsedPackage?.packages_details_points && JSON.parse(parsedPackage.packages_details_points).map((point, index) => (
                                                <span className='d-flex gap-3 pt-1 pb-1'>
                                                    <img src={GreenTick} alt='' />
                                                    {Object.values(point)[0]}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    {/* <div className={styles.add_ons_item}>
                                    <div className='pt-3 d-flex align-items-center justify-content-between w-100'>
                                        <label> Add ons</label>
                                        <span> ₹500.00</span>
                                    </div>
                                    <div className='pt-3 pb-3'>
                                        <span className='d-flex gap-3 pt-1 pb-1'>
                                            <img src={GreenTick} alt='' />
                                            Interior Design
                                        </span>
                                        <span className='d-flex gap-3 pt-1 pb-1'>
                                            <img src={GreenTick} alt='' />
                                            3D Elevation
                                        </span>
                                    </div>
                                </div> */}
                                    <div className={styles.promo}>
                                        <input type='text' placeholder='Enter promo code' />
                                    </div>
                                    <div className={styles.total_div}>
                                        <div className='pt-3 d-flex align-items-center justify-content-between w-100'>
                                            <label>Total</label>
                                            <span>₹{parsedPackage?.package_pricing}</span>
                                        </div>
                                        <div className='pt-3 pb-3 d-flex align-items-center justify-content-between w-100'>
                                            <span>Total delivery time</span>
                                            <span>7 days</span>
                                        </div>
                                        {isLoggedIn && activeStep !== Registertion  && (
                                            <button type='button pt-3'>Confirm & Pay</button>

                                        )}
                                        <div className={styles.btn_demo}>
                                            <label>Or Book Free Demo today</label>
                                            <span>
                                                You will be charged ₹{parsedPackage?.package_pricing}. Total amount<br />
                                                includes currency conversion fees.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {/* Package box design for Servie Packages  */}
                            {parsedPackage?.with_package ? (
                                <div className={styles.right_main}>
                                    <div className={styles.plan}>
                                        <div className='d-flex align-items-center justify-content-between w-100 border-bottom'>
                                            <h5>{parsedPackage?.package_types}</h5>
                                            <div className={styles.small_text}>Change Plan</div>
                                        </div>
                                        <div className='pt-3 d-flex align-items-center justify-content-between w-100'>
                                            <label>{parsedPackage?.package_name}</label>
                                            <span>₹{parsedPackage?.package_pricing}</span>
                                        </div>
                                        <div className='pt-3 pb-3'>
                                            {Object.keys(JSON.parse(parsedPackage.with_package))?.map((item, itemIndex) => (
                                                <span className='d-flex gap-3 pt-1 pb-1'>
                                                    <img src={GreenTick} alt='' />
                                                    {item}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    {/* <div className={styles.add_ons_item}>
                                        <div className='pt-3 d-flex align-items-center justify-content-between w-100'>
                                            <label> Add ons</label>
                                            <span> ₹500.00</span>
                                        </div>
                                        <div className='pt-3 pb-3'>
                                            <span className='d-flex gap-3 pt-1 pb-1'>
                                                <img src={GreenTick} alt='' />
                                                Interior Design
                                            </span>
                                            <span className='d-flex gap-3 pt-1 pb-1'>
                                                <img src={GreenTick} alt='' />
                                                3D Elevation
                                            </span>
                                        </div>
                                    </div> */}
                                    <div className={styles.promo}>
                                        <input type='text' placeholder='Enter promo code' />
                                    </div>
                                    <div className={styles.total_div}>
                                        <div className='pt-3 d-flex align-items-center justify-content-between w-100'>
                                            <label>Total</label>
                                            <span>₹{parsedPackage?.package_pricing}</span>
                                        </div>
                                        <div className='pt-3 pb-3 d-flex align-items-center justify-content-between w-100'>
                                            <span>Total delivery time</span>
                                            <span>7 days</span>
                                        </div>
                                        {isLoggedIn && activeStep !== Registertion  && (
                                            <button type='button pt-3'>Confirm & Pay</button>

                                        )}
                                        <div className={styles.btn_demo}>
                                            <label>Or Book Free Demo today</label>
                                            <span>
                                                You will be charged ₹{parsedPackage?.package_pricing} . Total amount<br />
                                                includes currency conversion fees.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : null}


                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}
