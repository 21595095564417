import React from 'react'
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import RedTick from '../../../assets/images/svg/tick_red.svg'
import BlackCross from '../../../assets/images/svg/blackcross.svg'
import styles from './description.module.scss';
import { Link } from 'react-router-dom';
export const  ComparePackages = ({ listDespcription }) => {
    useEffect(() => {
        console.log(listDespcription, 'afsdddddddddddddddddddddddddddddddddddd');
    }, [listDespcription]);
    const renderTableHeader = () => {
        if (!listDespcription?.package_data || listDespcription.package_data.length === 0) {
          return null;
        }
    
        const header = ['Package', ...listDespcription.package_data.map(item => item.package_types)];
    
        return header.map((key, index) => (
            
          <th key={index}>
            <label >{key}</label>
          </th>
        ));
      };
    
      const renderTableData = () => {
        if (!listDespcription?.package_data || listDespcription.package_data.length === 0) {
          return null;
        }
      
        const categories = Object.keys(JSON.parse(listDespcription.package_data[0].with_package));
      
        return categories.map((category, categoryIndex) => (
          <tr key={categoryIndex}>
            <td><label>{category}</label></td>
            {listDespcription.package_data.map((packageItem, packageIndex) => (
              <td key={packageIndex} className='text-center'>
                {JSON.parse(packageItem.with_package)[category] ? (
                  <img src={RedTick} alt='' />
                ) : (
                  <img src={BlackCross} alt='' />
                )}
              </td>
            ))}
          </tr>
        ));

        
      };
      
      
    
      return (
        <Container>
          <h4 className='pb-0'>Compare packages</h4>
          <p className='mb-5'>These are the packages to provide this project according to you.</p>
          <div className={styles.compare_packages}>
            <table className="table table-striped">
              <tbody>
                <tr>{renderTableHeader()}</tr>
                {renderTableData()}
                <tr>
                  <td></td>
                  {listDespcription.package_data.map((packageItem, index) => (
                    <td key={index} className='text-center'>
                      <div className='d-flex flex-column'>
                        <span>{`₹${packageItem.package_pricing}`}</span>
                        <Link to={`/payment?package=${JSON.stringify(packageItem)}`}>
                          <button type='button' className={styles.select_btn}>Select</button>
                            </Link>
                            <Link to={`/your-design`}>
                        <button type='button' className={styles.modify_btn}>Modify</button>
                        </Link>
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </Container>
      );
    };