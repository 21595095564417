import fetch from "./FetchInterceptor";
const SampleManagment = {};
SampleManagment.dawnloadFile = function (data) {
    return fetch({
      url: "/download-sample",
      method: "get",
      data: data,
    });
  };
  
  export default SampleManagment;