import React from 'react'
import img1 from '../../assets/images/skelton_img.jpg'
import { Link } from 'react-router-dom'

export const Image_card = () => {
    return (
        <>
            <div className="card" aria-hidden="true" style={{width:"30%"}}>
                <img src={img1} className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 className="card-title placeholder-glow">
                        <span className="placeholder col-6" />
                    </h5>
                    <p className="card-text placeholder-glow">
                        <span className="placeholder col-7" />
                        <span className="placeholder col-4" />
                        <span className="placeholder col-4" />
                        <span className="placeholder col-6" />
                        <span className="placeholder col-8" />
                    </p>
                    <Link
                        to="#"
                        tabIndex={-1}
                        className="btn btn-secondary disabled placeholder col-6"
                    />
                </div>
            </div>
        </>

    )
}
