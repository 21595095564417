import React from 'react'
import { Button, Container } from 'react-bootstrap'
import ThankYouImg from '../../../assets/images/thankyoumsg.jpg'
import styles from './thankyou.module.scss'
import { Link } from 'react-router-dom'
export const ThankYouMsg = () => {
  return (
    <div className={styles.main_container}>
      <Container className='d-flex' >
        <div className='col-md-7' >
          <img src={ThankYouImg} />
        </div>
        <div className='col-md-5'>
          <div className=''>
            <p className={styles.text_wrapper}>
              Thanks for your query. Our consultant will get in touch with you soon. if your requirement is urgent and you want to discuss now call us at +91-8010822233.</p>
          </div>
          <div className='d-flex'>
            <div className={styles.home_button_container}>
              <Link to='/'><Button>Home</Button></Link>
            </div>
            <div className={styles.contactUs_container}>
              <Link to='/help-center'><Button>Contact Us</Button></Link>
            </div>
          </div>
        </div>
      </Container>
    </div>

  )
}
