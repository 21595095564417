import { Container } from 'react-bootstrap'
import { ExportOtherServices } from '../../common/export-other-services'
import { HaveMoreQuestionBox } from '../../common/haveMoreQuestion'
import { TrendingDesign } from '../Home/TrendingDesign'
import { TryUsBox } from '../Home/TryUsBox'
import styles from './description.module.scss'
import { GetFreeConslt } from '../../common/conslt-btn-box'
import HomeIcon from '../../../assets/images/svg/home_icon.svg'
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Power from '../../../assets/images/svg/power.svg';
import { Link, useLocation } from 'react-router-dom'
import { IMAGE_BASE_URL } from "../../../config/constants/AppConfig";
import { Pricing } from './PricingTabs'
import { OverviewTabs } from './OverviewTabs'
import { useState } from 'react'
export const ServiceDescription = () => {
    const location = useLocation();
    const listDespcription = location?.state
    const photosData = JSON.parse(listDespcription?.photos)
    var date = new Date(listDespcription.created_at);
    let localDate = date.toString();
    const [key, setKey] = useState("Basic");

    return (
        <div className={styles.description_main}>
            <Container>
                <div className={styles.top_breadcum}>
                    <img src={HomeIcon} alt='' />
                    <span> &nbsp; /  &nbsp;</span> <label>Architecural Services &nbsp;</label>
                    <span>/  &nbsp;</span><label>House Floor Plans</label>
                </div>
                <div className={styles.service_detail}>
                    <div className='row'>
                        <div className={`${styles.left_section} col-lg-8 col-md-12`}>
                            <h1>{listDespcription.project_name}</h1>
                            <div className={styles.img_section}>
                                <Swiper
                                    modules={[Navigation]}
                                    // spaceBetween={12}
                                    slidesPerView={1}
                                    navigation
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    autoplay={{ delay: 3000 }}
                                >
                                    {photosData.map((photo) => {
                                        return (
                                            <SwiperSlide>
                                                <img src={`${IMAGE_BASE_URL}/${photo.name}`} alt='' height={'400px'} width={"100%"} />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                            <div className={styles.all_img}>
                                {photosData.map((photo, i) => {
                                    return (
                                        <img src={`${IMAGE_BASE_URL}/${photo.name}`} alt='' height={"72px"} width={"72px"} key={i} />
                                    )
                                })}
                            </div>
                            <div className={styles.about_property}>
                                <div className={styles.about}>
                                    <span className={styles.estimate_feet}>₹11.94 K/sq.ft</span>
                                    <span className={styles.price} >Avg. Price</span>
                                </div>
                                <div className={styles.about}>
                                    <span className={styles.estimate_feet}>Residential</span>
                                    <span>Building Category</span>
                                </div>
                                <div className={styles.about}>
                                    <span className={styles.estimate_feet}>Modern</span>
                                    <span>Style</span>
                                </div>
                                <div className={styles.about}>
                                    <span className={styles.estimate_feet}>South-East Facing</span>
                                    <span>Facing</span>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.right_section} col-lg-4 col-md-12`}>
                            <div className={styles.plan_box_main}>
                                <Pricing listDescription={listDespcription} />
                            </div>

                            <div className={styles.get_demo_div}>
                                <button type='button' className={styles.demo_btn}>
                                    <span>Get free Demo/ Modify</span>
                                </button>
                            </div>
                            <div className={styles.note}>
                                <div className='d-flex gap-3'>
                                    <img src={Power} alt='' />
                                    <div className='d-flex flex-column'>
                                        <span>Highly responsive!</span>
                                        <label>Known for exceptionally quick replies</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <OverviewTabs listDespcription={listDespcription} />
            <GetFreeConslt />
            <ExportOtherServices />
            <TrendingDesign title="Similar Designs" />
            <HaveMoreQuestionBox />
            <TryUsBox title1="Ready to try Us?" />
        </div>
    )
}
