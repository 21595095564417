import fetch from "./FetchInterceptor";
const PackageService = {};

PackageService.getActivePackage = function (data) {
  return fetch({
    url: "/get-active-package",
    method: "get",
    data: data,
  });
};

export default PackageService;
