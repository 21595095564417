import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Tick from "../../../assets/images/i.png"
import { ReactComponent as LeftArrow } from '../../../assets/images/svg/arrow_right_white.svg';
import Goal from "../../../assets/images/Goal.png";
import City from "../../../assets/images/city.png";
import Coin from "../../../assets/images/Coin_in_Hand.png";
import engineer from "../../../assets/images/engineer.png";
import CustomImg from "../../../assets/images/banner_custom.png";
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import ServiceCalendly from './CalendaryFormServices';
import styles from './banner.module.scss';
import VastuCalendly  from './CalendaryFormVastu';

export const MainBannner = ({ title1, title2, imageBox, pageLink,ServiceForm }) => {
    
    return (
        <div className={`${styles.main_banner} ${styles.spacing}`}>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className='listing'>
                            <div direction="horizontal" gap={1}>
                                <Link className="text-light fw-normal me-2" to="">Home <LeftArrow /></Link>
                                <Link className="text-light fw-normal me-2" to="">Architecture Service <LeftArrow /></Link>
                                <Link className="text-light fw-bold" to="">{pageLink}</Link>
                            </div>
                        </div>
                        <h1 className={`${styles.heading_text}`}>{title1} <br /> <span className={`${styles.filled_text}`}>{title2}</span></h1>
                        <p className={`${styles.para_text}`}>Certified Architect Designed House Plans</p>

                        <div className={`${styles.listing_box}`}>
                            <ul>
                                <li><img className='pe-2' src={Tick} alt="tick" />Creative, custom made designs</li>
                                <li><img className='pe-2' src={Tick} alt="tick" />Work directly with a designer</li>
                                <li><img className='pe-2' src={Tick} alt="tick" />Created by the best 5% of designers</li>
                                <li><img className='pe-2' src={Tick} alt="tick" />Receive full-copyright and ready to use files</li>
                                <li><img className='pe-2' src={Tick} alt="tick" />24x7 Architect Support </li>
                            </ul>
                        </div>

                        {imageBox ?
                            <>
                                <Container>
                                    <div className={styles.features}>

                                        <div className='d-flex'>
                                            <div className={`${styles.circle_box}`}>
                                                <img src={Goal} alt="goal" />
                                            </div>
                                            <div className='ps-2'>
                                                <p className='mb-0'>Projects</p>
                                                <span >5000+</span>
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className={`${styles.circle_box}`}>
                                                <img src={City} alt="city" />
                                            </div>
                                            <div className='ps-2'>
                                                <p className='mb-0'>Cities Covered</p>
                                                <span >800+</span>
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className={`${styles.circle_box}`}>
                                                <img src={Coin} alt="coin" />
                                            </div>
                                            <div className='ps-2'>
                                                <p className='mb-0'>Starting Fee</p>
                                                <span >₹2 per sqft</span>
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className={`${styles.circle_box}`}>
                                                <img src={engineer} alt="engineer" />
                                            </div>
                                            <div className='ps-2'>
                                                <p className='mb-0'>Gurantee</p>
                                                <span >100% money back gurantee</span>
                                            </div>
                                        </div>
                                        <button className={styles.startbtn}>Start Project</button>
                                    </div>

                                </Container>
                            </> :
                            <div className='main-description-box'>
                                <div className='d-flex w-100'>
                                    <div className='d-flex w-50'>
                                        <div className={`${styles.circle_box}`}>
                                            <img src={Goal} alt="goal" />
                                        </div>
                                        <div className='ps-2'>
                                            <p className='mb-0 text-light'>Projects</p>
                                            <span className='text-light'>5000+</span>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className={`${styles.circle_box}`}>
                                            <img src={City} alt="city" />
                                        </div>
                                        <div className='ps-2'>
                                            <p className='mb-0 text-light'>Cities Covered</p>
                                            <span className='text-light'>800+</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4 d-flex w-100'>
                                    <div className='d-flex w-50'>
                                        <div className={`${styles.circle_box}`}>
                                            <img src={Coin} alt="coin" />
                                        </div>
                                        <div className='ps-2'>
                                            <p className='mb-0 text-light'>Starting Fee</p>
                                            <span className='text-light'>₹2 per sqft</span>
                                        </div>
                                    </div>
                                    <div className='d-flex w-50'>
                                        <div className={`${styles.circle_box}`}>
                                            <img src={engineer} alt="engineer" />
                                        </div>
                                        <div className='ps-2'>
                                            <p className='mb-0 text-light'>Gurantee</p>
                                            <span className='text-light'>100% money back gurantee</span>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                    </Col>
                    {imageBox ? (
  <Col md={6} className='d-flex align-items-center justify-content-center'>
    <img src={CustomImg} alt='custom Img' />
  </Col>
) : (
  <Col md={6}>
    {ServiceForm ? <ServiceCalendly /> : <VastuCalendly />}
  </Col>
)}

                </Row>
            </Container>
        </div>
    )
}
