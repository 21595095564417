import React from 'react'
import styles from './help.module.scss'
import { Link } from "react-router-dom";
import contactUsImage from '../../../assets/images/cotactUS.png';
import LocationImage from '../../../assets/images/location.png';
import CallImage from '../../../assets/images/call.png';
import EmailImage from '../../../assets/images/mail.png'

export const HelpCentre = () => {
    return (
        <>
            <div className={styles.section_full_main_page}>
                <div className={styles.container}>
                    <div className="section-full  p-t30 p-b30 bg-no-repeat">
                        <div className="">
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="wrapper">
                                        <div className="row mb-5">
                                            <div className="col-md-4">
                                                <div className={`${styles.dbox} w-100 text-center`}>
                                                    <div className={`${styles.icon} d-flex align-items-center justify-content-center`}>
                                                        <span className="">
                                                            <img src={LocationImage} alt="" height="" width="30px" className={styles.Logo} />
                                                        </span>
                                                    </div>
                                                    <div className="text">
                                                        <p><span className={styles.textStyleIcon}>Address:</span><span className='text-danger'>H-44, First Floor, Sector-63, Noida</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className={`${styles.dbox} w-100 text-center`}>
                                                    <div className={`${styles.icon} d-flex align-items-center justify-content-center`}><span
                                                        className=""> <img src={CallImage} alt="" height="" width="30px" className={styles.Logo} /></span></div>
                                                    <div className="text">
                                                        <p><span className={styles.textStyleIcon}>Phone:</span>
                                                            <a className="text-danger" href="tel:+918010822233">+91 - 8010822233</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className={`${styles.dbox} w-100 text-center`}>
                                                    <div className={`${styles.icon} d-flex align-items-center justify-content-center`}><span
                                                        className="">
                                                        <img src={EmailImage} alt="" height="" width="30px" className={styles.Logo} /></span></div>
                                                    <div className="text">
                                                        <p><span className={styles.textStyleIcon}>Email:</span> <Link className="text-danger" to=""><span
                                                            className="text-danger"> support@planningHomes.com</span></Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content">
                                <div className="">
                                    <div className="row justify-content-center">
                                        <div className="col-md-10">
                                            <div className="row align-items-center">
                                                <div className="col-lg-7 mb-5 mb-lg-0">
                                                    <h2 className={`${styles.heading_h2} mb-5`}>Post Your <span className="text-danger">Query</span></h2>
                                                    <form className={`${styles.border_right} mb-5`} method="post" id="contactForm"
                                                        name="contactForm">
                                                        <div className="row m-3">
                                                            <div className="col-md-12 form-group">
                                                                <input type="text"
                                                                    className={`${styles.formControl2} form-control`}
                                                                    name="fname"
                                                                    id="fname"
                                                                    placeholder="First name"
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="row m-3">
                                                            <div className="col-md-12 form-group">
                                                                <input type="email"
                                                                    className={`${styles.formControl2} form-control`}
                                                                    name="email"
                                                                    id="email"
                                                                    placeholder="Email"
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="row m-3">
                                                            <div className="col-md-12 form-group">
                                                                <input type="text"
                                                                    className={`${styles.formControl2} form-control`}
                                                                    name="number"
                                                                    id="number"
                                                                    placeholder="Number"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row m-3">
                                                            <div className="col-md-12 form-group"><textarea
                                                                className={`${styles.formControl2} form-control`} name="message" id="message"
                                                                cols="30" rows="5" placeholder="Write your message"></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="row m-3">
                                                            <div className="col-md-12">
                                                                <input disabled="" type="button"
                                                                    className={styles.ButtonDesignHelp}
                                                                    value="Submit"
                                                                /><span
                                                                    className="submitting"></span></div>
                                                        </div>
                                                    </form>
                                                    <div id="form-message-warning mt-4"></div>
                                                </div>
                                                <div className="col-lg-4 ml-auto">
                                                    <h3 className={`${styles.heading_h3} mb-4`}>Let's talk about everything.</h3>
                                                    <p>
                                                        <p className="text">Reach us if you any query regarding our services or about house
                                                            plans and architecture services you <span className="read-or-hide"><span
                                                                className="text-danger">...read more</span></span></p>
                                                    </p>
                                                    <img className={`${styles.contact_img}`} src={contactUsImage} alt="" height="auto" width="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" d-none section-content">
                                <div className="row">
                                    <div className="col-xl-5 col-lg-5 col-md-12 d-none">
                                        <div className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                                        >

                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12">
                                        <div className=" text-center">
                                            <h4 className="contact-page m-t0 sx-tilte fsm-14  mt-contact mb-4">REACH US IF YOU ANY QUERY
                                                REGARDING OUR SERVICES OR ABOUT HOUSE PLANS AND ARCHITECTURE SERVICES YOU NEED. WE
                                                ARE HERE TO HELP YOU OUT!! WE WILL BE OBLIGED TO LISTEN TO YOUR QUESTION AND ANSWER
                                                THEM SO THAT YOU CAN GET TO KNOW MORE ABOUT ARCHITECTURAL DESIGNING THAT WILL HELP
                                                YOU OUT TO MAKE A BETTER DECISION.</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={`${styles.belowFullSection} d-block`}>
                <div className="container">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-12 d-none">
                                <div className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                                ></div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className=" text-center">
                                    <p className="fs-16 m-t0 sx-tilte fsm-14  mt-3 mb-3 text-right-mobile">
                                        <div className="App">
                                            <div><p className={styles.descriptionText}>You might have questions regarding a house plan or you are planning to make your house/commercial project/farmhouse or anything, we will give a brief knowledge of house designing and how it can work out for you effectively, since our telephonic consultancy is free of cost there is no harm in contacting us for helping you out. You can freely ask us anything about our services will be glad to answer them. We are here to provide you best services we can therefore our consultant is just one step away. Give us a call, message or email to get professionally assisted.</p>
                                                <p className={styles.descriptionText}>Call us at +91- 8010822233</p>
                                                <p className={styles.descriptionText}>Mail Us at support@planningHomes.com</p>
                                                <p className={styles.descriptionText}>Or you even can fill our query forms provided on our website, we will try to respond to these query forms within 24 hours or sometimes within minutes.</p>

                                            </div>
                                        </div>
                                    </p>
                                    <div className="text-center mb-2">
                                        <Link to="/book-demo">
                                            <button className={styles.ButtonDesignHelp}>BookDemo</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}
