import React, { useState, useEffect, useMemo, } from 'react';
import { useNavigate } from "react-router-dom"
import axios from 'axios';
import './login.styles.scss';
import { postRequest } from '../../components/API_Requests/Api_Request';
import 'flag-icon-css/css/flag-icons.min.css';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the default CSS (if not already imported)
import { GetOtp } from './getOtp';
import { Register } from './register';

export const LoginRegister = () => {
  const otpVerified = localStorage.getItem('otpVerified')
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState('');
  const { REACT_APP_API_URL } = process.env;
  const [countryCode, setCountryCode] = useState('');
  const [countryFlag, setCountryFlag] = useState('');
  const url = useMemo(() => REACT_APP_API_URL + 'sendLoginOTP', []);
  const otpMatchUrl = useMemo(() => REACT_APP_API_URL + 'matchLoginOTP', [])
  const [otpSent, setOtpSent] = useState(false); // New state for tracking OTP sending
  const [otpValue, setOtpValue] = useState('');
  // Function to handle form submission

  // const [otpVerified, setOtpVerified] = useState(false);
  /**
   * getGeoInfo this function is used for fetching location according to Ip address.
   * @author Phil
   * @since 28-08-2023
   */
  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response?.data;
        setCountryCode(data?.country_calling_code); // Add '+' to the country code
        setCountryFlag(data?.country_code.toLowerCase()); // Set the flag based on country code
      })
      .catch((error) => {
        console.log(error);
      });
  };


  /**
   * sendOTP this function is used for sendOtp to mobileNumber
   * @author Phil
   * @since 28-08-2023
   */
  const sendOTP = async () => {
    if (!mobileNumber) {
      toast.error('Please enter your mobile number.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    let data = {
      mobile_no: mobileNumber,
      country_code: countryCode,
    };
    try {
      const response = await postRequest(url, data);
      if (response && response.data && response.data.status === 200) {
        toast.success('OTP sent successfully!');
        setOtpSent(true);
      } else {
        toast.error('Error sending OTP.');
      }
    } catch (error) {
      toast.error(error);
    }
  };

  /**
   * submitOtp this function is used for submitOtp
   * @author Phil
   * @since 29-08-2023
   */
  const submitOtp = async () => {
    if (!otpValue) {
      toast.error('Please enter a 6-digit OTP.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    const data = {
      mobile_no: mobileNumber,
      country_code: countryCode,
      otp: otpValue
    };
    try {
      const response = await postRequest(otpMatchUrl, data);
      console.log(response,'hertere');
      if (response && response.message.status === 200) {
        localStorage.setItem('accessToken', response.data.token);
        localStorage.setItem('id', response.data.id);
        if (response.data.email || response.data.last_name || response.data.first_name) {
          navigate('/profile');
          localStorage.setItem('otpVerified', false);
        } else {
          localStorage.setItem('otpVerified', true);
          navigate('/register');
        }
      } else {
        localStorage.setItem('otpVerified', false);
        toast.error('OTP does not match.');
      }
    } catch (error) {
      console.error('Error submitting OTP:', error);
      toast.error('An error occurred while submitting OTP.');
    }
  };
  

  /**
   * 
   * 
   */
  const resendOtp = async () => {
    let data = {
      mobile_no: mobileNumber,
      country_code: countryCode,
    };
    const response = await postRequest(url, data);
    if (response.data.status === 200) {
      toast.success('OTP sent successfully!');
      setOtpSent(true); // Update the state when OTP is successfully sent
    } else {
      toast.error('Error sending OTP.');
    }
  }
  useEffect(() => {
    getGeoInfo();
  }, []);

  return (
    <>
    <ToastContainer />
      {!otpVerified ? (
        <GetOtp
          otpSent={otpSent}
          setMobileNumber={setMobileNumber}
          setOtpValue={setOtpValue}
          otpValue={otpValue}
          submitOtp={submitOtp}
          mobileNumber={mobileNumber}
          resendOtp={resendOtp}
          sendOTP={sendOTP}
          countryFlag={countryFlag}
          countryCode={countryCode}
        />
      ) : (
        <Register />
      )}
    </>
  );
};
