import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap'
import styles from './description.module.scss';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ArrowDown from '../../../assets/images/svg/arrow_down.svg'
import GreenTick from '../../../assets/images/svg/tick_green.svg'
import RedTick from '../../../assets/images/svg/tick_red.svg'
import Star from '../../../assets/images/svg/star.svg'
import Like from '../../../assets/images/svg/like.svg'
import Dislike from '../../../assets/images/svg/dislike.svg'
import Progress from '../../../assets/images/svg/progress.svg'
import Star_4 from '../../../assets/images/svg/4Star.svg'
import BlackCross from '../../../assets/images/svg/blackcross.svg'
import { ComparePackages } from './ComparePackages';

export const OverviewTabs = ({ listDespcription }) => {
    const [activeTab, setActiveTab] = useState('OverviewId');
    useEffect(() => {
        console.log(listDespcription, 'afsdddddddddddddddddddddddddddddddddddd');
    }, [listDespcription]);
    const handleTabClick = (tabId) => {
        const element = document.getElementById(tabId);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            setActiveTab(tabId);
        }
    };
    var date = new Date(listDespcription.created_at);
    let localDate = date.toString();
    const aboutArr = [
        {
            id: 1,
            title: "Description",
            desp: "The 30ft X 60ft floor plan project involves designing the layout and floor plan for a residential or commercial space with an area of 30 feet in width and 60 feet in length. This project requires careful consideration of the available space and how it can be utilized to create a functional and aesthetically pleasing living or working environment."
        },
        {
            id: 2,
            title: "How can I help you, and what are this design unique features?",
            desp: "The design process for this project involves collaborating with the client to understand their specific requirements, such as the number of rooms, the flow between rooms, the placement of windows and doors, and the desired aesthetic style. The design team will then work to create a floor plan that incorporates these requirements, while also maximizing natural light, ventilation, and privacy."
        },
        {
            id: 3,
            title: "How can I help you, and what are the packages' unique features?",
            desp: "The goal of this project is to create a functional and comfortable living or working space that meets the client's needs and preferences. With careful planning and attention to detail, the design team can create a floor plan that maximizes space utilization, provides adequate storage, and creates a seamless flow between rooms."
        }
    ]
    return (
        <div className={styles.tabes}>
            <div className={styles.tabes_main}>
                <div className={styles.main_box}>
                    <Container className={styles.container}>
                        <ul className={`${styles.tabsOverview} mb-3 d-flex`}>
                            <li
                                className={activeTab === 'OverviewId' ? ` ${styles.activeTab}` : ''}
                                onClick={() => handleTabClick('OverviewId')}
                            >
                                Overview
                            </li>
                            <li
                                className={activeTab === 'aboutId' ? ` ${styles.activeTab}` : ''}
                                onClick={() => handleTabClick('aboutId')}
                            >
                                About
                            </li>
                            <li
                                className={activeTab === 'compareId' ? `${styles.activeTab}` : ''}
                                onClick={() => handleTabClick('compareId')}
                            >
                                Compare
                            </li>
                            <li
                                className={activeTab === 'customId' ? `${styles.activeTab}` : ''}
                                onClick={() => handleTabClick('customId')}
                            >
                                Custom
                            </li>
                            <li
                                className={activeTab === 'reviewId' ? `${styles.activeTab}` : ''}
                                onClick={() => handleTabClick('reviewId')}
                            >
                                Review
                            </li>
                        </ul>
                    </Container>
                </div>
                <Container className={styles.TabContainer}>
                    <div id='OverviewId' className={`${styles.tabes_details} mt-5`}>
                        <Container>
                            <h4>Overview</h4>
                            <div className={styles.overView}>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className={styles.overView_detail}>
                                            <div className='d-flex align-items-center'>
                                                <div className={styles.img_box}>
                                                    {/* <img src=''></img> */}
                                                </div>

                                            </div>
                                            <div className='d-flex flex-column align-items-start ms-4'>
                                                <span>Building Type</span>
                                                <label>{listDespcription?.project_building_type}</label>
                                            </div>

                                        </div>
                                        <div className={styles.overView_detail}>
                                            <div className='d-flex align-items-center'>
                                                <div className={styles.img_box}>
                                                    {/* <img src=''></img> */}
                                                </div>

                                            </div>
                                            <div className='d-flex flex-column align-items-start ms-4'>
                                                <span>Plot Size</span>
                                                <label>20 ft X 45 ft</label>
                                            </div>
                                        </div>
                                        <div className={styles.overView_detail}>
                                            <div className='d-flex align-items-center'>
                                                <div className={styles.img_box}>
                                                    {/* <img src=''></img> */}
                                                </div>

                                            </div>
                                            <div className='d-flex flex-column align-items-start ms-4'>
                                                <span>Bedroom</span>
                                                <label>{listDespcription?.no_of_bedrooms}</label>
                                            </div>
                                        </div>
                                        <div className={styles.overView_detail}>
                                            <div className='d-flex align-items-center'>
                                                <div className={styles.img_box}>
                                                    {/* <img src=''></img> */}
                                                </div>

                                            </div>
                                            <div className='d-flex flex-column align-items-start ms-4'>
                                                <span>Stair Location</span>
                                                <label>{listDespcription?.stair_locations}</label>
                                            </div>

                                        </div>
                                        <div className={styles.overView_detail}>
                                            {/* <span>Balcony</span>
                                            <label>1</label> */}
                                        </div>

                                    </div>
                                    <div className='col-md-6'>
                                        <div className={styles.overView_detail}>
                                            <div className='d-flex align-items-center'>
                                                <div className={styles.img_box}>
                                                    {/* <img src=''></img> */}
                                                </div>

                                            </div>
                                            <div className='d-flex flex-column align-items-start ms-4'>
                                                <span>Estimated Construction Cost</span>
                                                <label>43.0 L want BOQ ?</label>
                                            </div>
                                        </div>
                                        <div className={styles.overView_detail}>
                                            <div className='d-flex align-items-center'>
                                                <div className={styles.img_box}>
                                                    {/* <img src=''></img> */}
                                                </div>

                                            </div>
                                            <div className='d-flex flex-column align-items-start ms-4'>
                                                <span>Vaastu Compliance</span>
                                                <label>{listDespcription?.vastu_compliances}</label>
                                            </div>
                                        </div>
                                        <div className={styles.overView_detail}>
                                            <div className='d-flex align-items-center'>
                                                <div className={styles.img_box}>
                                                    {/* <img src=''></img> */}
                                                </div>

                                            </div>
                                            <div className='d-flex flex-column align-items-start ms-4'>
                                                <span>Parking</span>
                                                <label>{listDespcription?.parking}</label>
                                            </div>
                                        </div>
                                        <div className={styles.overView_detail}>
                                            <div className='d-flex align-items-center'>
                                                <div className={styles.img_box}>
                                                    {/* <img src=''></img> */}
                                                </div>

                                            </div>
                                            <div className='d-flex flex-column align-items-start ms-4'>
                                                <span>Added</span>
                                                <label>{localDate}</label>
                                            </div>

                                        </div>
                                        <div className={styles.overView_detail}>
                                            {/* <span>Added</span>
                                            <label>{moment(localDate).startOf('min').fromNow()}</label> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div id='aboutId' className={styles.tabes_details}>
                        <Container>
                            <h4>About this Project</h4>
                            <div className={styles.about_property}>
                                {aboutArr.map((item) => {
                                    return (
                                        <div key={item.id}>
                                            <label>{item.title}</label>
                                            <p>{item.desp}</p>
                                        </div>
                                    )
                                })}
                                <div className='d-flex'>
                                    <label className='d-flex'>Ready? <p>Click the order button right now!</p></label>
                                </div>
                                <label>Don't hesitate to contact us before placing an order if you have a custom request or any questions</label>
                                <div className={styles.about_bottom}>
                                    <div className='row w-100'>
                                        <div className='col-md-12 d-flex'>
                                            <div className='col-md-4'>
                                                <div className='d-flex flex-column'>
                                                    <span className='text-start'>Platform Type</span>
                                                    <p>Autocad, Sketchup</p>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='d-flex flex-column'>
                                                    <span className='text-start'>Content type</span>
                                                    <p>Cad, Jpeg, Pdf</p>
                                                </div>
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>

                    <div id='compareId' className={styles.tabes_details}>
                        {/* <Container>
                            <h4 className='pb-0'>Compare packages</h4>
                            <p className='mb-5'>These are the packages to provide this project according to you.</p>
                            <div className={styles.campare_packages}>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <th><labe> Package</labe></th>
                                            <th className='bg-light'>
                                                <div className='d-flex flex-column'>
                                                    <span>₹8,612</span>
                                                    <p>Basic</p>
                                                    <small>Starter</small>
                                                    <a href="">Get Sample</a>
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex flex-column'>
                                                    <span>₹8,612</span>
                                                    <p>Standard</p>
                                                    <small>Starter</small>
                                                    <a href="">Get Sample</a>
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex flex-column'>
                                                    <span>₹8,612</span>
                                                    <p>Premium</p>
                                                    <small>Starter</small>
                                                    <a href="">Get Sample</a>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td><label>Floor Plans</label></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                        </tr>
                                        <tr>
                                            <td><label>Furniture Layout</label></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                        </tr>
                                        <tr>
                                            <td><label>3D Front Elevation</label></td>
                                            <td className='text-center'><img src={BlackCross} alt='' /></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                        </tr>
                                        <tr>
                                            <td><label>2D Front Elevation</label></td>
                                            <td className='text-center'><img src={BlackCross} alt='' /></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                        </tr>
                                        <tr>
                                            <td><label>Working Drawing</label></td>
                                            <td className='text-center'><img src={BlackCross} alt='' /></td>
                                            <td className='text-center'><img src={BlackCross} alt='' /></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                        </tr>
                                        <tr>
                                            <td><label>MEP Drawings</label></td>
                                            <td className='text-center'><img src={BlackCross} alt='' /></td>
                                            <td className='text-center'><img src={BlackCross} alt='' /></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                        </tr>
                                        <tr>
                                            <td><label>BOQ Estimate</label></td>
                                            <td className='text-center'><img src={BlackCross} alt='' /></td>
                                            <td className='text-center'><img src={BlackCross} alt='' /></td>
                                            <td className='text-center'><img src={RedTick} alt='' /></td>
                                        </tr>
                                        <tr>
                                            <td><label>Design Option</label></td>
                                            <td className='text-center'><span>1</span></td>
                                            <td className='text-center'><span>2</span></td>
                                            <td className='text-center'><span>Up-to Satisfaction</span></td>
                                        </tr>
                                        <tr>
                                            <td><label>Revisions</label></td>
                                            <td className='text-center'><span>4</span></td>
                                            <td className='text-center'><span>Unlimited</span></td>
                                            <td className='text-center'><span>Unlimited</span></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td className='text-center'>
                                                <div className='d-flex flex-column'>
                                                    <span>₹8,612</span>
                                                    <button type='button' className={styles.select_btn}>Select</button>
                                                    <button type='button' className={styles.modify_btn}>Modify</button>
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                <div className='d-flex flex-column'>
                                                    <span>₹8,612</span>
                                                    <button type='button' className={styles.select_btn}>Select</button>
                                                    <button type='button' className={styles.modify_btn}>Modify</button>
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                <div className='d-flex flex-column'>
                                                    <span>₹8,612</span>
                                                    <button type='button' className={styles.select_btn}>Select</button>
                                                    <button type='button' className={styles.modify_btn}>Modify</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Container> */}
                        <ComparePackages listDespcription={listDespcription}   />
                    </div>

                    <div id='customId' className={styles.tabes_details}>
                        <Container>
                            <h4 className='pb-0'>Want to Modify this project at same cost ?</h4>
                            <p className='pt-2 pb-5'>These are the steps we’ll follow to modify this project at same cost according to you.</p>
                            <div className={styles.work_flow}>
                                <span>Modify Project.</span>
                                <p>These are the steps we’ll follow to modify this project.</p>
                                <div className={styles.work_flow_stepper}>
                                    <ul className='d-inline-flex flex-column'>
                                        <li className='pb-5'>
                                            <div className={`${styles.count} position-absolute d-inline-flex align-items-center`}>
                                                <span>1</span>
                                            </div>
                                            <div className={styles.list_item}>
                                                <div className='d-flex gap-2'>
                                                    <label className='mt-0'>Preparations</label>
                                                    <img src={ArrowDown} alt='' />
                                                </div>
                                                <p>We'll do all preparations needed to start this project.</p>
                                                <div className={styles.step_label}>
                                                    <span>Onboarding Questionnaire · Requirements</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='pb-5'>
                                            <div className={`${styles.count} position-absolute d-inline-flex align-items-center`}>
                                                <span>2</span>
                                            </div>
                                            <div className={styles.list_item}>
                                                <div className='d-flex gap-2'>
                                                    <label className='mt-0'>Research</label>
                                                    <img src={ArrowDown} alt='' />
                                                </div>
                                                <p>We’ll do research based on your design needs.</p>
                                                <div className={styles.step_label}>
                                                    <span>Research · Design Reference · Work Calender</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='pb-5'>
                                            <div className={`${styles.count} position-absolute d-inline-flex align-items-center`}>
                                                <span>3</span>
                                            </div>
                                            <div className={styles.list_item}>
                                                <div className='d-flex gap-2'>
                                                    <label className='mt-0'>Design creation</label>
                                                    <img src={ArrowDown} alt='' />
                                                </div>
                                                <p>We’ll create content design on your requirements.</p>
                                                <div className={styles.step_label}>
                                                    <span>First draft · Modifications · Progress check</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className=''>
                                            <div className={`${styles.count} position-absolute d-inline-flex align-items-center`}>
                                                <span>4</span>
                                            </div>
                                            <div className={styles.list_item}>
                                                <div className='d-flex gap-2'>
                                                    <label className='mt-0'>Delivery</label>
                                                    <img src={ArrowDown} alt='' />
                                                </div>
                                                <p>We’ll send you the final delivery for review and feedback.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='d-flex mt-5'>
                                                <button type='button' className={styles.modify_btn}>Modify</button>
                                                <button type='button' className={styles.demo_btn}>Get Free Demo</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Container>
                    </div>

                    <div id='reviewId' className={styles.tabes_details}>
                        <Container>
                            <h4>Reviews</h4>
                            <div className={styles.reviews}>
                                <div className='d-flex gap-2 align-items-center'>
                                    <h5>165</h5>
                                    <h5>reviews for this Project</h5>
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <label>5</label>
                                </div>
                                <div className={`${styles.review_box} row w-100 `}>
                                    <div className='col-md-6'>
                                        <div className='d-flex align-items-center gap-2 pt-2 pb-2'>
                                            <span>5 Stars</span>
                                            <img src={Progress} alt='' />
                                            <p>(163)</p>
                                        </div>
                                        <div className='d-flex align-items-center gap-2 pt-2 pb-2'>
                                            <span>4 Stars</span>
                                            <img src={Star_4} alt='' />
                                            <p>(2)</p>
                                        </div>
                                        <div className='d-flex align-items-center gap-2 pt-2 pb-2'>
                                            <span>3 Stars</span>
                                            <label />
                                            <p>(0)</p>
                                        </div>
                                        <div className='d-flex align-items-center gap-2 pt-2 pb-2'>
                                            <span>2 Stars</span>
                                            <label />
                                            <p>(0)</p>
                                        </div>
                                        <div className='d-flex align-items-center gap-2 pt-2 pb-2'>
                                            <span>1 Stars</span>
                                            <label />
                                            <p>(0)</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <h5 className='pb-4'>Rating Breakdown</h5>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h6 className='mb-3'>Seller communication level</h6>
                                            <div className='d-flex gap-1 align-items-center'>
                                                <img src={Star} alt='' />
                                                <h3>5</h3>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h6 className='mb-3'>Recommend to a friend</h6>
                                            <div className='d-flex gap-1 align-items-center'>
                                                <img src={Star} alt='' />
                                                <h3>5</h3>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h6>Service as described</h6>
                                            <div className='d-flex gap-1 align-items-center'>
                                                <img src={Star} alt='' />
                                                <h3>5</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex gap-2 align-items-center pb-5 border-bottom w-100'>
                                    Sort By <h5>Most relevant</h5>
                                </div>

                                <div className=''></div>

                                <div className={styles.person_review}>
                                    <label>joshuachogan</label>
                                    <span className='ml-3 pt-1'>United States</span>
                                    <div className='d-flex gap-1 align-items-center'>
                                        <img src={Star} alt='' />
                                        <img src={Star} alt='' />
                                        <img src={Star} alt='' />
                                        <img src={Star} alt='' />
                                        <img src={Star} alt='' />
                                        | <span className='pb-0'>2 months ago</span>
                                    </div>
                                    <p className='pt-2'>Rexhinoganci92 is incredible at what he does! I am grateful for his hard work and<br />
                                        will continue to use his services. He listened and captured my vision making the<br />
                                        content relatable to my audience. He adjusted what i needed and got it right...<br />
                                        See more
                                    </p>
                                    <div className='d-flex gap-2 '>
                                        <small>Helpful?  <img className='ps-2' src={Like} alt='' /> Yes  <img className='ps-2' src={Dislike} alt='' /> No</small>
                                    </div>
                                </div>
                                <div className={styles.person_review}>
                                    <label>joshuachogan</label>
                                    <span className='ml-3 pt-1'>United States</span>
                                    <div className='d-flex gap-1 align-items-center'>
                                        <img src={Star} alt='' />
                                        <img src={Star} alt='' />
                                        <img src={Star} alt='' />
                                        <img src={Star} alt='' />
                                        <img src={Star} alt='' />
                                        | <span className='pb-0'>2 months ago</span>
                                    </div>
                                    <p className='pt-2'>Rexhinoganci92 is incredible at what he does! I am grateful for his hard work and<br />
                                        will continue to use his services. He listened and captured my vision making the<br />
                                        content relatable to my audience. He adjusted what i needed and got it right...<br />
                                        See more
                                    </p>
                                    <div className='d-flex gap-2'>
                                        <small>Helpful?  <img className='ps-2' src={Like} alt='' /> Yes  <img className='ps-2' src={Dislike} alt='' /> No</small>
                                    </div>
                                </div>
                                <button className={styles.seemore_btn} type='button'>+ See More</button>
                            </div>
                        </Container>
                    </div>
                </Container>
            </div>
        </div>
    )
}
