import React, { useEffect, useState } from 'react'
import styles from '../listing-page/listing.module.scss'
import { Button, Container } from 'react-bootstrap'
import { ReactComponent as MediaIcon } from "../../../assets/images/svg/media.svg"
import { ReactComponent as ArrowDown } from "../../../assets/images/svg/arrow_down.svg"
import Heart from '../../../assets/images/svg/heart.svg';
import { TryUsBox } from '../Home/TryUsBox'
import { HaveMoreQuestionBox } from '../../common/haveMoreQuestion'
import TrendingChoice from '../../../assets/images/svg/trending_choice.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GetFreeConslt } from '../../common/conslt-btn-box'
import { ExportOtherServices } from '../../common/export-other-services'
import { IMAGE_BASE_URL } from "../../../config/constants/AppConfig";
import { Image_card } from '../../skeleton-loading/Image_card'
import { onButtonClick } from '../../../utils/helper'
import userService from '../../../services/UserService'
import { RightSidebar } from '../../common/sidebar-listing-page'
export const InspirationPage  = () => {
    const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const [listingData, setListingData] = useState([])
    const [loading, setloading] = useState(true)
    const handleListing = async () => {
        let data = await userService.getlisting();
        if (data.status === 200) {
            setloading(false)
        }
        setListingData(data.data.data)
    }

    useEffect(() => {
        handleListing();
    }, []);
  return (
    <div className={styles.listing_main}>
    <Container>
        <div className='row'>
            <div className={`${styles.heading_text} col-lg-9 col-md-8 pe-md-4`}>
                <h1 className={styles.inner}> Discover Design Ideas and Inspiration</h1>
            </div>
            <div className={`${styles.top_right_div} col-lg-3 col-md-4 ps-md-5`}>
                <Button className={`${styles.sample_btn} w-100`} onClick={onButtonClick}>Get Sample</Button>
                <span className={styles.works}>
                    <span><MediaIcon /></span>
                    <span className={styles.works_text}> How Planningg Works</span>
                </span>
            </div>
        </div>
        {/* filters */}
        <div className='row mt-5'>
            <div className={` ${styles.filter} col-lg-9 col-md-8 pe-md-4`}>
                <div className={styles.service_Count_main}>
                    <span className={`${styles.service_Count}`}>1,234 Inspiration available</span>
                    <span className={`${styles.sort_by}`}>
                        <span>Sort by :</span>
                        <span className={`${styles.sort_by_text}`}>Best Selling</span>
                        <span><ArrowDown /></span>
                    </span>
                </div>
                {!loading ?
                    <div className={styles.img_cards}>
                        {listingData?.map((itm, i) => {
                            return (
                                <div className={`${styles.single_categ_slide}`}  key={itm.id}>
                                    <img src={`${IMAGE_BASE_URL}/${JSON.parse(itm?.photos)[0]?.name}`} alt='Home Design' width='100%' height={"200px"} />
                                    
                                        <div>
                                            <div className={styles.img_cards_div}>
                                                <div className={`${styles.rating_verified_box} d-flex justify-content-between px-3`}>
                                                    <h5>5.0 <span>(2)</span></h5>
                                                    <img src={TrendingChoice} alt="Your SVG" />
                                                </div>
                                                <h4>{itm.project_name}</h4>
                                                <p>Facing: {itm.plot_main_facing}</p>
                                                <p>Size: {itm.plot_size_width}ft X {itm.plot_size_length}ft</p>
                                            </div>
                                            <div className={`${styles.design_price_box} d-flex justify-content-between`}>
                                                <div className="">
                                                <p>{itm?.project_category_name}</p>
                                                </div>
                                                <img src={Heart} alt="Your SVG" />

                                            </div>
                                        </div>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className={styles.img_cards}>
                        {arr?.map((itm, i) => {
                            return (
                                <Image_card />
                            )
                        })}
                    </div>}
                <div className={styles.pagination}>
                    <nav aria-label="...">
                        <ul className="pagination pagination-lg">
                            <li className="page-item disabled">
                                <Link className="page-link" to='' tabindex="-1">1</Link>
                            </li>
                            <li className="page-item disabled">
                                <Link className="page-link" to='' tabindex="-1">2</Link>
                            </li>
                            <li className="page-item disabled">
                                <Link className="page-link" to='' tabindex="-1">3</Link>
                            </li>
                            <li className="page-item disabled">
                                <Link className="page-link" to='' tabindex="-1">4</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
          <RightSidebar /> 
        </div>
    </Container>
    <GetFreeConslt />
    <ExportOtherServices />
    <Container>
        {/* Have More Question Box */}
        <HaveMoreQuestionBox />
        {/* Try us Box  */}
        <TryUsBox title1="Ready to try Us?" />
    </Container>
</div>
  )
}
