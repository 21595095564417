import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Slider from "react-slick";
import user1 from "../../../assets/images/user-img1.png"
import user2 from "../../../assets/images/user-img2.png"
import user3 from "../../../assets/images/user-img3.png"
import star from "../../../assets/images/star-icon.png"
import styles from'./slider.module.scss'

function SliderBox() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
  return (
    <div className={`${styles.slider_main_box}`}>
      <Container>
         <div>
         <h2 className='text-center'> What client says</h2>
        <Slider {...settings}>
          <div className='slide1'>
              <div className='d-flex w-100'>
                  <Row>
                     <Col md={4}>
                        <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                 <img className='pe-3' src={user3} alt="user"/>
                              </div> 
                               <div className=''>
                                  <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p className='pt-3 pb-2'>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div>                      
                     </Col>
                     <Col md={4}>
                     <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                <img className='pe-3' src={user2} alt="user"/>
                              </div> 
                               <div className=''>
                                 <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p className='pt-3 pb-2'>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div> 
                     </Col>
                     <Col md={4}>
                     <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                <img className='pe-3' src={user2} alt="user"/>
                              </div> 
                               <div className=''>
                                 <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p className='pt-3 pb-2'>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div> 
                     </Col>
                  </Row>      
              </div>
          </div>
          <div className='slide2'>
          <div className='d-flex w-100'>
                  <Row>
                     <Col md={4}>
                        <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                <img className='pe-3' src={user3} alt="user"/>
                              </div> 
                               <div className=''>
                                 <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p className='pt-3 pb-2'>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div>                      
                     </Col>
                     <Col md={4}>
                     <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                <img className='pe-3' src={user2} alt="user"/>
                              </div> 
                               <div className=''>
                                 <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p className='pt-3 pb-2'>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div> 
                     </Col>
                     <Col md={4}>
                     <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                <img className='pe-3' src={user1} alt="user"/>
                              </div> 
                               <div className=''>
                                 <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p className='pt-3 pb-2'>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div> 
                     </Col>
                  </Row>      
              </div>
          </div>
          <div className='slide3'>
          <div className='d-flex w-100'>
                  <Row>
                     <Col md={4}>
                        <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                <img className='pe-3' src={user3} alt="user"/>
                              </div> 
                               <div className=''>
                                 <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p className='pt-3 pb-2'>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div>                      
                     </Col>
                     <Col md={4}>
                     <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                <img className='pe-3' src={user2} alt="user"/>
                              </div> 
                               <div className=''>
                                 <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p className='pt-3 pb-2'>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div> 
                     </Col>
                     <Col md={4}>
                     <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                <img className='pe-3' src={user1} alt="user"/>
                              </div> 
                               <div className=''>
                                 <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div> 
                     </Col>
                  </Row>      
              </div>
          </div>
          <div className='slide4'>
          <div className='d-flex w-100'>
                  <Row>
                     <Col md={4}>
                        <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                <img className='pe-3' src={user3} alt="user"/>
                              </div> 
                               <div className=''>
                                 <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p className='pt-3 pb-2'>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div>                      
                     </Col>
                     <Col md={4}>
                     <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                <img className='pe-3' src={user2} alt="user"/>
                              </div> 
                               <div className=''>
                                 <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p className='pt-3 pb-2'>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div> 
                     </Col>
                     <Col md={4}>
                     <div className={`${styles.card}`}>
                            <div className='d-flex align-items-center'>
                              <div className=''>
                                <img className='pe-3' src={user1} alt="user"/>
                              </div> 
                               <div className=''>
                                 <p className='fw-bold mb-0'>Jerome Bell</p>
                                  <p className='mb-0'>Google</p>
                               </div>   
                            </div> 
                            <p className='pt-3 pb-2'>“This guy is true professional and very experienced in migration and server configuration. He was able to complete my order in time and as per agreed scope. Highly recommend!”</p>   
                            <div className='d-flex'>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                              <img className='pe-2' src={star} alt="star"/>
                            </div>                         
                        </div> 
                     </Col>
                  </Row>      
              </div>
          </div>
        </Slider>
        </div>
      </Container>  
   </div>
  )
}

export default SliderBox