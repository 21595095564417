import { Route, Routes } from 'react-router-dom';
import { BookDemo } from '../views/book-demo';
import routes from '../../config/constants/routeConstant';
import { LoginRegister } from '../../auth/login';
import { Register } from '../../auth/login/register';
import { PublicLayout } from '../layout/Public';
import { HomeLayout } from '../layout/Home';
import { Home } from '../views/Home';
import { ProductListing } from '../views/listing-page';
import { ServiceDescription } from '../views/description-page';
import { UserProfile } from '../views/Profile'
import { Payment } from '../views/payment';
import { PrivateRoute } from '../layout/Home/privateRoute';
import { HelpCentre } from '../views/Help';
import { VaastuMain } from '../views/vaastu';
import { HowItWorks } from '../views/how-it-works';
import { CustomHouseDesign } from '../views/custom-house-design';
import { PricingComponent } from '../views/pricing';
import { PartnerNow } from '../views/parnter-now';
import { InspirationPage } from '../views/inspiration';
import { ThankYouMsg } from '../views/ThankYou-page';
export const Navigation = () => {
    return (
        <Routes>
            <Route
                path={routes.HomeRoute}
                element={
                    <HomeLayout>
                        <Home />
                    </HomeLayout>
                } />
            <Route
                path={routes.ListItemRoute}
                element={
                    <HomeLayout>
                        <ProductListing />
                    </HomeLayout>
                } />
                   <Route
                path={routes.InspirationRoute}
                element={
                    <HomeLayout>
                        <InspirationPage />
                    </HomeLayout>
                } />
                        <Route
                path={routes.ThankYouRoute}
                element={
                    <HomeLayout>
                        <ThankYouMsg />
                    </HomeLayout>
                } />
            <Route
                path={routes.DescriptionPageRoute}
                element={
                    <HomeLayout>
                        <ServiceDescription />
                    </HomeLayout>
                } />
            <Route
                path={routes.HelpCentreRoute}
                element={
                    <HomeLayout>
                        <HelpCentre />
                    </HomeLayout>
                } />
            <Route
                path={routes.PaymentRoute}
                element={
                    <HomeLayout>
                        <Payment />
                    </HomeLayout>
                } />
            <Route
                path={routes.BookDemoRoute}
                element={
                    <HomeLayout>
                        <BookDemo />
                    </HomeLayout>
                } />
            <Route
                path={routes.VasstuRoute}
                element={
                    <HomeLayout>
                        <VaastuMain />
                    </HomeLayout>
                } />
            <Route
                path={routes.HowItWorksRoute}
                element={
                    <HomeLayout>
                        <HowItWorks />
                    </HomeLayout>
                } />
            <Route
                path={routes.CustomDesignRoute}
                element={
                    <HomeLayout>
                        <CustomHouseDesign />
                    </HomeLayout>
                } />
            <Route
                path={routes.PartnerNowRoute}
                element={
                    <HomeLayout>
                        <PartnerNow />
                    </HomeLayout>
                } />
            <Route
                path={routes.PricingRoute}
                element={
                    <HomeLayout>
                        <PricingComponent />
                    </HomeLayout>
                } />
            <Route
                path={routes.ProfileRoute}
                element={
                    <PrivateRoute>
                        <UserProfile />
                    </PrivateRoute>
                } />
            <Route
                path={routes.loginRoute}
                element={
                    <PublicLayout>
                        <LoginRegister />
                    </PublicLayout>
                } />
            <Route
                path={routes.RegisterRoute}
                element={
                    <PublicLayout>
                        <Register />
                    </PublicLayout>
                } />
        </Routes>

    )
}
