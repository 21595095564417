import React from 'react';
export const NoOfTypes = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 'N/A', value: 'Other' },
]

export const stairLocation = [
    { name: "Inside", value: "Inside" },
    { name: "OutSide", value: "OutSide" },
]

export const facingDropdown = [
    { name: 'East', value: 'East' },
    { name: 'West', value: 'West' },
    { name: 'North', value: 'North' },
    { name: 'South', value: 'South' },
    { name: 'North-East', value: 'North-East' },
    { name: 'North-West', value: 'North-West' },
    { name: 'South-East', value: 'South-East' },
    { name: 'South-West', value: 'South-West' },
]

export const otherFacing = [
    { name: 'Corner', value: 'Corner' },
    { name: 'T Face', value: 'T Face' },
    { name: 'Three Side', value: 'Three Side' },
    { name: 'Other', value: 'Other' },
]

export const designType = [
    { name: 'Contemprary', value: 'Contemprary' },
    { name: 'Modern', value: 'Modern' },
    { name: 'Traditional', value: 'Traditional' },
    { name: 'Minimilistic', value: 'Minimilistic' },
    { name: 'Art Deco', value: 'Art Deco' },
    { name: 'Luxury', value: 'Luxury' },
    { name: 'Beach Style', value: 'Beach Style' },
    { name: 'Mid Century', value: 'Mid Century' },
    { name: 'Rustic', value: 'Rustic' },
    { name: 'Classic', value: 'Classic' },
    { name: 'Western ', value: 'Western' },
    { name: 'Indian', value: 'Indian' },
    { name: 'Other', value: 'Other' },
];

export const amenties = [
    { name: 'Dining Table', value: 'Dining Table', icon: '턒' },
    { name: 'Washing Machine', value: 'Washing Machine', icon: '톁' },
    { name: 'Sofa', value: 'Sofa', icon: '턝' },
    { name: 'Microwave', value: 'Microwave', icon: '턛' },
    { name: 'Stove', value: 'Stove', icon: '턞' },
    { name: 'Fridge', value: 'Fridge', icon: '턙' },
    { name: 'Water Purifier', value: 'Water Purifier', icon: '턓' },
];

export const preferredFor = [
    { name: 'Small Famliy', value: 'Small Famliy' },
    { name: 'Joint Family', value: 'Joint Family' },
    { name: 'Rented Premises', value: 'Rented Premises' },
    { name: 'Business Family', value: 'Business Family' },

];
export const plotArea = [
    { name: '1000 Sq.ft', value: '1000' },
    { name: '1000-2000 Sq.ft', value: '1000-2000' },
    { name: '2000-3000 Sq.ft', value: '2000-3000' },
    { name: '3000-4000 Sq.ft', value: '3000-4000' },
    { name: '4000 Sq.ft-above', value: '4000-above' },
]

export const MoreFilters = [
    {
        name: 'Design Type',value:'design_type', subOptions: [
            'Contemprary',
            'Modern',
            'Traditional',
            'Minimilistic',
            'Art Deco',
            'Luxury',
            'Beach Style',
            'Mid Century',
            'Rustic',
            'Classic',
            'Western',
            'Indian',
            'Other',
        ]
    },
    {
        name: "Plot Size",value:'plot_size', subOptions: [
            '1000',
            '1000-2000',
            '2000-3000',
            '3000-4000',
            '4000-above',
        ]
    },
    {
        name: "Number of Floor",value:'no_floors', subOptions: [
            1,
            2,
            3,
            4,
            5,
            'Other'
        ]
    },
    {
        name: "Unit Type",value:'flat_type', subOptions: [
            1,
            2,
            3,
            4,
            5,
            'Other'
        ]
    },
    {
        name: "Vaastu",value:'vastu_compliances', subOptions: [
            'Yes', 'No'
        ]
    },
    {
        name: "Room Type(interior)",value:'room_type', subOptions: [
            "Bedroom",
            "Kitchen",
            "Banquet",
            "Toilet",
            "Dining",
            "Drawing",
        ]
    },
    {
        name: "Direction",value:'direction', subOptions: [
            'East',
            'West',
            'North',
            'South',
            'North-East',
            'North-West',
            'South-East',
            'South-West',
        ]
    },
]
export const buildingTypes = [
    {
        name: 'Residential', value: 'residential',
        subOptions: [
            "Single Story",
            "Double Story",
            "Bunglow",
            "Kothi",
            "Commercial House",
            "Apartment House",
            "Multi Family House",
            "Small House",
            "Traditional House",
            "Kerela Hut Shaped House",
            "Modern House",
            "FarmHouse",
            "Other",
        ]
    },
    {
        name: 'Commercial', value: 'commercial',
        subOptions: [
            'Apartments',
            'Shoping Complex',
            'Corporate',
            'Hotels',
            'Hospitals',
            'Restaurant',
            'Showrooms',
            'Banquets Hall',
            'Gyms',
            'Rental',
            'Other',
        ]
    },
    {
        name: 'Industrial', value: 'industrial', subOptions: []
    },
    { name: 'Agriculture', value: 'agriculture', subOptions: [] },
    {
        name: 'institutional', value: 'institutional',
        subOptions: [
            'School',
            'Univerity',
            'ITI',
            'Play School',
            'Other',
        ]
    },
    { name: 'Government Buildings', value: 'Government Buildings', subOptions: [] },
    {
        name: 'Religious', value: 'religious',
        subOptions: [
            'religious', 'Temple', 'Gurudwara', 'Masjid', 'Church'
        ]
    },
    { name: 'Offices', value: 'offices', subOptions: [] },
    // Add more options with subOptions as needed
]


