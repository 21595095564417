import React from 'react'

export const PublicLayout = ({ children }) => {
    return (
        <>
            {/* {data ? children : <Navigate to='/Home' />} */}
            {children}
        </>
    )
}
