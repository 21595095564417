import { Col, Container, Row } from 'react-bootstrap'
import { HaveMoreQuestionBox } from '../../common/haveMoreQuestion'
import { PriceBox } from '../../common/price-box'
import { TryUsBox } from '../Home/TryUsBox'
import styles from './pricing.module.scss'
import TreeIcon from '../../../assets/images/svg/tree_plus_icon.svg'
import GreenStar from '../../../assets/images/svg/green_star.svg'
import OneHalf from '../../../assets/images/one-half.png.png'
import PackageService from '../../../services/PackageService'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const PricingComponent = () => {
  const [projectTypes, setProjectTypes] = useState([]);
  const [selectedPackageFor, setSelectedPackageFor] = useState('');
  const [activePackages, setActivePackages] = useState([]);

  const fetchAllProjectCategory = async () => {
    try {
      const response = await PackageService.getActivePackage();
      if (response.status === 200) {
        setProjectTypes(response.data);
        setSelectedPackageFor(response.data[0]?.packageFor || '');
      }
    } catch (error) {
      console.error('Error fetching project categories:', error);
    }
  };

  useEffect(() => {
    fetchAllProjectCategory();
  }, []);

  useEffect(() => {
    const selectedCategory = projectTypes.find((category) => category.packageFor === selectedPackageFor);
    const activePackagesData = selectedCategory ? selectedCategory.ActivePackages : [];
    setActivePackages(activePackagesData);
  }, [selectedPackageFor, projectTypes]);

  const handlePackageForChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedPackageFor(selectedValue);
  };
  const arrAddon = [
    { title1: "Structure Design", title2: "Starter, Growth, Pro, Enterprise" },
    { title1: "Electrical Layout", title2: "Growth, Pro, Enterprise" },
    { title1: "Plumbing Layout", title2: "Pro, Enterprise" },
    { title1: "3d Elevation", title2: "Pro, Enterprise" },
    { title1: "2d Floor Plan", title2: "Pro, Enterprise" },
  ]
  return (
    <div className={styles.pricing_main}>
      <div className={styles.top_bannner}>
        <Container>
          <div className='pt-4 pb-4'>
            <span>  Home » Packages</span>
            <Row>
              <Col md={7}>
                <h1 className='pt-3'>Planningg.homes Pricing</h1>
                <label className={`d-flex gap-2 pt-3 ${styles.Show_pricing}`}>
                  Show pricing for:
                  <select className={styles.banner_packagedropdown} value={selectedPackageFor} onChange={handlePackageForChange}>
                    {projectTypes.map((category) => (
                      <option key={category.packageFor} value={category.packageFor}>
                        {category.packageFor}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>
              <Col md={5} className='d-flex flex-column justify-content-center'>
                <h2 className='pt-3'>Average total star rating:</h2>
                <div className='d-flex gap-2'>
                  <img src={GreenStar} alt='' />
                  <img src={GreenStar} alt='' />
                  <img src={GreenStar} alt='' />
                  <img src={GreenStar} alt='' />
                  <img src={GreenStar} alt='' />
                </div>
              </Col>
            </Row>
            <div className={styles.items}>
              <div className='d-flex align-items-center'>
                <div className={styles.blank_box} />
                <div className='d-flex flex-column ms-3'>
                  <label>Free trial</label>
                  <span>No credit card required</span>
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <div className={styles.blank_box} />
                <div className='d-flex flex-column ms-3'>
                  <label>Flexible contracts</label>
                  <span>No lock-in and price hikes</span>
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <div className={styles.blank_box} />
                <div className='d-flex flex-column ms-3'>
                  <label>Minimal learning curve</label>
                  <span>50% faster implementation</span>
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <div className={styles.blank_box} />
                <div className='d-flex flex-column ms-3'>
                  <label>Intuitive Design</label>
                  <span>Better adoption, easier training</span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className={`mt-0 ${styles.price_box}`}>
        <PriceBox activePackages={activePackages} />
        <div className={styles.add_ons}>
          <Container>
            <h1 className='mb-5'>Get add-ons/single service that help you do more with Planningg.homes</h1>
            <Row>
              <Col md={5}>
                {arrAddon.map((itm, i) => {
                  return (
                    <div className={styles.addon_card} key={i}>
                      <button >ADD ON</button>
                      <span className='pt-2'>{itm.title1}</span>
                      <label className='pt-2'>{itm.title2}</label>
                    </div>
                  )
                })}
              </Col>
              <Col md={7}>
                <div className={styles.desgin_div}>
                  <div className={styles.div_1}>
                    <h1>  Structure Design</h1>
                  </div>
                  <div className={styles.div_2}>
                    <h1><sup>$</sup>15 <span>per Sq.ft.</span></h1>
                    <span>1000 Transactions per pack</span><br />
                    <span>Starter, Growth, Pro, Enterprise</span><br />
                    <span>Validity: End of Billing cycle</span><br />
                  </div>

                </div>
                <button className={styles.continue_btn}>Continue</button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles.applicable_taxesbox}>
          <Container>
            <Row>
              <Col md={12} >
                <div className={styles.inner_taxesbox}>
                  <p>All prices are exclusive of applicable taxes</p>
                  <p><b>24x 7 email support</b> and  <b>24 x 5 phone support</b>  included with all plans</p>
                  <p>Can't decide which plan is right for you?</p>
                  <a href="">Book a free consultation with us</a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles.price_dsep}>
          <Container>
            <Row>
              <Col md={7}>
                <div className='d-flex align-items-start'>
                  <img src={TreeIcon} alt='' />
                  <div className={styles.purchase_box}>
                    <h1 className='mb-3'>We Plant 1 Tree on your Purchase</h1>
                    <p className='mb-2'>Get extra support help for a product launch or other spike</p>
                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
                  </div>
                </div>
              </Col>
              <Col md={5}>
                <img className='img-fluid' src={OneHalf} alt='' />
              </Col>
            </Row>
          </Container>
        </div>
        <HaveMoreQuestionBox />
        <TryUsBox title1="Ready to try " title2='Floor Plan?' page='packages' />
      </div>
    </div>
  )
}
