import axios from "axios";
import { API_BASE_URL } from "../config/constants/AppConfig";
import { AUTH_TOKEN } from "../config/constants/AuthConstant";
import { ShowSuccessMessage, ShowErrorMessage, } from "../components/views/Notification";

const unauthorizedCode = [400, 401, 403];

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const TOKEN_PAYLOAD_KEY = "authorization";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    ShowErrorMessage("Error");
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);
    let notificationParam = {
      message: "",
    };

    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

    // Remove token and redirect
    // if (unauthorizedCode.includes(error.response.status)) {
    // 	notificationParam.message = 'Authentication Fail'
    // 	notificationParam.description = 'Please login again'
    // 	localStorage.removeItem(AUTH_TOKEN)

    // 	store.dispatch(signOutSuccess())
    // }

    if (error.response?.status === 401 && jwtToken) {
      // notificationParam.message = 'Invalid User'
      console.error("Invalid User");
      // Swal.fire({
      // 	title: 'Session expired',
      // 	html: 'Please login again.',
      // 	icon: 'warning',
      // 	confirmButtonText: 'Ok',
      // 	confirmButtonColor: "#ff6b72"
      //   })
      //   .then((result) => {
      // 	if (result.isConfirmed) {
      // 	  localStorage.removeItem(AUTH_TOKEN);
      // 	  store.dispatch(signOutSuccess())
      // 	}
      //   })
    }

    if (error.response?.status === 404) {
      //   notificationParam.message = "Not Found";
      ShowErrorMessage("Not Found");
      console.log("Not Found");
    }

    if (error.response?.status === 500) {
      notificationParam.message = "Internal Server Error";
    }

    if (error.response?.status === 508) {
      notificationParam.message = "Time Out";
    }

    if (error.response?.status !== 401) {
      //   notification.error(notificationParam);
      ShowErrorMessage(notificationParam.message);
    }

    return Promise.reject(error);
  }
);

export default service;
