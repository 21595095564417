import React, { Component } from 'react';

class ServiceCalendly extends Component {
  componentDidMount() {
    // Load the Calendly script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.head.appendChild(script);
  }

  componentWillUnmount() {
    // Clean up the script to prevent memory leaks
    // (Not needed in this case, but added for completeness)
    const script = document.querySelector('script[src="https://assets.calendly.com/assets/external/widget.js"]');
    if (script) {
      document.head.removeChild(script);
    }
  }

  render() {
    return (
      <div>
        {/* Render the Calendly inline widget */}
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/phil-k-7g4/book-a-free-call-from-planningg-experts"
          style={{ minWidth: '320px', height: '700px' }}
        />
      </div>
    );
  }
}

export default ServiceCalendly;

