import { Container } from "react-bootstrap"
import styles from './moreQuestion.module.scss'
import TeddyIcon from '../../../assets/images/svg/question_user.svg'
import Accordion from 'react-bootstrap/Accordion';
export const HaveMoreQuestionBox = () => {
    const questionsArr = [
        'Where are Planningg.homes designers located?',
        'How quick is Planningg.homes turnaround time?',
        'How many hours a month will my designer work?',
        'How many designs can I get in a month?',
        'What happens if I do not like my design?',
        'What design software do your designers use?',
        'How does Planningg.homes 30-day money back guarantee work?',
        'What does unlimited graphic design mean?',
        'What is the max amount of updates I can expect in one day?'
    ]
    return (
        <div className={styles.more_question_main}>
            <Container>
                <div className={`${styles.inner_secton_main} row`}>
                    <div className={`${styles.left_section} col-lg-5 col-md-6 `}>
                        <h1>Have more <br />questions?</h1>
                        <span className="d-flex gap-1">Chat with us <p>or</p> send us a message!</span>
                        <div className="mt-5">
                            <img src={TeddyIcon} alt="" />
                        </div>
                    </div>
                    <div className={`${styles.right_section} col-lg-7 col-md-6`}>
                    <Container>
          <div className={styles.faq_inn_box}>
            <Accordion className="faq_single_set" defaultActiveKey="0">
              <Accordion.Item className="faq_ques" eventKey="0">
                <Accordion.Header>Where are Planningg designers located?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="1">
                <Accordion.Header> How quick is Planningg turnaround time?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="2">
                <Accordion.Header>How many hours a month will my designer work? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="3">
                <Accordion.Header>How many designs can I get in a month? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="4">
                <Accordion.Header>How do revisions work? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="5">
                <Accordion.Header>What happens if I do not like my design? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="6">
                <Accordion.Header>What design software do your designers use? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="7">
                <Accordion.Header>How does Planningg 30-day money back guarantee work? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="8">
                <Accordion.Header>What does unlimited Architectural design mean? </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="faq_ques" eventKey="9">
                <Accordion.Header>What is the max amount of updates I can expect in one day?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Container>

                        
                    </div>
                </div>
            </Container>
        </div>
    )
}
