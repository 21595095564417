import React, { useState, useEffect, useMemo } from 'react';
import styles from './profile.module.scss';
import { getRequest } from '../../API_Requests/Api_Request';

export const ProfileForm = () => {
  const { REACT_APP_API_URL } = process.env;
  const getUserDataUrl = useMemo(() => `${REACT_APP_API_URL}client/get_user_details/`, [REACT_APP_API_URL]);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const userId = localStorage.getItem('id');
    const accessToken = localStorage.getItem('accessToken')
    if (userId) {
      const getData = async () => {
        try {
          const response = await getRequest(`${getUserDataUrl}${userId}`, accessToken);
          if (response.data.status === 200) {
            setUserData(response.message); // Update user data in state
          } else {
            console.error('Failed to fetch user data');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      getData();
    }
  }, [getUserDataUrl]);

  return (
    <div className="col-xl-9 col-lg-8 m-b30">
      <div className={`${styles.shop_bx} ${styles.shop_profile}`}>
        <div className={`${styles.shop_bx_title} ${styles.clearfix}`}>
          <h5 className="text-uppercase">Basic Information</h5>
        </div>
        <form>
          <div className="row m-b30">
            <div className="col-lg-6 col-md-6">
              <div className="mb-3">
                <label htmlhtmlFor="formcontrolinput1" className={styles.form_label}>
                  First Name :
                </label>
                <input
                  type="text"
                  className="form-control pro-fil-e11"
                  value={userData.first_name || ''} // Populate with user data
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="mb-3"><label htmlFor="formcontrolinput2" className={styles.form_label}>Last Name :</label>
                <input
                  type="text"
                  className="form-control pro-fil-e11"
                  value={userData.last_name || ''}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="mb-3"><label htmlFor="formcontrolinput2" className={styles.form_label}>Age:</label>
                <input
                  type="text"
                  className="form-control pro-fil-e11"
                  value={userData.last_name || ''}

                /></div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="mb-3"><label htmlFor="formcontrolinput2" className={styles.form_label}>Job Title:</label>
                <input
                  type="text"
                  className="form-control pro-fil-e11"
                  value={userData.job_title || ''}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.shop_bx_title} ${styles.clearfix}`}>
            <h5 className="text-uppercase">Contact Information</h5>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="mb-3"><label htmlFor="formcontrolinput15" className={styles.form_label}>Contact Number :</label>
                <input
                  type="number"
                  className="form-control pro-fil-e11"
                  value={userData.mobile_no || ''}

                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="mb-3"><label htmlFor="formcontrolinput16" className={styles.form_label}>Email Address :</label>
                <input
                  type="text"
                  className="form-control pro-fil-e11"
                  id="formcontrolinput16"
                  value={userData.email || ''}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="mb-3"><label htmlFor="formcontrolinput19" className={styles.form_label}>City :</label>
                <input
                  type="text"
                  className="form-control  pro-fil-e11"
                  id="formcontrolinput19"
                  value={userData.city || ''}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="mb-3"><label htmlFor="formcontrolinput110" className={styles.form_label}>Address :</label>
                <input
                  type="text"
                  className="form-control pro-fil-e11"
                  value={userData.address || ''} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="mb-3"><label htmlFor="formcontrolinput110" className={styles.form_label}>Country :</label>
                <input
                  type="text"
                  className="form-control pro-fil-e11"
                  value={userData.country || ''}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="mb-3"><label htmlFor="formcontrolinput18" className={styles.form_label}>Postcode :</label>
                <input
                  type="text"
                  className="form-control pro-fil-e11"
                  value={userData.post_code || ''}

                />

              </div>
            </div>
          </div><button type="submit" className={styles.updateProfileButton}>Update</button>
        </form>
      </div>
    </div>
  )

}