import styles from './parter.module.scss'
import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { ReactComponent as LeftArrow } from '../../../assets/images/svg/arrow_left.svg'
import partnerbanner from "../../../assets/images/partner-banner.png"
import partnerdesign1 from "../../../assets/images/partner-design1.png"
import partnerdesign2 from "../../../assets/images/partner-design2.png"
import partnerdesign3 from "../../../assets/images/partner-design3.png"
import partnerdesign4 from "../../../assets/images/partner-design4.png"
import circlebg from "../../../assets/images/circle-bg.png"
import setting from "../../../assets/images/setting.png"
import money from "../../../assets/images/money.png"
import peoplegroup from "../../../assets/images/peoplegroup.png"
import pricing from "../../../assets/images/pricing.png"
import settings from "../../../assets/images/settings.png"
import shipping from "../../../assets/images/shipping.png"
import userdetails from "../../../assets/images/user-details.png"
import usersignup from "../../../assets/images/user-signup.png"
import onboard from "../../../assets/images/onboard.png"
import memberbanner from "../../../assets/images/member-banner.png"
import redStar from "../../../assets/images/red-star.png"
import Carratarrow from "../../../assets/images/caratarrow.png"
import SliderBox from './slider';


export const PartnerNow = () => {
    return (
        <div >
            <div className={`${styles.main_banner} ${styles.spacing_main}`}>
                <Container>
                    <Row>
                        <Col lg={6} md={12}>
                            <div className='listing mb-4'>
                                <div direction="horizontal" gap={1}>
                                    <a className="text-light text-decoration-none fw-normal me-2" href="">Home</a>
                                    <img className={`${styles.arrow_img}`} src={Carratarrow} alt=""/>
                                    <a className="text-light text-decoration-none fw-normal ms-2 me-2" href="">Architecture Service</a>
                                    <img className={`${styles.arrow_img}`} src={Carratarrow} alt=""/>
                                    <a className="text-light text-decoration-none fw-bold me-2" href="">Partner with us</a>
                                </div>
                            </div>
                            <h1 className={`${styles.heading_text}`}>Do business with <br /><i className={`${styles.italic_text}`}>the best</i></h1>
                            {/* <p className={`${styles.para_text}`}>We make great design work happen with<br/> our global community of professional<br/> designers. If you'd like to speak to a human,<br/> talk to one of our design experts</p>*/}
                            <p className='text-light fw-normal mt-3'>Transform your pipeline and drive revenue with powerful <br /> design by top Architects in your industry<br /> and managed by Planningg.homes</p>
                            <button className={`${styles.become_partner_btn} fw-bold mt-4`}>Become a partner</button>
                        </Col>
                        <Col lg={6} md={12}>
                            <img className={`${styles.banner_main_img} ps-5 img-fluid`} src={partnerbanner} alt="work-banner" />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <div className={`${styles.main_circle_box}`}>
     <Container>		
	   <Row>
			<Col md={3}>
			  <div className='d-flex align-items-center w-100'>	
                <span className={`${styles.purple_circle}`}>1</span>
			     <p className='mb-0'>Create design projects</p>
			  </div> 
			</Col>
            <Col md={3}>
			   <div className='d-flex align-items-center w-100'>	
                 <span className={`${styles.blue_circle}`}>2</span>
				 <p className='mb-0'>Get instantly matched</p>
			</div>	
			</Col>
			<Col md={3}>
			  <div className='d-flex align-items-center w-100'>	
               <span className={`${styles.yellow_circle}`}>3</span>
			    <p className='mb-0'>Receive within 1-2 days</p>				
			</div>	
			</Col>
			<Col md={3}>
			  <div className='d-flex align-items-center w-100'>	
                <span className={`${styles.pink_circle}`}>4</span>
				<p className='mb-0'>Download & Repeat</p>
			  </div>	
			</Col>
		</Row>
	 </Container>
	 </div>
	 <div className={`${styles.create_design_box}`}>
        <Container>
			<Row>
				<Col md={6}>
				  <div className={`${styles.design_box}`}>	
                   <h3>Initial Consultations</h3>
				   <div className='mt-4 mb-5'>
				      <p>Start by filling out a simple form detailing your design needs. You can create multiple projects to form a queue.</p>
				   </div>	
				   <div className={`${styles.grey_box}`}>
                       <p className={`${styles.ques_text}`}>What type of designs can I request?</p>
				   </div>
				   <div className={`${styles.grey_box}`}>
                       <p className={`${styles.ques_text}`}>What type of designs can I request?</p>
				   </div>
				   <div className={`${styles.grey_box}`}>
                       <p className='mb-0'>What type of designs can I request?</p>
				   </div>
                  </div>
				</Col>
                <Col md={6}>
					<div className={''}>
						<div className={`${styles.text_banner1}`}>
				            <img className='pe-2' src={illustrationbanner} alt="img"/>
						</div>  
				    </div>	 
				</Col>
			</Row>
		</Container>
	 </div>
	 <div className={`${styles.ai_design_box} `}>
        <Container>
			<Row>
			<Col md={6}>
					<div className={''}>
						<div className={`${styles.text_banner2}`}>
				            <img className={`${styles.ai_banner}`} src={aibanner} alt="img"/>
						 </div>  
				    </div>	 
				</Col>
				<Col md={6} className='d-flex justify-content-end'>
				  <div className={`${styles.design_box}`}>	
                   <h3>A.I. assigns project to the best designer</h3>
				    <div className='mb-5 mt-4'>
						<p>Never worry about hiring the wrong designer again. Our artificial intelligence matches every project you create with the most compatible designer - every time.</p>
					</div>
				   <div className={`${styles.grey_box}`}>
                       <p className={`${styles.ques_text}`}>What type of designs can I request?</p>
				   </div>
				   <div className={`${styles.grey_box}`}>
                       <p className={`${styles.ques_text}`}>What type of designs can I request?</p>
				   </div>
				   <div className={`${styles.grey_box}`}>
                       <p className='mb-0'>What type of designs can I request?</p>
				   </div>
                  </div>
				</Col>               
			</Row>
		</Container>
	 </div>
	 <div className={`${styles.create_design_box}`}>
        <Container>
			<Row>
				<Col md={6}>
				  <div className={`${styles.design_box}`}>	
                   <h3>Simple & thoughtful revision tool</h3>
				   <div className='mt-4 mb-5'>
				      <p>Need to make some updates? Use our Point & Click tool to leave revisions directly on your design.</p>
			      </div>	  
				   <div className={`${styles.grey_box}`}>
                       <p className={`${styles.ques_text}`}>How long do revisions take?</p>
				   </div>
				   <div className={`${styles.grey_box}`}>
                       <p className={`${styles.ques_text}`}>What if I don’t like my designer?</p>
				   </div>
				   
                  </div>
				</Col>
                <Col md={6}>
					<div className={''}>
						<div className={`${styles.text_banner3}`}>
				            <img className={`${styles.thoughtfulrevision_img}`} src={thoughtfulrevision} alt="img"/>
						</div>  
				    </div>	 
				</Col>
			</Row>
		</Container>
	 </div>
	   <div className={`${styles.border_line_box}`}>
		 <hr></hr>
	   </div> */}
            <div className={`${styles.spacing} ${styles.hassel_banner}`}>
                <Container>
                    <h2 className='text-center'>We Take the Hassle Out of <i className={`${styles.italic_text} fw-bold`}>Designing a space</i></h2>
                    <p className={`${styles.bottom_heading} mb-5 text-center`}>Here’s how we do it</p>
                    <Row>
                        <Col md={6}>
                            <div className={`${styles.box_banner} w-100 d-flex`}>
                                <div className={`${styles.partner_deisgn_box1}`}>
                                    <img className='img-fluid' src={partnerdesign1} alt="partner-design"/>
                                </div>
                                <div className={`${styles.partner_deisgn_box2}`}>
                                    <p className='text-dark mb-0 fw-bold'>Vetted Architects</p>
                                    <p className={`${styles.box_small_text}`}>in 200+ industries.</p>
                                    <p className='text-dark mt-2'>We find, vet, and manage top architects in<br /> your industry so you don't have to.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={`${styles.box_banner} w-100 d-flex`}>
                                <div className={`${styles.partner_deisgn_box1}`}>
                                    <img className='img-fluid' src={partnerdesign2} alt="partner-design" />
                                </div>
                                <div className={`${styles.partner_deisgn_box2}`}>
                                    <p className='text-dark mb-0 fw-bold'>End-to-End Production</p>
                                    <p className={`${styles.box_small_text}`}>managed by Planningg.homes.</p>
                                    <p className='text-dark mt-2'>We manage your content plan from ideation to final approval so you have ready-to- publish designs.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col md={6}>
                            <div className={`${styles.box_banner} w-100 mt-4 d-flex`}>
                                <div className={`${styles.partner_deisgn_box1}`}>
                                    <img className='img-fluid' src={partnerdesign3} alt="partner-design" />
                                </div>
                                <div className={`${styles.partner_deisgn_box2}`}>
                                    <p className='text-dark mb-0 fw-bold'>Unlimited Scalability</p>
                                    <p className={`${styles.box_small_text}`}>to ramp up or down on the fly.</p>
                                    <p className='text-dark mt-2'>We’ve got the talent and processes to support your designs at scale.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={`${styles.box_banner} w-100 mt-4 d-flex`}>
                                <div className={`${styles.partner_deisgn_box1}`}>
                                    <img className='img-fluid' src={partnerdesign4} alt="partner-design" />
                                </div>
                                <div className={`${styles.partner_deisgn_box2}`}>
                                    <p className='text-dark mb-0 fw-bold'>A Dedicated Planningg Team</p>
                                    <p className={`${styles.box_small_text}`}>to support you along the way.</p>
                                    <p className='text-dark mt-2'>We bring expertise and process to become a trusted partner for your designing needs.</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='text-center mt-5 mb-5' md={12}>
                            <button className={`${styles.get_sample_btn}`}>Join Planningg.homes</button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={`${styles.spacing} ${styles.business_banner}`}>
                <Container>
                    <h2 className='text-center'>Join <i className={`${styles.italic_text}`}>Planningg</i> & Grow Your Business</h2>
                    <p className={`${styles.bottom_heading} mb-5 text-center`}>Boost your architectural business by opting for the Planningg.homes Partnership program.</p>
                    <Row className='mb-5'>
                        <Col className={`${styles.inner_box} text-center`} md={4}>
                            <div className={`${styles.inner_box_new} w-100 d-flex justify-content-center align-items-center`}>
                                <span className={`${styles.highlighted_text}`}>500+</span>
                                <img className={`${styles.circle_img} img-fluid`} src={circlebg} alt="circle" />
                            </div>
                            <p className={`${styles.box_small_text}`}>Partners already on board</p>
                        </Col>
                        <Col className='text-center' md={4}>
                            <div className={`${styles.inner_box_new} w-100 d-flex justify-content-center align-items-center`}>
                                <span className={`${styles.highlighted_text}`}>Rs20lakh</span>
                                <img className={`${styles.circle_img} img-fluid`} src={circlebg} alt="circle" />
                            </div>
                            <p className={`${styles.box_small_text}`}>Paid out to partners in 2021</p>
                        </Col>
                        <Col className='text-center' md={4}>
                            <div className={`${styles.inner_box_new} w-100 d-flex justify-content-center align-items-center`}>
                                <span className={`${styles.highlighted_text}`}>1000+</span>
                                <img className={`${styles.circle_img} img-fluid`} src={circlebg} alt="circle" />
                            </div>
                            <p className={`${styles.box_small_text}`}>Services delivered each month</p>
                        </Col>
                    </Row>
                    <div className={`${styles.center_main_box} d-flex justify-content-center`}>
                        <div className={`${styles.center_line}`}></div>
                    </div>

                </Container>
            </div>
            <div className={`${styles.spacing} ${styles.business_banner}`}>
                <Container>
                    <h2 className='text-center'>Are You an <i className={`${styles.italic_text}`}>Architect ?</i></h2>
                    <p className={`${styles.bottom_heading} mb-5 text-center`}>Join us and get customers, get support and earn more!</p>
                    <Row className='mb-5'>
                        <Col className={`${styles.inner_box} text-center`} md={4}>
                            <img className={`${styles.circle_img} img-fluid`} src={peoplegroup} alt="circle" />
                            <p className={`${styles.green_text}`}>Get Customers</p>
                            <p className={`${styles.box_small_text} mt-3`}>Get more customers at your <br />studio.</p>
                        </Col>
                        <Col className={`${styles.inner_box} text-center`} md={4}>
                            <img className={`${styles.circle_img} img-fluid`} src={setting} alt="circle" />
                            <p className={`${styles.green_text}`}>Get Support</p>
                            <p className={`${styles.box_small_text} mt-3`}>Get new designs and <br />operations support.</p>
                        </Col>
                        <Col className={`${styles.inner_box} text-center`} md={4}>
                            <img className={`${styles.circle_img} img-fluid`} src={money} alt="circle" />
                            <p className={`${styles.green_text}`}>Earn More</p>
                            <p className={`${styles.box_small_text} mt-3`}>Earn greater revenue and <br />profits.</p>
                        </Col>
                    </Row>
                    <div className={`${styles.center_main_box} d-flex justify-content-center`}>
                        <div className={`${styles.center_line}`}></div>
                    </div>

                </Container>
            </div>
            <div className={`${styles.spacing} ${styles.business_banner}`}>
                <Container>
                    <h2 className='text-center'>Exclusive Perks for <i className={`${styles.italic_text}`}>Planningg.home</i> Partners</h2>
                    <p className={`${styles.bottom_heading} mb-5 text-center`}>Signup today and enjoy these exclusive benefits.</p>
                    <Row className='mb-5'>
                        <Col className={`${styles.inner_box} text-center`} md={4}>
                            <img className={`${styles.circle_img} img-fluid`} src={pricing} alt="circle"/>
                            <p className={`${styles.green_text}`}>Competitive Pricing</p>
                            <p className={`${styles.box_small_text} mt-3`}>Get nationwide best design & <br />discount on new designs</p>
                        </Col>
                        <Col className={`${styles.inner_box} text-center`} md={4}>
                            <img className={`${styles.circle_img} img-fluid`} src={settings} alt="circle" />
                            <p className={`${styles.green_text}`}>Genuine Designs</p>
                            <p className={`${styles.box_small_text} mt-3`}>We use only genuine designs</p>
                        </Col>
                        <Col className={`${styles.inner_box} text-center`} md={4}>
                            <img className={`${styles.circle_img} img-fluid`} src={shipping} alt="circle" />
                            <p className={`${styles.green_text}`}>Easy Shipping</p>
                            <p className={`${styles.box_small_text} mt-3`}>Get last-mile shipping solutions</p>
                        </Col>
                    </Row>
                    <div className={`${styles.center_main_box} d-flex justify-content-center`}>
                        <div className={`${styles.center_line}`}></div>
                    </div>

                </Container>
            </div>
            <div className={`${styles.spacing} ${styles.business_banner}`}>
                <Container>
                    <h2 className='text-center'>Steps To Partner with <i className={`${styles.italic_text}`}>Planningg.homes</i></h2>
                    <p className={`${styles.bottom_heading} mb-5 text-center`}>Onboarding with Planningg.homes is a simple 3-step process.</p>
                    <Row className='mb-5'>
                        <Col className='d-block text-center' md={4}>
                            <img className={`${styles.circle_img} img-fluid`} src={userdetails} alt="circle" />
                            <p className='fw-bold mt-4 text-dark'>Step 1</p>
                            <p className={`${styles.box_small_text} mt-3`}>Enter your details and we will get back to <br />you to discuss your business needs</p>
                        </Col>
                        <Col className='text-center' md={4}>
                            <img className={`${styles.circle_img} img-fluid`} src={usersignup} alt="circle" />
                            <p className='fw-bold mt-4 text-dark'>Step 2</p>
                            <p className={`${styles.box_small_text} mt-3`}>Signup with Planningg.homes using the link <br />received after the conversation</p>
                        </Col>
                        <Col className='text-center' md={4}>
                            <img className={`${styles.circle_img} img-fluid`} src={onboard} alt="circle" />
                            <p className='fw-bold mt-4 text-dark'>Step 3</p>
                            <p className={`${styles.box_small_text} mt-3`}>Onboard Planningg.homes technology suite<br /> for hassle-free operations</p>
                        </Col>
                    </Row>
                    {/* <div className={`${styles.center_main_box} d-flex justify-content-center`}>
                        <div className={`${styles.center_line}`}></div>
                    </div> */}

                </Container>
            </div>
            <SliderBox />


            <div className={`${styles.member_banner}`}>
                <Container>
                    <Row>
                        <Col className='d-flex align-items-center' lg={6} md={12}>
                            <div className={`${styles.form_outer}`}>
                                <form>
                                    <div className='d-flex'>
                                        <Col className='mb-4 d-block' md={6}>
                                           <div><label>First Name <span>  <img className={`${styles.star_img} img-fluid`} src={redStar} alt="star" />	</span> </label></div>
                                           <div><input className={`${styles.input_box}`} placeholder='Name' type="text"></input></div>
                                        </Col>
                                        <Col md={6}>
                                            <div><label>Last Name <span>  <img className={`${styles.star_img} img-fluid`} src={redStar} alt="star" />	</span> </label></div>
                                            <input className='mb-4 w-100' placeholder='Name' type="text"></input>
                                        </Col>
                                    </div>
                                    <div className=''>
                                        <Col md={12}>
                                        <div> <label>Phone number <span>  <img className={`${styles.star_img} img-fluid`} src={redStar} alt="star" />	</span> </label></div>
                                        <div>  <input className='mb-4 w-100' type="text" placeholder='Enter Phone Number'></input></div>
                                        </Col>
                                        <Col md={12}>
                                           <div> <label>Email <span>  <img className={`${styles.star_img} img-fluid`} src={redStar} alt="star" />	</span> </label> </div>
                                           <div>  <input className='mb-4 w-100' type="text" placeholder='Enter Email'></input> </div>
                                        </Col>
                                        <Col>
                                           <div><label>City <span>  <img className={`${styles.star_img} img-fluid`} src={redStar} alt="star" />	</span> </label></div>
                                           <div> <input className='mb-4 w-100' type="text" placeholder='Enter City'></input> </div>
                                        </Col>
                                        <Col>
                                            <div className='d-flex align-items-center w-100'>
                                                <input className={`${styles.form_checkbox}`} type="checkbox"></input>
                                                <p className='ms-2 mb-0'>Accept the Terms and Privacy Policy</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <button className={`${styles.sign_up_btn} mt-4 w-100`}>Sign up</button>
                                        </Col>
                                    </div>
                                </form>
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <img className={`${styles.member_banner_img} img-fluid`} src={memberbanner} alt="member-banner"/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={`${styles.terms_box}`}>
                <Container>
                    <Row>
                        <div className=''>
                            <p className='mb-3'>*Terms and Conditions. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pharetra tortor non augue feugiat congue. Nullam et libero eu ipsum tempus iaculis sit amet at velit. Praesent viverra sodales odio, eu vehicula dolor placerat at. Donec tortor dolor, aliquet commodo tristique ut, porttitor vitae nibh. Duis accumsan eros id aliquam euismod. Quisque neque lectus, iaculis scelerisque cursus ac, consequat a dui. Nulla porttitor sem mi, a facilisis velit efficitur vel. Etiam eget lectus vehicula, ultrices lectus sagittis, volutpat dolor. Aliquam faucibus lacus quis nulla dignissim commodo. Nam convallis dui vel enim consequat, nec mattis ante eleifend. Vivamus auctor tellus et mauris rutrum ultrices eu non justo. Morbi mattis, tellus vitae feugiat egestas, dolor ligula dictum metus, sit amet accumsan ipsum lorem sed lorem. Aenean in tortor neque. Integer quis aliquam mi. Quisque eget suscipit magna, ut pellentesque dui. Maecenas bibendum neque ornare nibh posuere, nec gravida nisl sodales.</p>

                            <p className='mb-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pharetra tortor non augue feugiat congue. Nullam et libero eu ipsum tempus iaculis sit amet at velit. Praesent viverra sodales odio, eu vehicula dolor placerat at. Donec tortor dolor, aliquet commodo tristique ut, porttitor vitae nibh. Duis accumsan eros id aliquam euismod. Quisque neque lectus, iaculis scelerisque cursus ac, consequat a dui. Nulla porttitor sem mi, a facilisis velit efficitur vel. Etiam eget lectus vehicula, ultrices lectus sagittis, volutpat dolor. Aliquam faucibus lacus</p>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pharetra tortor non augue feugiat congue. Nullam et libero eu ipsum tempus iaculis sit amet at velit. Praesent viverra sodales odio, eu vehicula dolor placerat at. Donec tortor dolor, aliquet commodo tristique ut, porttitor vitae nibh. Duis accumsan eros id aliquam euismod. Quisque neque lectus, iaculis scelerisque cursus ac, consequat a dui. Nulla porttitor sem mi, a facilisis velit efficitur vel. Etiam eget lectus vehicula, ultrices lectus sagittis, volutpat dolor. Aliquam faucibus lacus</p>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
