import axios from 'axios';

/* get Request this functin is for getRequest for globally.
* @author Phil<Appwrk>
* @since 28-08-2023
*/
const getRequest = async (url, token = null) => {
  try {
    const headers = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await axios.get(url, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};


/* postRequest this functin is for postRequest for globally.
* @author Phil<Appwrk>
* @since 28-08-2023
*/
const postRequest = async (url, data, token = null) => {
  try {
    const headers = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await axios.post(url, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};
;

/* postFile this functin is for postFile for globally.
* @author Phil<Appwrk>
* @since 28-08-2023
*/
const postFile = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: data,
      redirect: 'follow',
    });
    return await response.json();
  } catch (error) {
    return error.response;
  }
};

export {getRequest, postRequest, postFile};
