import fetch from "./FetchInterceptor";
const homeService = {};

homeService.getArchitecularService = function (data) {
  return fetch({
    url: "/get-all-project-category",
    method: "get",
    data: data,
  });
};

export default homeService;
