import React from 'react'
import styles from './footer.module.scss'
import { Accordion, Container } from 'react-bootstrap'
import CommentBox from '../../../assets/images/svg/commentBox.svg'
import Fb from '../../../assets/images/svg/socialSVG/facebook.svg'
import Insta from '../../../assets/images/svg/socialSVG/insta.svg'
import Linkdin from '../../../assets/images/svg/socialSVG/linkdin.svg'
import { BrowserView, MobileView } from 'react-device-detect';
import Logo from '../../../assets/images/Logo-Planning.png'
import Facebook from '../../../assets/images/svg/socialSVG/facebook_fill.svg'
import Twiter from '../../../assets/images/svg/socialSVG/twitter.svg'
import Instagram from '../../../assets/images/svg/socialSVG/insta._fill.svg'
import Pintrst from '../../../assets/images/svg/socialSVG/pinterst.svg'
import LinkdinF from '../../../assets/images/svg/socialSVG/linkdin_fill.svg'
import Global from '../../../assets/images/svg/global.svg'
import INR from '../../../assets/images/svg/Inr.svg'
import User from '../../../assets/images/svg/user.svg'
import { Link } from 'react-router-dom';


export const Footer = () => {

  return (
    <>
      <BrowserView>
        <footer className={styles.footer_main}>
          <Container>
            <div className={styles.inner_box_main}>
              <div className={styles.infoDiv} >
                <div className={styles.commentBox}>
                  <img src={CommentBox} alt='' width='129px' height='72px' />
                  <span>© Planningg 2022</span>
                </div>
                <div className={styles.socialBtn}>
                  <div className={styles.btn1}>
                    <img src={Fb} alt='' />
                  </div>
                  <div className={styles.btn1}>
                    <img src={Insta} alt='' />
                  </div>
                  <div className={styles.btn1}>
                    <img src={Linkdin} alt='' />
                  </div>
                </div>
              </div>
              <div className={styles.itemList1}>
                <ul>
                  <li><h4>Solution</h4></li>
                  <li><span>Why Planningg?</span></li>
                  <li><span>How it works</span></li>
                  <li><span>Our work</span></li>
                  <li><span>Pricing</span></li>
                  <li><span>Reviews</span></li>
                  <li><span>Get a Demo</span></li>
                </ul>
              </div>
              <div className={styles.itemList2}>
                <ul>
                  <li><h4>Resources</h4></li>
                  <li><span>Partners</span></li>
                  <li><span>Affiliates</span></li>
                  <li><span>Learning center</span></li>
                  <li><span>Customer stories</span></li>
                  <li><span>Scope of service</span></li>
                </ul>
              </div>
              <div className={styles.itemList3}>
                <ul>
                  <li><h4>Company</h4></li>
                  <li><span>Our mission</span></li>
                  <li><span>Careers</span></li>
                  <li><span>Media kit</span></li>
                  <li><span>For good</span></li>
                  <li><span>Press</span></li>
                </ul>
              </div>
              <div className={`col-md-6 ${styles.itemList4}`}>
                <ul>
                  <li><h4>Pricing</h4></li>
                  <li><h4>Design Samples</h4></li>
                  <li><h4>Request Demo</h4></li>
                  <Link to='/help-center'><li><h4>Help Center</h4></li></Link>
                </ul>
              </div>
            </div>
          </Container>
        </footer>
      </BrowserView>
      <MobileView>
        {/* <!-- Footer --> */}
        <img className={styles.mobile_footer_logo_img}  src={Logo} width='181px' alt='' style={{ paddingLeft: "20px", paddingBottom: "18px" }} />
        <div className={styles.mobile_footer_main}>
          <div className={styles.mobile_inner_main}>
            <Accordion className="footer_single_set">
              <Accordion.Item className="footer_tabs" eventKey="0">
                <Accordion.Header>Solution</Accordion.Header>
                <Accordion.Body>
                <ul>
                  <li><span>Why Planningg?</span></li>
                  <li><span>How it works</span></li>
                  <li><span>Our work</span></li>
                  <li><span>Pricing</span></li>
                  <li><span>Reviews</span></li>
                  <li><span>Get a Demo</span></li>
                </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="footer_tabs" eventKey="1">
                <Accordion.Header>Resources</Accordion.Header>
                <Accordion.Body>
                <ul>
                  <li><span>Partners</span></li>
                  <li><span>Affiliates</span></li>
                  <li><span>Learning center</span></li>
                  <li><span>Customer stories</span></li>
                  <li><span>Scope of service</span></li>
                </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="footer_tabs" eventKey="2">
                <Accordion.Header>Company</Accordion.Header>
                <Accordion.Body>
                <ul>
                  <li><span>Our mission</span></li>
                  <li><span>Careers</span></li>
                  <li><span>Media kit</span></li>
                  <li><span>For good</span></li>
                  <li><span>Press</span></li>
                </ul>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item className="footer_tabs" eventKey="3">
                <Accordion.Header>More</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Accordion.Body>
              </Accordion.Item> */}
              <Accordion.Item className="footer_tabs" eventKey="4">
                <Accordion.Header>Pricing</Accordion.Header>
                <Accordion.Body>
                <ul>
                  <li>Design Samples</li>
                  <li>Request Demo</li>
                  <Link to='/help-center'><li>Help Center</li></Link>
                </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className={styles.footer_end} >
          <div className={styles.inner}>
            <div className={styles.socialBtnIcon}>
              <img src={Twiter} alt='' width='20px' height='20px' />
              <img src={Facebook} alt='' width='20px' height='20px' />
              <img src={LinkdinF} alt='' width='20px' height='20px' />
              <img src={Pintrst} alt='' width='20px' height='20px' />
              <img src={Instagram} alt='' width='20px' height='20px' />
            </div>
            <div className={styles.footer_inner_2}>
              <div className={styles.global}>
                <img src={Global} alt='' />
                <span>English</span>
              </div>
              <img src={INR} alt='' />
              <img src={User} alt='' />
            </div>
            <div className={styles.end}>
            © Planningg.Homes 2023
            </div>
          </div>
        </div>
      </MobileView>
    </>

  )
}
