import React, { useState } from "react";
import styles from './profile.module.scss';
import { ProfileForm } from './form';
import { SideBar } from './sideBar';
import { FavouriteDesign } from './FavouriteDesign';
import { TransactionHistory } from "./TransactionHistory";
import { OrderList } from "./OrderList";

export const UserProfile = () => {
    const [activeItem, setActiveItem] = useState("profile");
    return (

        <section className={`${styles.content_inner} bg-white`}>
            <div className="container">
                <div className="row">
                    <SideBar setActiveItem={setActiveItem} activeItem={activeItem} />
                    {activeItem === 'profile' ?
                        <ProfileForm />
                        :
                        activeItem === 'Transaction' ?
                            <TransactionHistory />
                            :
                            activeItem === 'OrderList' ?
                                <OrderList />
                                :
                                <FavouriteDesign />
                    }

                </div>
            </div>
        </section>

    )
}
