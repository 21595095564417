import React from 'react'
import { Button, Container, Modal } from 'react-bootstrap'
import styles from './playback.module.scss'
export const VideoPlayback = ({ showPlayBackModal, handleClose }) => {
    return (
        <Modal
            show={showPlayBackModal}
            onHide={handleClose}
            dialogClassName={styles.my_modal}
            contentClassName={styles.content_modal}
        >
            <Modal.Header className={styles.modal_header} closeButton>
                <Modal.Title>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.modal_body}>
                <video className={styles.video_box} controls id="video1">
                    <source src="" /> HTML5 video tag.
                    Your browser doesn't support

                </video>
            </Modal.Body>
            <Modal.Footer className='p-0'>

            </Modal.Footer>
        </Modal>
    )
}
