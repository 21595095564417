import styles from  '../Home/home.module.scss';
import Build1 from '../../../assets/images/building1.png';
import Build2 from '../../../assets/images/building2.png';
import Build3 from '../../../assets/images/building3.png';
import Build4 from '../../../assets/images/building4.png';
import Build5 from '../../../assets/images/building5.png';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import Container from 'react-bootstrap/Container';
import ProVerified from '../../../assets/images/svg/proverified.svg';
import Heart from '../../../assets/images/svg/heart.svg';


function SliderBox() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
  return (
    <div className={styles.trend_design_slides}>
    <div className={styles.cat_bgcol}></div>
    <Container>
      <div className={`${styles.categ_titles} d-flex gap-3 align-items-center `}>
        <h3>Trending Designs</h3>
      </div>
      <Swiper
        modules={[Navigation, Pagination]}
        // spaceBetween={25}
        // slidesPerView={4.5}
        navigation
        pagination={{ clickable: true }}
        onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper,'hererre')}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          // when window width is >= 640px
          320: {
            slidesPerView: 1.5,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 2.5,
          },
          1200: {
            slidesPerView: 4,
          },
        }}
        className="categories_slider"
      >
        <SwiperSlide>
          <div className={styles.single_categ_slide}>
            <img src={Build1} alt='' width='100%' />
            <div className={`${styles.rating_verified_box} d-flex justify-content-between px-3`}>
              <h5>5.0 <span>(2)</span></h5>
              <img src={ProVerified} alt="Your SVG" />
            </div>
            <h4>Complete Design of 1800 Sqft House</h4>
            <p>Facing: SOUTH</p>
            <p>Size: 22ft X 44ft</p>
            <div className={`${styles.design_price_box} d-flex justify-content-between`}>
              <img src={Heart} alt="Your SVG" />
              <div className="">
                <label>Starting at</label>
                <p>₹868,704 <span>31</span></p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.single_categ_slide}>
            <img src={Build2} alt='' width='100%' />
            <div className={`${styles.rating_verified_box} d-flex justify-content-between px-3`}>
              <h5>5.0 <span>(2)</span></h5>
              <img src={ProVerified} alt="Your SVG" />
            </div>
            <h4>Complete Design of 1800 Sqft House</h4>
            <p>Facing: SOUTH</p>
            <p>Size: 22ft X 44ft</p>
            <div className={`${styles.design_price_box} d-flex justify-content-between`}>
              <img src={Heart} alt="Your SVG" />
              <div className="">
                <label>Starting at</label>
                <p>₹868,704 <span>31</span></p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.single_categ_slide}>
            <img src={Build3} alt='' width='100%' />
            <div className={`${styles.rating_verified_box} d-flex justify-content-between px-3`}>
              <h5>5.0 <span>(2)</span></h5>
              <img src={ProVerified} alt="Your SVG" />
            </div>
            <h4>Complete Design of 1800 Sqft House</h4>
            <p>Facing: SOUTH</p>
            <p>Size: 22ft X 44ft</p>
            <div className={`${styles.design_price_box} d-flex justify-content-between`}>
              <img src={Heart} alt="Your SVG" />
              <div className="">
                <label>Starting at</label>
                <p>₹868,704 <span>31</span></p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.single_categ_slide}>
            <img src={Build4} alt='' width='100%' />
            <div className={`${styles.rating_verified_box} d-flex justify-content-between px-3`}>
              <h5>5.0 <span>(2)</span></h5>
              <img src={ProVerified} alt="Your SVG" />
            </div>
            <h4>Complete Design of 1800 Sqft House</h4>
            <p>Facing: SOUTH</p>
            <p>Size: 22ft X 44ft</p>
            <div className={`${styles.design_price_box} d-flex justify-content-between`}>
              <img src={Heart} alt="Your SVG" />
              <div className="">
                <label>Starting at</label>
                <p>₹868,704 <span>31</span></p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.single_categ_slide}>
            <img src={Build5} alt='' width='100%' />
            <div className={`${styles.rating_verified_box} d-flex justify-content-between px-3`}>
              <h5>5.0 <span>(2)</span></h5>
              <img src={ProVerified} alt="Your SVG" />
            </div>
            <h4>Complete Design of 1800 Sqft House</h4>
            <p>Facing: SOUTH</p>
            <p>Size: 22ft X 44ft</p>
            <div className={`${styles.design_price_box} d-flex justify-content-between`}>
              <img src={Heart} alt="Your SVG" />
              <div className="">
                <label>Starting at</label>
                <p>₹868,704 <span>31</span></p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </Container>
  </div>
  )
}

export default SliderBox