import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'; // Import the toast library
import styles from './profile.module.scss';
import ProfileLogo from '../../../assets/images/protstp.png';
import UserLogo from '../../../assets/images/user.png';
import FavouriteLogo from '../../../assets/images/heart.png';
import ReferFreindLogo from '../../../assets/images/id-card.png';
import HelpCentreLogo from '../../../assets/images/help-desk.png';
import NotificationLogo from '../../../assets/images/bell.png';
import LogoutLogo from '../../../assets/images/logout.png';
import ReferFriendModal from './ReferFriendModal';
import TransactionLogo from '../../../assets/images/transactionHistory.png';
import OrderListLogo from '../../../assets/images/orderList.png';

export const SideBar = ({ setActiveItem, activeItem }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const handleItemClick = (item) => {
        setActiveItem(item);
        if (item === "refer") {
            setIsModalOpen(true);
        }
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    // Function to handle logout
    const handleLogout = () => {
        // Clear local storage
        localStorage.clear();

        // Show a toast message
        toast.success("Logged out successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000, // Automatically close after 3 seconds
        });
        navigate('/login');
    }

    return (
        <div className="col-xl-3 col-lg-4 m-b30">
            <div className="">
                <div className="shop-account">
                    <div className={`${styles.account_detail} text-center`}>
                        <div className={styles.my_image}><Link to=''>
                            <img src={ProfileLogo} alt="" height="" width="123px" />
                        </Link></div>
                        <div className={styles.account_title}>
                            <div className="">
                                <h4 className={styles.m_b5}>Piyush kumar</h4>
                            </div>
                        </div>
                    </div>
                    <ul className={styles.account_list}>
                        <li>
                            <Link
                                aria-current="page"
                                className={activeItem === "profile" ? styles.active : ''}
                                to="/profile"
                                onClick={() => handleItemClick("profile")}
                            >
                                <img src={UserLogo} alt="" height="" width="20px" className={styles.Logo} />
                                <span>Profile</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={() => handleItemClick("favorite")}
                                className={activeItem === "favorite" ? styles.active : ''}
                            >
                                <img src={FavouriteLogo} alt="" height="" width="20px" className={styles.Logo} />
                                <span>Favorite Designs</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                aria-current="page"
                                className={activeItem === "refer" ? styles.active : ''}
                                onClick={() => handleItemClick("refer")}
                            >
                                <img src={ReferFreindLogo} alt="" height="" width="20px" className={styles.Logo} />
                                <span>Refer a Friend</span>
                            </Link>
                            {/* Pass the showModal and onClose props to the ReferFriendModal */}
                            <ReferFriendModal showModal={isModalOpen} onClose={handleCloseModal} />
                        </li>

                        <li>
                            <Link to="/help-center">
                                <img src={HelpCentreLogo} alt="" height="" width="20px" className={styles.Logo} />
                                <span>Help Center</span>
                            </Link>
                        </li>
                        <li>
                            <Link>
                                <img src={NotificationLogo} alt="" height="" width="20px" className={styles.Logo} />
                                <span>Consult Sales Team</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                aria-current="page"
                                className={activeItem === "transactionHistory" ? styles.active : ''}
                                onClick={() => handleItemClick("transactionHistory")}
                            >    <img src={TransactionLogo} alt="" height="" width="20px" className={styles.Logo} />
                                <span>Transaction History</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                aria-current="page"
                                className={activeItem === "orderListLogo" ? styles.active : ''}
                                onClick={() => handleItemClick("orderListLogo")}
                            ><img src={OrderListLogo} alt="" height="" width="20px" className={styles.Logo} />
                                <span>Order Listing</span>
                            </Link>
                        </li>
                        <div></div>
                        <li onClick={handleLogout}>
                            <Link>
                                <img src={LogoutLogo} alt="" height="" width="20px" className={styles.Logo} />
                                <span>LogOut</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
