import { Container } from 'react-bootstrap';
import styles from './home.module.scss';
import FooterCta from '../../../assets/images/footer-cta.png';
import { Link } from 'react-router-dom';
import { onButtonClick } from '../../../utils/helper';

export const TryUsBox = ({ title1, title2, page }) => {

  return (
    <div className={styles.try_us_box}>
      <Container>
        <div className={styles.try_us_bgbox}>
          <div className="row">
            <div className="col-lg-7 ps-md-5 d-flex flex-column justify-content-center pb-md-5 pb-0">
              {!title2 ?
                <h2>{title1}</h2> :
                <h2>{title1} <span>{title2}</span></h2>
              }
              <p>Create your project today and get your designs tomorrow. <br />It's that simple. Try Planingg today 30-days risk-free.</p>
              {page ?
                <div className={`${styles.try_us_buttons}`}>
                  <Link to='' className={`${styles.wire_btn} me-4`} onClick={onButtonClick}>Get a Sample</Link>
                  <Link to='/help-center'>Contact Us</Link>
                </div> :
                <div className={`${styles.try_us_buttons}`}>
                  <Link to='' className={`${styles.wire_btn} me-4`}>See Pricing</Link>
                  <Link to='/book-demo'>Get a Demo</Link>

                </div>
              }
            </div>
            <div className="col-lg-1 d-xl-block d-none"></div>
            <div className="col-lg-4 d-lg-block d-none">
              <img src={FooterCta} alt="" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
