import styles from './vasstu.module.scss'
import { MainBannner } from '../../common/banner'
import { HaveMoreQuestionBox } from '../../common/haveMoreQuestion'
import { ExportOtherServices } from '../../common/export-other-services'
import { TryUsBox } from '../Home/TryUsBox'
import { Col, Container, Row } from 'react-bootstrap'
import VastuGrup from '../../../assets/images/svg/vasstu_desp.svg'
import VastuStep from '../../../assets/images/svg/vastu_step.svg'
import ArrowDown from '../../../assets/images/svg/arrow_down_red.svg'
import BlueDot from '../../../assets/images/svg/blue-dot.svg'
import { Reviews } from '../../common/review_box'
import circle from '../../../assets/images/circle.png'
import { PriceBox } from '../../common/price-box'
import { useEffect } from 'react'

export const VaastuMain = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={styles.vasstu_main}>
      {/* main banner */}
      <MainBannner
        title1={'Get Consultation from'}
        title2={'Vaastu Experts'}
      />
      <div className={styles.vasstu_desp}>
        <Container>
          <div className='row'>
            <div className='col-md-7'>
              <h1>Vaastu Shastra Consultation</h1>
              <p>Vastu Shastra has been a significant and popular branch of science for a thousand years. It's because the vastu consultation specialists know specific regulations that improve the building's peaceful and harmonious environment. In addition, it is thought to improve one's health, happiness, and financial situation. Apart from that, design, layout, proportions, ground composition, organisation, and geometrical information are included in the discipline by Vastu consultants.
                Square Yards provides you with methods and guidelines through Vastu Consultation online that may make your infrastructure Vastu-compliant. </p>
            </div>
            <div className='col-md-5 d-flex align-items-center justify-content-center'>
              <img src={VastuGrup} alt='' />
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className={styles.vastu_couslt_step}>
          <h2 className='d-flex align-items-center justify-content-center mb-0 pt-5 pb-0 '>How Vaastu Consultation Works Through us ?</h2>
          <div className={`${styles.share_box}`}>

            <img className={`${styles.circle_img}`} src={circle} alt="circle-img" />
            <Row>
              <Col className='pe-0' md={6}>
                <div className={`${styles.consultation_box} ${styles.box_radius1}`}>
                  <div className='pe-5'>
                    <img className='img-fluid' src={VastuStep} alt="vastu-img" />
                  </div>
                  <div className='pe-5'>
                    <p className={styles.consultation_boxheading}>1. Share your requirement</p>
                    <p className={styles.consultation_boxtext}>Submit your basic detail and scedule a call back</p>
                  </div>
                </div>
              </Col>
              <Col className='ps-0' md={6}>
                <div className={`${styles.consultation_box} ${styles.box_radius2}`}>
                  <div className='pe-5'>
                    <img className='img-fluid' src={VastuStep} alt="vastu-img" />
                  </div>
                  <div className='pe-5'>
                    <p className={styles.consultation_boxheading}>2. Get end-to-end assistance</p>
                    <p className={styles.consultation_boxtext}>Our partner will reach out of you.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='pe-0' md={6}>
                <div className={`${styles.consultation_box} ${styles.box_radius3}`}>
                  <div className='pe-5'>
                    <img className='img-fluid' src={VastuStep} alt="vastu-img" />
                  </div>
                  <div className=''>
                    <p className={styles.consultation_boxheading}>3. Get free quotes</p>
                    <p className={styles.consultation_boxtext}>Get a detailed brief of vaastu consultation and packages </p>
                  </div>
                </div>
              </Col>
              <Col className='ps-0' md={6}>
                <div className={`${styles.consultation_box} ${styles.box_radius4}`}>
                  <div className='pe-5'>
                    <img className='img-fluid' src={VastuStep} alt="vastu-img" />
                  </div>
                  <div className='pe-5'>
                    <p className={styles.consultation_boxheading}>4. Book your move</p>
                    <p className={styles.consultation_boxtext}>Choose the package and get vaastu done.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className={styles.why_vastu}>
          <h2 className='d-flex align-items-center justify-content-center p-4 '>Why Vaastu Consultation is Crucial ?</h2>
          <Row className='d-flex justify-content-center'>
            <Col md={5}>
              <div className={styles.vastu_div}>
                <Row>
                  <Col md={3}>
                    <div className={styles.box} />
                  </Col>
                  <Col md={8} className='d-flex flex-column'>
                    <label className='pt-3'>Bringing in good fortune</label>
                    <p className='pt-3'>Outlaying properties accurately as per
                      the Vastu advice online can help in
                      bringing good fortune to the</p>
                  </Col>
                </Row>
              </div>
              <div className={styles.vastu_div}>
                <Row>
                  <Col md={3}>
                    <div className={styles.box} />
                  </Col>
                  <Col md={8} className='d-flex flex-column'>
                    <label className='pt-3'>Harmonising Energy</label>
                    <p className='pt-3'>Vastu brings harmony to natural elements and balances out the energies.</p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={5}>
              <div className={styles.vastu_div}>
                <Row>
                  <Col md={3}>
                    <div className={styles.box} />
                  </Col>
                  <Col md={8} className='d-flex flex-column'>
                    <label className='pt-3'>Spread Positivity</label>
                    <p className='pt-3'>OGetting Vastu consulting is essential for spreading positive energies throughout the space..</p>
                  </Col>
                </Row>
              </div>
              <div className={styles.vastu_div}>
                <Row>
                  <Col md={3}>
                    <div className={styles.box} />
                  </Col>
                  <Col md={8} className='d-flex flex-column'>
                    <label className='pt-3'>Healthy Relationship</label>
                    <p className='pt-3'>By decluttering the space, Vastu assists in achieving peace and healthy relationships with our loved</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.packages}>
          <div className='d-flex align-items-center justify-content-center p-4 flex-column '>
            <h2 >Consulting Packages & Approx charges</h2>
            <h5 className='pt-3'>Find the best home designing packages.*</h5>
            <h5 className='pt-3'>Currently showing for
              <label className='ms-1'>
                VAASTU PACK
                <img src={ArrowDown} alt='' className='ms-2' />
              </label>
            </h5>
          </div>
          <PriceBox />
        </div>
      </Container>
      <div className={styles.vastu_realty}>
        <Container>
          <h1 className='mb-4'>Select Vastu Consultation For All Realty</h1>
          <span>Moving to a new home or office is surely exciting. However, the process of packing & unpacking everything can get very exhausting.
            That's why you need professional packers & movers, so that you can enjoy your move without a worry.</span>
          <div className={styles.vastu_realty_div}>
            <Row>
              <Col md={6}>
                <div className='d-flex  gap-3 mt-4 align-items-start'>
                  <img src={BlueDot} alt='' />
                  <div className='d-flex flex-column'>
                    <label>Abhilash Sharma</label>
                    <span>Bring good luck and positivity to your flats by following the right Vastu principles. Make sure that they are always on the northeast and eastern side.</span>
                  </div>
                </div>
                <div className='d-flex  gap-3 mt-4 align-items-start'>
                  <img src={BlueDot} alt='' />
                  <div className='d-flex flex-column'>
                    <label>Abhilash Sharma</label>
                    <span>Bring good luck and positivity to your flats by following the right Vastu principles. Make sure that they are always on the northeast and eastern side.</span>
                  </div>
                </div>
                <div className='d-flex  gap-3 mt-4 align-items-start'>
                  <img src={BlueDot} alt='' />
                  <div className='d-flex flex-column'>
                    <label>Abhilash Sharma</label>
                    <span>Bring good luck and positivity to your flats by following the right Vastu principles. Make sure that they are always on the northeast and eastern side.</span>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className='d-flex  gap-3 mt-4 align-items-start'>
                  <img src={BlueDot} alt='' />
                  <div className='d-flex flex-column'>
                    <label>Abhilash Sharma</label>
                    <span>Bring good luck and positivity to your flats by following the right Vastu principles. Make sure that they are always on the northeast and eastern side.</span>
                  </div>
                </div>
                <div className='d-flex  gap-3 mt-4 align-items-start'>
                  <img src={BlueDot} alt='' />
                  <div className='d-flex flex-column'>
                    <label>Abhilash Sharma</label>
                    <span>Bring good luck and positivity to your flats by following the right Vastu principles. Make sure that they are always on the northeast and eastern side.</span>
                  </div>
                </div>
                <div className='d-flex  gap-3 mt-4 align-items-start'>
                  <img src={BlueDot} alt='' />
                  <div className='d-flex flex-column'>
                    <label>Abhilash Sharma</label>
                    <span>Bring good luck and positivity to your flats by following the right Vastu principles. Make sure that they are always on the northeast and eastern side.</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Reviews />
      <HaveMoreQuestionBox />
      <ExportOtherServices />
      <TryUsBox title1="Ready to try " title2='Floor Plan?' page='bookDemo' />
    </div>
  )
}
