import React, { useEffect, useState } from 'react'
import styles from './listing.module.scss'
import { Button, Container, Modal } from 'react-bootstrap'
import { ReactComponent as MediaIcon } from "../../../assets/images/svg/media.svg"
import { ReactComponent as ArrowDown } from "../../../assets/images/svg/arrow_down.svg"
import Build1 from '../../../assets/images/listing_img.png';
import Heart from '../../../assets/images/svg/heart.svg';
import { TryUsBox } from '../Home/TryUsBox'
import { HaveMoreQuestionBox } from '../../common/haveMoreQuestion'
import TrendingChoice from '../../../assets/images/svg/trending_choice.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GetFreeConslt } from '../../common/conslt-btn-box'
import { ExportOtherServices } from '../../common/export-other-services'
import Grey_circle from '../../../assets/images/svg/grey_circle.svg'
import userService from '../../../services/UserService'
import { IMAGE_BASE_URL } from "../../../config/constants/AppConfig";
import { Image_card } from '../../skeleton-loading/Image_card'
import { onButtonClick } from '../../../utils/helper'
import { buildingTypes, plotArea, preferredFor, NoOfTypes, MoreFilters, facingDropdown, amenties, designType } from './DropdownData';
import homeService from '../../../services/homeService';
import { RightSidebar } from '../../common/sidebar-listing-page'
import { VideoPlayback } from './VideoPlayback'
export const ProductListing = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const project_category = location?.state?.listingId
    const plotSize = location?.state?.plotSize
    const room_type = location?.state?.InterriorRoom
    const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const [listingData, setListingData] = useState([])
    const [loading, setloading] = useState(true)
    const [ProjectCategory, setProjectCategory] = useState([]);
    const [showMoreFiltersModal, setShowMoreFiltersModal] = useState(false);
    const [selectedFilterCount, setSelectedFilterCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showPlayBackModal, setShowPlayBackModal] = useState(false);
    const [activeSubmenu, setActiveSubmenu] = useState(null);
    const [length, setLength] = useState(plotSize?.plotLenght || '');  // Initialize with plotSize if available
    const [width, setWidth] = useState(plotSize?.plotBreath || '');   // Initialize with plotSize if available

    const handleMouseEnter = (filterName, index, mainDropdownValue) => {
        setSelectedFilters({
            ...selectedFilters,
            [filterName]: mainDropdownValue,
        });
        setActiveSubmenu(index);
    };
    const handleMouseEnterForMoreFilters = (index) => {
        setActiveSubmenu(index);

    };
    const handleMouseLeave = () => {
        setActiveSubmenu(null);
    };
    const [selectedFilters, setSelectedFilters] = useState({
        project_building_type: null,
        project_building_type_extended: null,
        project_category: null,
        plot_area: null,
        preferred_for: null,
        direction:null,
        vastu_compliances:null,
        flat_type:null,
        no_floors:null,
        plot_size:null,
        design_type:null,
    });
    const handleServiceDetail = (data) => {
        navigate("/service-description", { state: data })
    }
    const handleListing = async (page) => {
        console.log(selectedFilters,'herrererererererere');
        let data = await userService.getlisting(project_category, room_type, length, width, selectedFilters,page);
        if (data.status === 200) {
            setloading(false);
            setTotalPages(Math.ceil(data.data.total / data.data.per_page));
        }
        setListingData(data.data.data);
    }

    useEffect(() => {
        handleListing();
    }, [selectedFilters]);

    const handleDropdownChange = (filterName, selectedValue) => {
        console.log(filterName, 'seelected');
        setSelectedFilters({
            ...selectedFilters,
            [filterName]: selectedValue,
        });
    };
    const handleMoreFilterOnchange = (mainDropdown, selectedValue) => {
        // console.log('Main Dropdown:', mainDropdown.name);
        // console.log('Selected Submenu:', selectedValue);
        setSelectedFilters({
            ...selectedFilters,
            [mainDropdown.value]: selectedValue,
        });
    };

    const handleProjectCategory = async () => {
        let response = await homeService.getArchitecularService();
        console.log(response, 'herrererrre');
        if (response.status === 200) {
            setProjectCategory(response.data)
        }
    }

    useEffect(() => {
        handleProjectCategory();
    }, [])
    // const handleMoreFiltersApply = () => {
    //     handleListing();
    //     setShowMoreFiltersModal(false);
    //     let count = 0;
    //     if (moreFilters.bhk) count++;
    //     if (moreFilters.plot_main_facing) count++;
    //     if (moreFilters.other_animities) count++;
    //     if (moreFilters.project_design_type) count++;
    //     setSelectedFilterCount(count);

    // };

    // const handleMoreFiltersCancel = () => {
    //     // handleListing();
    //     setMoreFilters({
    //         bhk: '',
    //         plot_main_facing: '',
    //         other_animities: '',
    //         project_design_type: '',
    //     });
    //     let count = 0;
    //     setSelectedFilterCount(count);
    //     setShowMoreFiltersModal(false);
    // };

    const handleClose = () => {
        setShowPlayBackModal(false);
    }

    const handleShow = () => {
        setShowPlayBackModal(true);
    }

    return (
        <div className={styles.listing_main}>
            <Container>
                {location?.state?.type && <h1 className={styles.inner} > House Floor Plans</h1>}
                <div className='row'>
                    <div className={`${styles.heading_text} col-lg-9 col-md-8 pe-md-4`}>
                        {location?.state?.type ?
                            <span className={styles.text_content}>
                                Planningghomes.com has recently launched its new designing service of readymade house designs, this service enables you to get the pre-designed house maps, 3d elevations, and other technical drawings as per your preferences at a very affordable cost. We are providing a vast range of house designs such as duplex, simplex, triplex, and independent floors having sets of 2 bedrooms, 3 bedrooms, and much more. You will get all the popular house designs here having the best suitable configurations in India.
                            </span>
                            : <h1 className={styles.inner} > Discover Design Ideas and Inspiration</h1>}
                    </div>
                    <div className={`${styles.top_right_div} col-lg-3 col-md-4 ps-md-5`}>
                        <Button className={`${styles.sample_btn} w-100`} onClick={onButtonClick}>Get Sample</Button>
                        <span className={styles.works}>

                            <button className={`btn d-flex dropdown-toggle ${styles.select_filter}`} onClick={handleShow}> <span className='me-2'><MediaIcon /></span>
                                <span className={styles.works_text}> How Planningg Works</span></button>
                            <VideoPlayback showPlayBackModal={showPlayBackModal} handleClose={handleClose} />
                        </span>
                    </div>
                </div>
                {/* filters */}
                <div className='row mt-5'>
                    <div className={` ${styles.filter} col-lg-9 col-md-8 pe-md-4`}>
                        <div className='d-flex flex-wrap gap-4'>
                            <div className={`${styles.DrodpdownDesign} dropdown`}>
                                <button className={`btn  dropdown-toggle ${styles.select_filter}`} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    House Floor Plan
                                </button>
                                <div className={`${styles.DrodpdownMenuCustom} dropdown-menu`} aria-labelledby="dropdownMenuButton">
                                    {ProjectCategory?.map((project) => (
                                        <Link className={`${styles.DrodpdownMenuItem} dropdown-item`} value={project.id} to='' onClick={() => handleDropdownChange('project_category', project.id)}>{project.name}</Link>
                                    ))}
                                </div>
                            </div>
                            <div className={`${styles.DrodpdownDesign} dropdown`} onMouseLeave={handleMouseLeave}>
                                <button className={`btn  dropdown-toggle ${styles.select_filter}`} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Building Type
                                </button>
                                <div className={`${styles.DrodpdownMenuCustom} dropdown-menu`} aria-labelledby="dropdownMenuButton">
                                    {buildingTypes?.map((building, index) => (
                                        <div
                                            className={`${styles.DrodpdownMenuItem} dropdown-item`}
                                            onMouseEnter={() => handleMouseEnter('project_building_type', index, building.value)}
                                            value={building.value}
                                            key={building.value}
                                        >
                                            {building.name}
                                            {activeSubmenu === index && (
                                                <div className={styles.subMenu}>
                                                    {building.subOptions.map((subOption, subIndex) => (
                                                        <Link
                                                            key={subIndex}
                                                            to=""
                                                            onClick={() => handleDropdownChange('project_building_type_extended', subOption)}
                                                        >
                                                            {subOption}
                                                        </Link>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                            </div>
                            <div className={`${styles.DrodpdownDesign} dropdown`}>
                                <button className={`btn dropdown-toggle ${styles.select_filter}`} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Plot Area
                                </button>
                                <div className={`${styles.DrodpdownMenuCustom} dropdown-menu`} aria-labelledby="dropdownMenuButton">
                                    <div className={`${styles.plotBox} pt-2 ps-2 pe-2 w-100 mb-5 d-flex`}>
                                        <div className={`${styles.innerBox}`}>
                                            <label className='pb-2'>Length</label>
                                            <input
                                                className='w-100'
                                                placeholder='Max'
                                                type='text'
                                                value={length}
                                                onChange={(e) => setLength(e.target.value)}
                                            />
                                        </div>
                                        <div className={`${styles.innerBox}`}>
                                            <label className='pb-2'>Width</label>
                                            <input
                                                className='w-100'
                                                placeholder='Min'
                                                type='text'
                                                value={width}
                                                onChange={(e) => setWidth(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className='d-flex align-items-center pb-4 mt-5 w-100'>
                                        <div className='w-50'>
                                            <button className={`${styles.clearButton}`} onClick={() => { setLength(''); setWidth(''); }}>
                                                Clear All
                                            </button>
                                        </div>
                                        <div className='w-50 text-end'>
                                            <button className={`${styles.applyButton}`} onClick={() => handleListing(currentPage)}>
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className={`${styles.DrodpdownDesign} dropdown`}>
                                <button className={`btn  dropdown-toggle ${styles.select_filter}`} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Preferred For
                                </button>
                                <div className={`${styles.DrodpdownMenuCustom} dropdown-menu`} aria-labelledby="dropdownMenuButton">
                                    {preferredFor?.map((preferred) => (
                                        <Link className={`${styles.DrodpdownMenuItem} dropdown-item`} value={preferred.value} to='' onClick={() => handleDropdownChange('preferred_for', preferred.value)}>{preferred.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            {/* <div className={`${styles.DrodpdownDesign} dropdown`}>
                                <button className={`btn  dropdown-toggle ${styles.select_filter}`} onClick={() => setShowMoreFiltersModal(true)}>More Filters {selectedFilterCount > 0 ? ` (${selectedFilterCount})` : ''}</button>
                                <Modal show={showMoreFiltersModal} onHide={() => setShowMoreFiltersModal(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>More Filters</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <label className={styles.text_label}>BHK Type</label>
                                            <select
                                                className="form-control"
                                                value={moreFilters.bhk}
                                                onChange={(e) => setMoreFilters({ ...moreFilters, bhk: e.target.value })}
                                            >
                                                <option value="">BHK</option>
                                                {NoOfTypes?.map((Type) => (
                                                    <option value={Type?.value}>{Type?.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className={styles.text_label}>Face Type</label>
                                            <select
                                                className="form-control"
                                                value={moreFilters.plot_main_facing}
                                                onChange={(e) => setMoreFilters({ ...moreFilters, plot_main_facing: e.target.value })}
                                            >
                                                <option value="">Facing</option>
                                                {facingDropdown?.map((Type) => (
                                                    <option value={Type?.value}>{Type?.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className={styles.text_label}>Amenity Type</label>
                                            <select
                                                className="form-control"
                                                value={moreFilters.other_animities}
                                                onChange={(e) => setMoreFilters({ ...moreFilters, other_animities: e.target.value })}
                                            >
                                                <option value="">Amenity</option>
                                                {amenties?.map((Type) => (
                                                    <option value={Type?.value}>{Type?.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className={styles.text_label}>Design Type</label>
                                            <select
                                                className="form-control"
                                                value={moreFilters.project_design_type}
                                                onChange={(e) => setMoreFilters({ ...moreFilters, project_design_type: e.target.value })}
                                            >
                                                <option value="">Design</option>
                                                {designType?.map((Type) => (
                                                    <option value={Type?.value}>{Type?.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleMoreFiltersCancel}>
                                            Clear Filters
                                        </Button>
                                        <Button variant="primary" onClick={handleMoreFiltersApply}>
                                            Apply Filters
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div> */}
                            <div className={`${styles.DrodpdownDesign} dropdown`} onMouseLeave={handleMouseLeave}>
                                <button className={`btn  dropdown-toggle ${styles.select_filter}`} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    More Filters
                                </button>
                                <div className={`${styles.DrodpdownMenuCustom} dropdown-menu`} aria-labelledby="dropdownMenuButton">
                                    {MoreFilters?.map((filters, index) => (
                                        <div
                                            className={`${styles.DrodpdownMenuItem} dropdown-item`}
                                            onMouseEnter={() => handleMouseEnterForMoreFilters(index)}
                                            value={filters.name}
                                            key={filters.name}
                                        >
                                            {filters.name}
                                            {activeSubmenu === index && (
                                                <div className={styles.subMenu}>
                                                    {filters.subOptions.map((subOption, subIndex) => (
                                                        <Link
                                                            key={subIndex}
                                                            to=""
                                                            onClick={() => handleMoreFilterOnchange(filters, subOption)}
                                                        >
                                                            {subOption}
                                                        </Link>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                </div>
                            </div>

                        </div>
                        <div className={styles.service_Count_main}>
                            <span className={`${styles.service_Count}`}>{listingData?.length} Services available</span>
                            <span className={`${styles.sort_by}`}>
                                <span>Sort by :</span>
                                <span className={`${styles.sort_by_text}`}>Best Selling</span>
                                <span><ArrowDown /></span>
                            </span>
                        </div>
                        {!loading ?
                            <div className={styles.img_cards}>
                                {listingData?.map((itm, i) => {
                                    return (
                                        <div className={`${styles.single_categ_slide}`} onClick={() => handleServiceDetail(itm)} key={itm.id}>
                                            <img src={`${IMAGE_BASE_URL}/${JSON.parse(itm?.photos)[0]?.name}`} alt='Home Design' width='100%' height={"200px"} />
                                            <div>
                                                <div className={styles.img_cards_div}>
                                                    <div className={`${styles.rating_verified_box} d-flex justify-content-between px-3`}>
                                                        <h5>5.0 <span>(2)</span></h5>
                                                        <img src={TrendingChoice} alt="Your SVG" />
                                                    </div>
                                                    <h4>{itm.project_name}</h4>
                                                    <p>Facing: {itm.plot_main_facing}</p>
                                                    <p>Size: {itm.plot_size_width}ft X {itm.plot_size_length}ft</p>
                                                </div>
                                                <div className={`${styles.design_price_box} d-flex justify-content-between`}>
                                                    <img src={Heart} alt="Your SVG" />
                                                    <div className="">
                                                        <label>Starting at</label>
                                                        <p>₹18,994</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            <div className={styles.img_cards}>
                                {arr?.map((itm, i) => {
                                    return (
                                        <Image_card />
                                    )
                                })}
                            </div>}
                        <div className={styles.pagination}>
                            <nav aria-label="...">
                                <ul className="pagination pagination-lg">
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => {
                                                if (currentPage > 1) {
                                                    setCurrentPage(currentPage - 1);
                                                    handleListing(currentPage - 1);
                                                }
                                            }}
                                        >
                                            Previous
                                        </button>
                                    </li>

                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <li
                                            key={i}
                                            className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                                        >
                                            <button
                                                className="page-link"
                                                onClick={() => {
                                                    setCurrentPage(i + 1);
                                                    handleListing(i + 1);
                                                }}
                                            >
                                                {i + 1}
                                            </button>
                                        </li>
                                    ))}

                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => {
                                                if (currentPage < totalPages) {
                                                    setCurrentPage(currentPage + 1);
                                                    handleListing(currentPage + 1);
                                                }
                                            }}
                                        >
                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </div>
                    <RightSidebar />
                </div>
            </Container>
            <GetFreeConslt />
            <ExportOtherServices />
            <Container>
                {/* Have More Question Box */}
                <HaveMoreQuestionBox />
                {/* Try us Box  */}
                <TryUsBox title1="Ready to try Us?" />
            </Container>
        </div>
    )
}
