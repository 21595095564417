import React, { useMemo } from 'react';
import './login.styles.scss';
import { end } from '@popperjs/core';
import AuthSideBar from './authSideBar';
import { useForm, Controller } from 'react-hook-form';
import { postRequest } from '../../components/API_Requests/Api_Request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const Register = () => {
  // Define state variables to store user input
  const { handleSubmit, control, errors } = useForm();
  const { REACT_APP_API_URL } = process.env;
  // eslint-disable-next-line
  const submitRegistrtion = useMemo(() => `${REACT_APP_API_URL}client/save_registration_details`, []);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken')

  /**
    * handleSubmit this function is used for submitForm
    * @author Phil
    * @since 31-08-2023
    */
  const onSubmit = async (data) => {
    console.log((data));
    if (!data) {
      toast.error('Please fill registration form', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      try {
        let newdata = {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
        };
        const bearerToken = accessToken;
        const response = await postRequest(submitRegistrtion, newdata, bearerToken);
        console.log((response));
        if (response && response.success) {
          navigate("/profile")
          toast.success('form submitted successfully!');
        } else {
          toast.error('Errors while submitting form');
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <div className='login_main_page w-100 d-flex'>
      <div className='row w-100'>
        <AuthSideBar />
        <div className='registerationForm col-md-7 d-flex flex-column'>
          <div className="container pt-5">
            <div className="row g-0">
              <div className="col-11 mx-auto">
                <p className="text-right text-2 mb-0" style={{ textAlign: end }}>Already a member? <Link className="red" to="/login">Sign In</Link></p>
              </div>
            </div>
          </div>
          <div className="container my-auto">
            <div className="row g-0">
              <div className="col-11 col-md-10 col-lg-9 col-xl-8 mx-auto">
                <h3 className="fw-600 mb-3 login-h3 signupTitle">Sign up to Planning Homes</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label fw-500">First Name</label>
                    <Controller
                      name="firstName"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'First Name is required' }}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          placeholder="Enter First Name"
                          className={`form-control formInput ${errors?.firstName ? 'is-invalid' : ''}`}
                        />
                      )}
                    />
                    {errors?.firstName && <span className="invalid-feedback"> {errors?.firstName.message}</span>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label fw-500">Last Name</label>
                    <Controller
                      name="lastName"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Last Name is required' }}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          placeholder="Enter Last Name"
                          className={`form-control formInput ${errors?.lastName ? 'is-invalid' : ''}`}
                        />
                      )}
                    />
                    {errors?.lastName && <span className="invalid-feedback"> {errors?.lastName.message}</span>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label fw-500">Email Address</label>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Email Address is required' }}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="email"
                          placeholder="Enter Email Address"
                          className={`form-control formInput ${errors?.email ? 'is-invalid' : ''}`}
                        />
                      )}
                    />
                    {errors?.email && <span className="invalid-feedback"> {errors?.email.message}</span>}
                  </div>
                  <div className="form-check pl-0">
                    <Controller
                      name="agree"
                      control={control}
                      defaultValue={false}
                      rules={{ required: 'You must agree to the Terms and Privacy Policy' }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            id="agree"
                            name="agree"
                            className={`form-check-input ${errors?.agree ? 'is-invalid' : ''}`}
                            type="checkbox"
                          />
                          <label className="form-check-label" htmlFor="agree">
                            I agree to the <Link className="red" to=''>Terms</Link> and <Link className="red" to=''>Privacy Policy</Link>.
                          </label>
                        </>
                      )}
                    />
                    {errors?.agree && <span className="invalid-feedback"> {errors?.agree.message}</span>}
                  </div>
                  <button className="btn btn-primary shadow-none" type="submit">Create Account</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
