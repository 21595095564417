import { Header } from '../header'
import { Footer } from '../footer'
import { Navigate } from 'react-router'

export const PrivateRoute = ({ children }) => {
    const data = localStorage.getItem('id')
    return (
        <>
            <Header />
            {data ? children : <Navigate to='/' />}
            < Footer />
        </>
    )
}
