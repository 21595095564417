const routes = {
  HomeRoute: '/',
  BookDemoRoute: '/book-demo',
  loginRoute: '/login',
  ListItemRoute: '/list-products',
  DescriptionPageRoute: '/service-description',
  RegisterRoute: '/register',
  ProfileRoute: '/profile',
  PaymentRoute: '/payment',
  HelpCentreRoute: '/help-center',
  HowItWorksRoute: '/how-it-works',
  VasstuRoute: '/try-vasstu',
  PricingRoute: '/pricing',
  CustomDesignRoute: '/your-design',
  PartnerNowRoute:'/parnter-now',
  InspirationRoute: '/inspiration-list',
  ThankYouRoute:'/thank-you',
};
export default routes;

