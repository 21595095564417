import fetch from "./FetchInterceptor";
const userService = {};

userService.getlisting = function (project_category = '', room_type = '', length = '', width = '', filters = {}, page = 1) {
  let pageParam = `&page=${page}`;
  let projectCategoryParam = project_category ? `&project_category=${project_category}` : '';
  let PlotInterriorRoom = room_type ? `&room_type=${room_type}` : '';
  let plotLengthParam = (length && width) ? `&plot_size_length=${length}` : '';
  let PlotBreathParam = width ? `&plot_size_width=${width}` : '';
  
  const { project_building_type, project_building_type_extended, plot_area, preferred_for,direction,vastu_compliances,flat_type,no_floors,plot_size,design_type } = filters;
  
  let filterParams = '';
  if (project_building_type) {
    filterParams += `&project_building_type=${project_building_type}`;
  }
  if (project_building_type_extended) {
    filterParams += `&project_building_type_extended=${project_building_type_extended}`;
  }
  if (plot_area) {
    filterParams += `&plot_area=${plot_area}`;
  }
  if (preferred_for) {
    filterParams += `&preferred_for=${preferred_for}`;
  }
if(direction) {
  filterParams += `&direction=${direction}`;
}
if(vastu_compliances) {
  filterParams += `&vastu_compliances=${vastu_compliances}`;
}if(no_floors) {
  filterParams += `&no_floors=${no_floors}`;
}
if(plot_size) {
  filterParams += `&plot_size=${plot_size}`;
}
if(design_type) {
  filterParams += `&design_type=${design_type}`;
}
if(flat_type) {
  filterParams += `&flat_type=${flat_type}`;
}
  return fetch({
    url: `/get_all_service?${projectCategoryParam}${pageParam}${plotLengthParam}${PlotBreathParam}${PlotInterriorRoom}${filterParams}`,
    method: "get",
  });
};

export default userService;
